import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../../../components/Navbar'
import axiosInstance from '../../../../utils/axiosInstance'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

function MyAttendanceSummary() {
  const [leadInfo, setLeadInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [attendanceEntries, setattendanceEntries] = useState([])
  const auth = useSelector((state) => state.auth)

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'DATE',
      render: (text, record, index) => (
        <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>
      ),
      key: 'DATE',
      align: 'center',
    },
    {
      title: 'CHECK-IN TIME',
      render: (text, record, index) => (
        <span>{moment(record.checkInTime).format('hh:mm A')}</span>
      ),
      key: 'checkInTime',
      align: 'center',
    },
    {
      title: 'CHECK-OUT TIME',
      render: (text, record, index) => (
        <span>
          {record.checkOutTime
            ? moment(record.checkOutTime).format('hh:mm A')
            : ''}
        </span>
      ),
      key: 'checkOutTime',
      align: 'center',
    },
    {
      title: 'LEAVE AVAILED',
      render: (text, record, index) => <span></span>,
      // key: 'checkOutTime',
      align: 'center',
    },
    {
      title: 'LEAVE TYPE',
      render: (text, record, index) => <span></span>,
      // key: 'checkOutTime',
      align: 'center',
    },
    {
      title: 'LEAVE FROM',
      render: (text, record, index) => <span></span>,
      // key: 'checkOutTime',
      align: 'center',
    },
    {
      title: 'LEAVE TILL',
      render: (text, record, index) => <span></span>,
      // key: 'checkOutTime',
      align: 'center',
    },
    {
      title: 'EARLY LEAVE',
      render: (text, record, index) => (
        <span>
          {record.specialReq.map((el) => {
            if (el.requestFor === 'Early Leave') {
              return 'Submitted'
            } else {
              return ''
            }
          })}
        </span>
      ),
      align: 'center',
    },
    {
      title: 'REASON',
      render: (text, record, index) => (
        <div
          style={{
            border: '1px solid white',
            textAlign: 'center',
            maxHeight: 50,
            overflow: 'auto',
            fontSize: 10,
          }}
        >
          {record.specialReq.map((el) => {
            if (el.requestFor === 'Early Leave') {
              console.log('true')
              return el.detailsEarly
            } else {
              return ''
            }
          })}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'LATE ENTRY',
      render: (text, record, index) => (
        <div>
          {record.specialReq.map((el) => {
            if (el.requestFor === 'Late Entry') {
              return 'Submitted'
            } else {
              return ''
            }
          })}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'REASON',
      render: (text, record, index) => (
        <div
          style={{
            border: '1px solid white',
            maxHeight: 50,
            overflow: 'auto',
            fontSize: 10,
          }}
        >
          {record.specialReq.map((el) => {
            if (el.requestFor === 'Late Entry') {
              console.log('true')
              return el.detailsLate
            } else {
              return ''
            }
          })}
        </div>
      ),
      align: 'center',
      className: 'reasonAttendence',
    },
  ]

  const getMyAttendanceSummary = async () => {
    // setLoading(true)

    let pathString = `/cm/getMyAttendanceSummary`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        // console.log(response.data)

        // setattendanceEntries(response.data.attendanceEntries)

        let pathString2 = `/cm/getMySpecialRequest`
        const response2 = await axiosInstance.get(pathString2)
        // console.log(response2.data)

        // setattendanceEntries(response2.data.attendanceEntries)

        const newArr = response.data.attendanceEntries.map((att, index) => {
          var dateString = moment(att.createdAt).format('YYYY-MM-DD')
          const entries = response2.data.results.filter((el, idx) => {
            if (el.addingDate === dateString) {
              return att
            }
          })
          console.log(entries)
          console.log({ ...att, specialReq: entries })
          return { ...att, specialReq: entries }
        })

        console.log(newArr)
        setattendanceEntries(newArr)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyAttendanceSummary()
  }, [])

  return (
    <div>
      {' '}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            // margin: '0 16px',
            padding: '30px 16px',
            backgroundColor: '#fff',
          }}
        >
          <h1
            style={{
              color: '#275058',
              textDecoration: 'underline',
              textTransform: 'uppercase',
            }}
          >
            Attendance Summary
          </h1>

          <Table
            dataSource={attendanceEntries}
            columns={columns.map((each) => ({
              ...each,
              className: 'maxWidthCell',
            }))}
            bordered
            pagination={false}
          />
        </Content>
      </Layout>
    </div>
  )
}

export default MyAttendanceSummary
