import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Layout,
  Button,
  Radio,
  Upload,
  Input,
  Modal,
  Alert,
  List,
  Popconfirm,
  Select,
  Spin,
} from 'antd'
import TopRowCommon from '../../components/TopRowCommon'
import Navbar from '../../components/Navbar'

const { Content } = Layout

function TotalPatients() {
  const navigate = useNavigate()

  return (
    <>
      <Navbar />
      <Layout style={styles.layoutStyle}>
        <Content style={styles.contentStyle}>
          <TopRowCommon title="TOTAL PATIENTS" />
          <TopRowCommon title="__THIS COMPONENT IS NOT CURRENTLY IN USE__" />
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  layoutStyle: {
    minHeight: '100vh',
  },
  contentStyle: {
    padding: '0 16px',
    backgroundColor: '#fff',
  },
}

export default TotalPatients
