import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  UploadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import {
  Layout,
  Button,
  Radio,
  Upload,
  Input,
  Modal,
  Alert,
  List,
  Popconfirm,
  Select,
  Form,
  Spin,
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Navbar from '../../components/Navbar'
import axiosInstance from '../../utils/axiosInstance'

const { Content } = Layout
const { Option } = Select

const LabelText = styled.p`
  color: #275058;
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
`

const inputStyle = {
  padding: '12px',
  borderRadius: '26px',
  display: 'inline-block',
  border: '1px solid #275058',
}

function AddTreatment() {
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal()
  const submitButtonRef = useRef()

  const [size, setSize] = useState('ADD')
  const [allTreatments, setAllTreatments] = useState([])
  const [allSubSegments, setAllSubSegments] = useState([])
  const [treatmentFileList, setTreatmentFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [validationText, setValidationText] = useState('')

  const treatmentPlanUploadProps = {
    name: 'file',
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setTreatmentFileList(info.fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }

  const countDown = () => {
    let secondsToGo = 2
    const instance = modal.success({
      title: 'Added successfully!',
      content: ``,
      onOk: () => navigate('/dashboard'),
    })
    const timer = setInterval(() => {
      secondsToGo -= 1
      // instance.update({
      //   content: `This modal will be destroyed after ${secondsToGo} second.`,
      // })
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
      instance.destroy()
      navigate('/dashboard')
    }, secondsToGo * 1000)
  }

  const validateLeadData = () => {
    setValidationText('')
    const missingFields = []

    // Check if mandatory fields are empty, null, or undefined
    if (!formData.segmentName) missingFields.push('Segment Name')
    if (treatmentFileList.length === 0) missingFields.push('Image')

    return missingFields
  }
  const validateSubSegmentData = () => {
    setValidationText('')
    const missingFields = []

    // Check if mandatory fields are empty, null, or undefined
    if (!formData.segmentId) missingFields.push('Segment Name')
    if (!formData.subSegment) missingFields.push('Sub Segment Name')
    if (!formData.segmentDetails) missingFields.push('Segment Details')

    return missingFields
  }

  // Just to create a Segment with its image
  const handleSubmit = async (extras) => {
    // console.log(formData)
    // submitButtonRef.current.click()

    try {
      const missingFields = validateLeadData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      // return

      // showError(false)
      setLoading(true)

      const fData = new FormData()

      // Add your fields to the FormData object
      fData.append('segmentName', formData.segmentName)
      // fData.append('segmentDetails', formData.segmentDetails)
      // fData.append('subSegments', values.subSegments)

      for (let i = 0; i < treatmentFileList.length; i++) {
        fData.append('treatmentFileList', treatmentFileList[i].originFileObj)
      }

      try {
        const response = await axiosInstance.post(
          '/cm/createTreatment',
          fData,
          {
            headers: {}, // not sure if its working or not
          }
        )
        console.log(response.data)

        countDown() // showing success modal
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  // Just to create a Segment with its image
  const handleSubSegmentSubmit = async (extras) => {
    console.log(formData)

    try {
      const missingFields = validateSubSegmentData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      // return

      // showError(false)
      setLoading(true)

      const fData = new FormData()

      // Add your fields to the FormData object
      fData.append('segmentDetails', formData.segmentDetails)
      fData.append('subSegment', formData.subSegment)

      try {
        const response = await axiosInstance.post(
          `/cm/addSubSegmentToTreatment?id=${formData.segmentId}`,
          fData,
          {
            headers: { 'Content-Type': 'application/json' }, // Overriding default headers (maybe)
          }
        )
        console.log(response.data)

        countDown()
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllTreatments = async () => {
    //
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.get('/cm/getAllTreatments')
        console.log(response.data)
        setAllTreatments(response.data.allTreatments)

        const subSegmentsArr = []
        response.data.allTreatments.forEach((s) => {
          s.subSegments?.forEach((sObj) =>
            subSegmentsArr.push({
              ...sObj,
              for: s.segmentName,
              treatmentId: s._id,
            })
          )
        })

        console.log(subSegmentsArr)
        setAllSubSegments(subSegmentsArr)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllTreatments()
  }, [])

  const addTreatment = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>
          ADD TREATMENT
        </p>

        <div style={{ display: 'flex', gap: 40 }}>
          <Upload {...treatmentPlanUploadProps}>
            <div
              style={{
                marginTop: 20,
                width: '300px',
                height: '150px',
                background:
                  'radial-gradient(circle, rgba(37, 81, 89, 0.8) 0%, rgba(36, 78, 86, 1) 100%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
            >
              <PlusCircleOutlined
                style={{ color: 'white', fontSize: '2rem' }}
              />
              <p style={{ margin: 0 }}>PICTURE</p>
            </div>
          </Upload>

          <div style={{ border: '1px solid white', minWidth: 800 }}>
            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                marginBottom: 10,
                gap: 50,
              }}
            >
              <div style={{ width: '40%' }}>
                <LabelText>*TREATMENT SEGMENT NAME</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Name"
                  //   value={name}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, segmentName: value }
                    })
                  }}
                />
              </div>
              {/* <div style={{ width: '40%' }}>
                <LabelText>*SUB SEGMENT</LabelText>
                <Form
                  name="dynamic_form_item"
                  // {...formItemLayoutWithOutLabel}
                  onFinish={onFinish}
                  style={
                    {
                      // maxWidth: 600,
                    }
                  }
                >
                  <Form.List
                    name="subSegments"
                    initialValue={['']} // Add an empty value by default
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 1) {
                            return Promise.reject(
                              new Error('At least 1 Sub Segment')
                            )
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            // {...(index === 0
                            //   ? formItemLayout
                            //   : formItemLayoutWithOutLabel)}
                            required={false}
                            key={field.key}
                          >
                            <Form.Item
                              {...field}
                              // validateTrigger={['onChange', 'onBlur']}
                              // rules={[
                              //   {
                              //     required: true,
                              //     whitespace: true,
                              //     message:
                              //       "Please...",
                              //   },
                              // ]}
                              noStyle
                            >
                              <Input
                                style={{
                                  width: '80%',
                                  ...inputStyle,
                                }}
                              />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                // className="dynamic-delete-button"
                                style={{
                                  position: 'relative',
                                  top: '4px',
                                  margin: '0 8px',
                                  color: '#999',
                                  fontSize: '24px',
                                  cursor: 'pointer',
                                  transition: 'all 0.3s',
                                }}
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{
                              width: '60%',
                            }}
                            // icon={<PlusOutlined />}
                          >
                            Add field
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ display: 'none' }}
                      ref={submitButtonRef} // Attach the ref to the Submit button
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div> */}
            </div>
          </div>
        </div>

        {validationText && (
          <div style={{ marginTop: 60 }}>
            <Alert message={validationText} type="error" />
          </div>
        )}

        {loading && (
          <div className="overlay">
            <div style={{ textAlign: 'center', marginTop: '9rem' }}>
              <Spin size="large" />
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 20,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => handleSubmit()}
          >
            SAVE TREATMENT
          </Button>
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => navigate('/dashboard')}
          >
            Cancel{' '}
          </Button>
        </div>
      </>
    )
  }

  const addSubsegment = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>
          ADD SUB SEGMENT
        </p>

        <div style={{ display: 'flex', gap: 40 }}>
          <div style={{ border: '1px solid white', minWidth: 800 }}>
            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                marginBottom: 10,
                gap: 50,
              }}
            >
              <div style={{ width: '40%' }}>
                <LabelText>*TREATMENT SEGMENT NAME (CHOOSE ONE)</LabelText>
                {/* <Input
                  style={{ ...inputStyle }}
                  // placeholder="Name"
                  //   value={name}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, segmentName: value }
                    })
                  }}
                /> */}
                <Select
                  style={styles.selectStyle}
                  // value={formData.location}
                  onChange={(value) => {
                    // console.log(value)
                    setFormData((prev) => {
                      return { ...prev, segmentId: value }
                    })
                  }}
                >
                  {allTreatments?.map((el, idx) => (
                    <Option value={el._id} key={idx}>
                      {el.segmentName}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ width: '40%' }}>
                <LabelText>*SUB SEGMENT NAME</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Name"
                  //   value={name}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, subSegment: value }
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}></div>
        <LabelText>*DESCRIPTION</LabelText>
        <ReactQuill
          theme="snow"
          // value={value}
          onChange={(value) => {
            setFormData((prev) => {
              return { ...prev, segmentDetails: value }
            })
          }}
          style={{ height: '30vh' }}
        />

        {validationText && (
          <div style={{ marginTop: 60 }}>
            <Alert message={validationText} type="error" />
          </div>
        )}

        {loading && (
          <div className="overlay">
            <div style={{ textAlign: 'center', marginTop: '9rem' }}>
              <Spin size="large" />
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 20,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => handleSubSegmentSubmit()}
          >
            SAVE SUB SEGMENT
          </Button>
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => navigate('/dashboard')}
          >
            Cancel{' '}
          </Button>
        </div>
      </>
    )
  }
  const deleteTreatment = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>
          DELETE SEGMENT (With its sub segment)
        </p>

        <List
          style={{ maxWidth: '50%' }}
          size="large"
          // header={<div>All</div>}
          // footer={<div>Footer</div>}
          bordered
          dataSource={allTreatments}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a
                  href={`${process.env.REACT_APP_LIVE_SITE_URL}/treatments`}
                  target="_blank"
                >
                  View Live
                </a>,
                <Button
                  onClick={() => {
                    navigate(`/editTreatment?id=${item._id}`)
                  }}
                >
                  Edit
                </Button>,
                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete this?"
                  onConfirm={async () => {
                    try {
                      const response = await axiosInstance.delete(
                        `/cm/deleteTreatmentById?id=${item._id}`
                      )
                      console.log(response.data)
                      // handleReload()
                      getAllTreatments()
                    } catch (err) {
                      console.log(err)
                      // handleReload()
                    }
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger>Delete</Button>{' '}
                </Popconfirm>,
              ]}
            >
              {item.segmentName}
            </List.Item>
          )}
        />
      </>
    )
  }

  const deleteSubSegment = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>
          DELETE SUB SEGMENT
        </p>

        <List
          style={{ maxWidth: '50%' }}
          size="large"
          // header={<div>All</div>}
          // footer={<div>Footer</div>}
          bordered
          dataSource={allSubSegments}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a
                  href={`${process.env.REACT_APP_LIVE_SITE_URL}/treatments`}
                  target="_blank"
                >
                  View Live
                </a>,
                <Button
                  onClick={() => {
                    navigate(
                      `/editSubSegment?treatmentId=${item.treatmentId}&subSegmentId=${item.id}`
                    )
                  }}
                >
                  Edit
                </Button>,
                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete this?"
                  onConfirm={async () => {
                    try {
                      // TODO: DELETE  sub segment
                      const response = await axiosInstance.delete(
                        `/cm/removeSubSegmentFromTreatment?treatmentId=${item.treatmentId}&subSegmentId=${item.id}`
                      )
                      // console.log(response.data)
                      // handleReload()
                      getAllTreatments()
                    } catch (err) {
                      console.log(err)
                      // handleReload()
                    }
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger>Delete</Button>{' '}
                </Popconfirm>,
              ]}
            >
              {item.subSegment} - ({item.for})
            </List.Item>
          )}
        />
      </>
    )
  }

  const onFinish = async (values) => {
    console.log('Received values of form:', values)
    console.log(values.subSegments)
    console.log(typeof values.subSegments)
    console.log(formData)

    // * Because subSegments should be optional
    // console.log(values.subSegments[0] === '')
    // if (values.subSegments[0] === '') {
    //   setValidationText(`Sub segment missing`)
    //   return
    // }

    // if(values[0])
    try {
      const missingFields = validateLeadData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      return

      // showError(false)
      setLoading(true)

      const fData = new FormData()

      // Add your fields to the FormData object
      fData.append('segmentName', formData.segmentName)
      // fData.append('segmentDetails', formData.segmentDetails)
      // fData.append('subSegments', values.subSegments)

      for (let i = 0; i < treatmentFileList.length; i++) {
        fData.append('treatmentFileList', treatmentFileList[i].originFileObj)
      }

      try {
        const response = await axiosInstance.post(
          '/cm/createTreatment',
          fData,
          {
            headers: {}, // not sure if its working or not
          }
        )
        console.log(response.data)

        countDown() // showing success modal
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      {/* // TODO: Enable later */}
      {/* <Navbar /> */}
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={styles.backBtn}
              onClick={() => navigate('/dashboard')}
            >
              Back{' '}
            </Button>
          </div>
          <p style={styles.actionText}>TREATMENT</p>

          <Radio.Group value={size} onChange={(e) => setSize(e.target.value)}>
            <Radio.Button
              value="ADD"
              style={{
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              ADD SEGMENT
            </Radio.Button>
            <Radio.Button
              value="SUBSEGMENT"
              style={{
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              ADD SUBSEGMENT
            </Radio.Button>
            <Radio.Button
              value="DELETE"
              style={{ boxShadow: 'inset 0px 3px 3px #00000029' }}
            >
              SEGMENT ACTIONS
            </Radio.Button>
            <Radio.Button
              value="SUBSEGMENTDELETE"
              style={{
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              SUB SEGMENT ACTIONS
            </Radio.Button>
          </Radio.Group>

          {size === 'ADD' && addTreatment()}

          {size === 'SUBSEGMENT' && addSubsegment()}

          {size === 'DELETE' && deleteTreatment()}

          {size === 'SUBSEGMENTDELETE' && deleteSubSegment()}
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  backBtn: {
    marginTop: 20,
    borderRadius: 25,
    width: 70,
    fontSize: '.8rem',
    padding: 0,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  actionText: {
    color: '#275058',
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
    fontSize: '2rem',
  },
  selectStyle: {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
    padding: 5,
    overflow: 'hidden',
    width: '90%',
  },
}

export default AddTreatment
