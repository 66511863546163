import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Modal, Popconfirm, Input, message } from 'antd'
import { CloseCircleOutlined, FileOutlined } from '@ant-design/icons'

import axiosInstance from '../../../utils/axiosInstance'
import getExtensionFromMimeType from '../../../utils/getExtensionFromMimeType'

function NoteSection({ leadInfo }) {
  const { servicePackage } = leadInfo

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [notes, setNotes] = useState(leadInfo?.notes)
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState({})

  const handleSelectTag = async () => {
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${leadInfo._id}`,
          {
            notes: notes,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }

  const handleReload = () => {
    window.location.reload()
  }

  const inputStyle = {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
  }

  return (
    <div>
      <p
        style={{
          display: 'inline-block',
          padding: 5,
          border: '1px solid #275058',
          color: '#275058',
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          cursor: 'pointer',
        }}
        onClick={handleSelectTag}
      >
        Update
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          // alignItems: 'center',
          gap: 20,
        }}
      >
        <p style={{ margin: 0, color: '#275058', fontSize: '1.4rem' }}>
          <span style={{ fontWeight: 'bold' }}>Title:</span>{' '}
          {leadInfo?.noteTitle}
        </p>
        <Input
          // placeholder="Notes"
          style={{
            ...inputStyle,
            height: '10rem',
            width: '90%',
            marginBottom: 20,
          }}
          placeholder="Your note here"
          // defaultValue={leadInfo?.notes}
          value={notes}
          onChange={({ target: { value } }) => setNotes(value)}
        />
      </div>
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  optionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    marginTop: 20,
  },
  selectStyle: {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
    padding: 5,
    overflow: 'hidden',
    fontSize: '1.5rem',
    color: '#275058',
    paddingRight: 20,
    boxShadow: 'inset 0px 3px 3px #00000029',
  },
}

export default NoteSection
