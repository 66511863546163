import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  UploadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import {
  Layout,
  Button,
  Radio,
  Upload,
  Input,
  Modal,
  Alert,
  List,
  Popconfirm,
  Select,
  Form,
  Spin,
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Navbar from '../../components/Navbar'
import axiosInstance from '../../utils/axiosInstance'

const { Content } = Layout
const { Option } = Select

const LabelText = styled.p`
  color: #275058;
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
`

const inputStyle = {
  padding: '12px',
  borderRadius: '26px',
  display: 'inline-block',
  border: '1px solid #275058',
}

function EditSubSegment() {
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal()
  const submitButtonRef = useRef()

  const [size, setSize] = useState('ADD')
  const [allTreatments, setAllTreatments] = useState([])
  const [foundSegment, setFoundSegment] = useState(null)
  const [allSubSegments, setAllSubSegments] = useState([])
  const [foundTreatment, setFoundTreatment] = useState(null)
  const [treatmentFileList, setTreatmentFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [validationText, setValidationText] = useState('')
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const treatmentId = searchParams.get('treatmentId')
  const subSegmentId = searchParams.get('subSegmentId')

  const countDown = () => {
    let secondsToGo = 2
    const instance = modal.success({
      title: 'Updated successfully!',
      content: ``,
      onOk: () => navigate(-1),
    })
    const timer = setInterval(() => {
      secondsToGo -= 1
      // instance.update({
      //   content: `This modal will be destroyed after ${secondsToGo} second.`,
      // })
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
      instance.destroy()
      navigate(-1)
    }, secondsToGo * 1000)
  }

  const validateSubSegmentData = () => {
    setValidationText('')
    const missingFields = []

    // Check if mandatory fields are empty, null, or undefined
    if (!formData.segmentId) missingFields.push('Segment Name')
    if (!formData.subSegment) missingFields.push('Sub Segment Name')
    if (!formData.segmentDetails) missingFields.push('Segment Details')

    return missingFields
  }

  // Just to create a Segment with its image
  const handleSubSegmentSubmit = async (extras) => {
    console.log(formData)

    try {
      const missingFields = validateSubSegmentData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      // return

      // showError(false)
      setLoading(true)

      const fData = new FormData()

      // Add your fields to the FormData object
      fData.append('segmentDetails', formData.segmentDetails)
      fData.append('subSegment', formData.subSegment)

      try {
        const response = await axiosInstance.post(
          `/cm/editSubSegmentInTreatment?treatmentId=${treatmentId}&subSegmentId=${subSegmentId}`,
          fData,
          {
            headers: { 'Content-Type': 'application/json' }, // Overriding default headers (maybe)
          }
        )
        console.log(response.data)

        countDown()
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllTreatments = async () => {
    //
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.get('/cm/getAllTreatments')
        setAllTreatments(response.data.allTreatments)

        const subSegmentsArr = []
        response.data.allTreatments.forEach((treatment) => {
          if (treatmentId == treatment._id) {
            treatment.subSegments?.forEach((sObj) => {
              const obj = {
                ...sObj,
                for: treatment.segmentName,
                treatmentId: treatment._id,
              }

              subSegmentsArr.push(obj)

              setFoundSegment(obj)

              setFormData({ ...obj, segmentId: treatment._id })
            })
          }
        })

        // console.log(subSegmentsArr)
        setAllSubSegments(subSegmentsArr)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllTreatments()
  }, [])

  const addSubsegment = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>
          EDIT SUB SEGMENT
        </p>

        <div style={{ display: 'flex', gap: 40 }}>
          <div style={{ border: '1px solid white', minWidth: 800 }}>
            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                marginBottom: 10,
                gap: 50,
              }}
            >
              {/* //  ! the current backend route cant update this Select tag below */}
              {/* <div style={{ width: '40%' }}>
                <LabelText>*TREATMENT SEGMENT NAME (CHOOSE ONE)</LabelText>
                <Select
                  style={styles.selectStyle}
                  value={formData.segmentId}
                  onChange={(value) => {
                    setFormData((prev) => {
                      return { ...prev, segmentId: value }
                    })
                  }}
                >
                  {allTreatments?.map((el, idx) => (
                    <Option value={el._id} key={idx}>
                      {el.segmentName}
                    </Option>
                  ))}
                </Select>
              </div> */}
              <div style={{ width: '40%' }}>
                <LabelText>*SUB SEGMENT NAME</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  value={formData.subSegment}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, subSegment: value }
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}></div>
        <LabelText>*DESCRIPTION</LabelText>
        <ReactQuill
          theme="snow"
          defaultValue={formData.segmentDetails}
          onChange={(value) => {
            setFormData((prev) => {
              return { ...prev, segmentDetails: value }
            })
          }}
          style={{ height: '30vh' }}
        />

        {validationText && (
          <div style={{ marginTop: 60 }}>
            <Alert message={validationText} type="error" />
          </div>
        )}

        {loading && (
          <div className="overlay">
            <div style={{ textAlign: 'center', marginTop: '9rem' }}>
              <Spin size="large" />
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 20,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => handleSubSegmentSubmit()}
          >
            SAVE SUB SEGMENT
          </Button>
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => navigate('/dashboard')}
          >
            Cancel{' '}
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button style={styles.backBtn} onClick={() => navigate(-1)}>
              Back{' '}
            </Button>
          </div>

          {foundSegment && addSubsegment()}
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  backBtn: {
    marginTop: 20,
    borderRadius: 25,
    width: 70,
    fontSize: '.8rem',
    padding: 0,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  actionText: {
    color: '#275058',
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
    fontSize: '2rem',
  },
  selectStyle: {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
    padding: 5,
    overflow: 'hidden',
    width: '90%',
  },
}

export default EditSubSegment
