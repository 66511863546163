import React, { useState } from 'react'
import { Upload, Button, message, Spin } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import axiosInstance from '../../../utils/axiosInstance'

function ActionBills({ leadInfo }) {
  const [billFileList, setBillFileList] = useState([])
  const [loading, setLoading] = useState(false)

  const treatmentPlanUploadProps = {
    name: 'file',
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setBillFileList(info.fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }

  const onUpdateLead = async () => {
    //

    try {
      // showError(false)
      setLoading(true)

      if (billFileList.length != 1) {
        message.error('Please add one file')
        return
      }

      const formData = new FormData()

      // Add your fields to the FormData object
      formData.append('pID', leadInfo.pID)

      for (let i = 0; i < billFileList.length; i++) {
        formData.append('billFileList', billFileList[i].originFileObj)
      }

      try {
        const response = await axiosInstance.post('/cm/uploadBill', formData, {
          headers: {}, // not sure if its working or not
        })
        console.log(response.data)
        window.location.reload()
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200,
        // border: '1px solid green',
      }}
    >
      <p
        style={{
          color: '#275058',
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          textDecoration: 'underline',
          fontSize: '2rem',
        }}
      >
        ACTION{' '}
      </p>

      <div>
        <Upload {...treatmentPlanUploadProps}>
          <Button
            type="primary"
            style={{
              borderRadius: 25,
              textTransform: 'uppercase',
              height: 50,
              width: 300,
              fontSize: '1.2rem',
            }}
          >
            UPLOAD BILL <UploadOutlined />
          </Button>
        </Upload>
        <br />
        {loading && (
          <div className="overlay">
            <div style={{ textAlign: 'center', marginTop: '9rem' }}>
              <Spin size="large" />
            </div>
          </div>
        )}
        <div
          style={{
            marginTop: 40,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            // type="primary"
            style={styles.saveBtn}
            onClick={() => {
              onUpdateLead()
            }}
          >
            Save{' '}
          </Button>
          <Button
            // type="primary"
            style={styles.saveBtn}
            onClick={() => {
              window.location.reload()
            }}
          >
            Cancel{' '}
          </Button>
        </div>
      </div>
    </div>
  )
}

const styles = {
  saveBtn: {
    boxShadow: 'inset 0px 3px 3px #00000029',
    borderRadius: 15,
    textTransform: 'uppercase',
    width: 100,
    fontSize: '1.1rem',
    color: '#275058',
    padding: 0,
  },
}

export default ActionBills
