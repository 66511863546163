import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../../../components/Navbar'

import axiosInstance from '../../../../utils/axiosInstance'
import CmOverviewCard from './CmOverviewCard'
import ActionCard from './actions/ActionCard'
import BottomNavContainer from './bottom/BottomNavContainer'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

function EachCm() {
  let { id } = useParams()
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')

  const [cmUser, setCmUser] = useState(null)
  const [selectedTab, setSelectedTab] = useState(tab || '')

  const getCmUsers = async () => {
    let pathString = `/cm/getAllCmUsers`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        // console.log(response.data)
        response.data.cmUsers.forEach((user) => {
          if (user._id === id) {
            setCmUser(user)
          }
        })
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
    }
  }

  useEffect(() => {
    getCmUsers()
  }, [])

  if (!cmUser) return <div>Loading...</div>

  return (
    <div>
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            // margin: '0 16px',
            padding: '30px 16px',
            backgroundColor: '#fff',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '50%' }}>
              <CmOverviewCard leadInfo={cmUser} />
            </div>
            <div style={{ width: '50%' }}>
              <ActionCard cmUser={cmUser} selectedTab={selectedTab} />
            </div>
          </div>
          <div>
            {cmUser && (
              <BottomNavContainer
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                leadInfo={cmUser}
              />
            )}
          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default EachCm
