import React, { Children, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Breadcrumb, Layout, Menu, theme, Tree } from 'antd'
import {
  BellOutlined,
  HomeOutlined,
  IdcardOutlined,
  UserOutlined,
  MinusCircleFilled,
} from '@ant-design/icons'

import axiosInstance from '../utils/axiosInstance'

const { Header, Content, Footer, Sider } = Layout

function MySider({ children }) {
  const location = useLocation()
  const [cmUsers, setCmUsers] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const auth = useSelector((state) => state.auth)
  const isSu = useState(auth?.user?.type === 'su' ? true : false)[0]
  const navigate = useNavigate()

  const treeData = [
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/allLeads?leadSource=allLeads')}
        >
          All Patient
        </span>
      ),
      key: '0-0',
      children: [
        // {
        //   title: 'Patient',
        //   key: '0-0-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/allLeads?leadSource=Website')}
        >
          Website Leads
        </span>
      ),
      key: '0-1',
      children: [
        // {
        //   title: '',
        //   key: '0-1-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/allLeads?leadSource=Facebook')}
        >
          Facebook Leads
        </span>
      ),
      key: '0-2',
      children: [
        // {
        //   title: '',
        //   key: '0-2-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/allLeads?leadSource=contactCenter')}
        >
          Contact Center Leads
        </span>
      ),
      key: '0-3',
      children: [
        // {
        //   title: '',
        //   key: '0-3-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/leadRequest')}
        >
          Leads Received{' '}
        </span>
      ),
      key: '0-4',
      children: [],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/teleRequest')}
        >
          Tele-Medicine Request
        </span>
      ),
      key: '0-5',
      children: [],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/queryRequests')}
        >
          Query Requests
        </span>
      ),
      key: '0-5',
      children: [],
    },
    ...(!isSu
      ? [
          {
            title: (
              <span
                style={{ color: '#275058' }}
                onClick={() => navigate('/assignedLeads')}
              >
                Assigned Leads
              </span>
            ),
            key: '0-6',
            children: [],
          },
        ]
      : []),
  ]

  const userRoleTree = [
    ...(isSu
      ? [
          {
            title: <span style={{ color: '#275058' }}>Client Management</span>,
            key: '0-0',
            children: [
              ...cmUsers.map((el, idx) => ({
                title: (
                  <span
                    onClick={() => {
                      navigate(`/cm/${el._id}`, {
                        state: { sentFrom: location },
                      })
                    }}
                  >
                    {el.name}
                  </span>
                ),
                key: `0-0-${idx + 1}`,
              })),
            ],
          },
        ]
      : []),
    {
      title: <span style={{ color: '#275058' }}>Channel Partners</span>,
      key: '0-1',
      children: [
        {
          title: '',
          key: '0-1-1',
        },
      ],
    },
    {
      title: <span style={{ color: '#275058' }}>International Partners</span>,
      key: '0-2',
      children: [
        {
          title: '',
          key: '0-2-1',
        },
      ],
    },
  ]

  const addRemoveTreeData = [
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/addHospital', { state: {} })}
        >
          Hospitals
        </span>
      ),
      key: '0-0',
      children: [
        // {
        //   title: '',
        //   key: '0-0-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/addDoctor', { state: {} })}
        >
          Doctors
        </span>
      ),
      key: '0-1',
      children: [
        // {
        //   title: '',
        //   key: '0-1-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/addTreatment', { state: {} })}
        >
          Treatments
        </span>
      ),
      key: '0-2',
      children: [
        // {
        //   title: '',
        //   key: '0-2-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/addService', { state: {} })}
        >
          Services
        </span>
      ),
      key: '0-3',
      children: [
        // {
        //   title: '',
        //   key: '0-3-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/addBlog', { state: {} })}
        >
          Blogs
        </span>
      ),
      key: '0-4',
      children: [
        // {
        //   title: '',
        //   key: '0-4-1',
        // },
      ],
    },
    {
      title: (
        <span
          style={{ color: '#275058' }}
          onClick={() => navigate('/addBanners', { state: {} })}
        >
          Banners
        </span>
      ),
      key: '0-5',
      children: [],
    },
    // {
    //   title: <span style={{ color: '#275058' }}>FAQ</span>,
    //   key: '0-6',
    //   children: [
    //     {
    //       title: '',
    //       key: '0-5-1',
    //     },
    //   ],
    // },
    // {
    //   title: <span style={{ color: '#275058' }}>Partners</span>,
    //   key: '0-6',
    //   children: [
    //     {
    //       title: '',
    //       key: '0-6-1',
    //     },
    //   ],
    // },
  ]

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info)
  }
  const onCheck = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info)
  }

  const getCmUsers = async () => {
    let pathString = `/cm/getAllCmUsers`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        // setLeaveEntries(response.data.leaveEntries)
        setCmUsers(response.data.cmUsers)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
    }
  }

  useEffect(() => {
    if (isSu) {
      getCmUsers()
    }
  }, [])

  return (
    <Sider
      theme="light"
      // collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={300}
      style={{
        paddingTop: '2rem',
        paddingLeft: '2rem',
        boxShadow: 'inset 0px 3px 13px #00000029',
      }}
    >
      {children}
      <div className="homeAside">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            cursor: 'pointer',
          }}
        >
          {/* <HomeOutlined style={{ color: '#275058', fontSize: '1.4rem' }} /> */}
          <img
            src="/contents/icons/HOME.png"
            style={{ height: '1.4rem' }}
            alt=""
          />
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            HOME
          </p>
        </div>
      </div>

      <div className="patientAside">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 30,
            cursor: 'pointer',
          }}
        >
          {/* <IdcardOutlined style={{ color: '#275058', fontSize: '1.4rem' }} /> */}
          <img
            src="/contents/icons/PATIENTS.png"
            style={{ height: '1.4rem' }}
            alt=""
          />
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              // fontWeight: 'bold',
            }}
          >
            PATIENTS
          </p>
        </div>
        <div
          style={{
            marginTop: '1rem',
            width: '80%',
            color: '#275058',
          }}
          className="siderTree"
        >
          <Tree
            // checkable
            // defaultExpandedKeys={['0-0-0', '0-0-1']}
            // defaultSelectedKeys={['0-0-0', '0-0-1']}
            // defaultCheckedKeys={['0-0-0', '0-0-1']}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={treeData}
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </div>

      <div className="roleAside">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 30,
            cursor: 'pointer',
          }}
        >
          {/* <UserOutlined style={{ color: '#275058', fontSize: '1.4rem' }} /> */}
          <img
            src="/contents/icons/USER_RULES.png"
            style={{ height: '1.4rem' }}
            alt=""
          />
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              // fontWeight: 'bold',
            }}
          >
            USER ROLES
          </p>
        </div>
        <div
          style={{
            marginTop: '1rem',
            width: '80%',
            color: '#275058',
          }}
          className="siderTree"
        >
          <Tree
            // checkable
            // defaultExpandedKeys={['0-0-0', '0-0-1']}
            // defaultSelectedKeys={['0-0-0', '0-0-1']}
            // defaultCheckedKeys={['0-0-0', '0-0-1']}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={userRoleTree}
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </div>

      <div className="addRemoveAside">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 30,
            cursor: 'pointer',
          }}
        >
          <MinusCircleFilled style={{ color: '#275058', fontSize: '1.4rem' }} />
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              // fontWeight: 'bold',
            }}
          >
            ADD/REMOVE
          </p>
        </div>
        <div
          style={{
            marginTop: '1rem',
            width: '80%',
            color: '#275058',
          }}
          className="siderTree"
        >
          <Tree
            // checkable
            // defaultExpandedKeys={['0-0-0', '0-0-1']}
            // defaultSelectedKeys={['0-0-0', '0-0-1']}
            // defaultCheckedKeys={['0-0-0', '0-0-1']}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={addRemoveTreeData}
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </div>

      <div className="campainsAside">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 30,
            cursor: 'pointer',
          }}
        >
          <img
            src="/contents/icons/CAMPAIGN.png"
            style={{ height: '1.4rem' }}
            alt=""
          />
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              // fontWeight: 'bold',
            }}
          >
            CAMPAIGNS
          </p>
        </div>
      </div>

      <div className="testimonialsAside">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 30,
            cursor: 'pointer',
          }}
        >
          <img
            src="/contents/icons/TESTIMONIAL.png"
            style={{ height: '1.4rem' }}
            alt=""
          />
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              // fontWeight: 'bold',
            }}
          >
            TESTIMONIALS{' '}
          </p>
        </div>
      </div>

      <div className="websiteAppAside">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 30,
            cursor: 'pointer',
          }}
        >
          <img
            src="/contents/icons/WEBSITES.png"
            style={{ height: '1.4rem' }}
            alt=""
          />
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              // fontWeight: 'bold',
            }}
          >
            WEBSITE/APP{' '}
          </p>
        </div>
      </div>

      <div className="socialMediaPostAppAside">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 30,
            cursor: 'pointer',
          }}
        >
          <img
            src="/contents/icons/SOCIAL-MEDIA.png"
            style={{ height: '1.4rem' }}
            alt=""
          />
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              // fontWeight: 'bold',
            }}
          >
            SOCIAL MEDIA POST
          </p>
        </div>
      </div>
    </Sider>
  )
}

export default MySider
