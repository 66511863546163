import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../../../components/Navbar'
import OfficeReqOverviewCard from './OfficeReqOverviewCard'

import axiosInstance from '../../../../utils/axiosInstance'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

function OfficeRequirement() {
  const [leadInfo, setLeadInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [officeRequirements, setofficeRequirements] = useState([])
  const [formData, setFormData] = useState({})

  const onSave = async () => {
    // console.log(formData)
    // return
    try {
      const response = await axiosInstance.put(`/cm/udpateOfficeRequirement`, {
        formData,
      })
      console.log(response.data)
      // setAllLeads(response.data.allLeads)
      message.success(`Successfully Updated!`)
      setLoading(false)
    } catch (error) {
      console.log('kamal')
      console.log(error)
      console.error('Request Error:', error)
    }
  }

  const handleSelectTag = async (value, key, _id) => {
    //
    // console.log(value)
    // console.log({
    //   formData: {
    //     [_id]: { [key]: value },
    //   },
    // })
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/udpateOfficeRequirement`,
          {
            formData: {
              [_id]: { [key]: value },
            },
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'REQUEST FOR',
      dataIndex: 'requestFor',
      key: 'requestFor',
      align: 'center',
    },
    {
      title: 'REQUEST DATE',
      dataIndex: 'requestDate',
      key: 'requestDate',
      align: 'center',
    },
    {
      title: 'REQUIRED DATE',
      dataIndex: 'requiredDate',
      key: 'requiredDate',
      align: 'center',
    },
    {
      title: 'RECEIVED',
      render: (text, record, index) => {
        // return <span>{record.received}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.received}
            onChange={(event) => {
              handleSelectTag(event.target.value, 'received', record._id)
            }}
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        )
      },
      key: 'received',
      align: 'center',
    },
    {
      title: 'RECEIVED DATE',
      render: (text, record, index) => (
        <>
          <Input
            style={styles.invisibleInput}
            defaultValue={record.receivedDate}
            // value={formData.receivedDate}
            onChange={(e) => {
              setFormData((prev) => {
                return {
                  ...prev,
                  [record._id]: {
                    ...prev[record._id],
                    receivedDate: e.target.value,
                  },
                }
              })
            }}
          />
        </>
      ),
      key: 'receivedDate',
      align: 'center',
    },
    {
      title: 'REQUIRED QUANTITY',
      dataIndex: 'requiredQuantity',
      key: 'requiredQuantity',
      align: 'center',
    },
    {
      title: 'BUDGET',
      dataIndex: 'requiredBudget',
      key: 'requiredBudget',
      align: 'center',
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor',
      key: 'vendor',
      align: 'center',
    },
    {
      title: 'PAYMENT METHOD',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      align: 'center',
    },
    {
      title: 'PAYMENT COMPLETED',
      render: (text, record, index) => {
        // return <span>{record.paymentCompleted}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.paymentCompleted}
            onChange={(event) => {
              handleSelectTag(
                event.target.value,
                'paymentCompleted',
                record._id
              )
            }}
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        )
      },
      key: 'paymentCompleted',
      align: 'center',
    },
    {
      title: 'GOODS RECEIVED',
      render: (text, record, index) => {
        // return <span>{record.goodsReceived}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.goodsReceived}
            onChange={(event) => {
              handleSelectTag(event.target.value, 'goodsReceived', record._id)
            }}
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        )
      },
      key: 'goodsReceived',
      align: 'center',
    },
  ]

  const getMyLeaveEntries = async () => {
    // setLoading(true)

    let pathString = `/cm/getOfficeRequirementOfUser`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        setofficeRequirements(response.data.results)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyLeaveEntries()
  }, [])
  return (
    <div>
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            // margin: '0 16px',
            padding: '30px 16px',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{}}>
              <OfficeReqOverviewCard leadInfo={leadInfo} />
            </div>
          </div>
          <h2 style={{ color: '#275058', textDecoration: 'underline' }}>
            OFFICE REQUIREMENT SUMMARY
          </h2>
          <p
            style={{
              display: 'inline-block',
              padding: 5,
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
              textUnderlineOffset: '0.25rem',
              cursor: 'pointer',
            }}
            onClick={onSave}
          >
            Update
          </p>
          <Table
            dataSource={officeRequirements}
            columns={columns.map((each) => ({
              ...each,
              className: 'maxWidthCell',
            }))}
            bordered
            pagination={false}
          />
        </Content>
      </Layout>
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default OfficeRequirement
