// constant copied from web for client reporistory

export const data = [
  {
    image: '/contents/treatment-segments/Cardiac.png',
    title: 'Cardiac',
    subSegments: [
      {
        title: 'Heart Bypass Surgery',
        folder: 'cardiac',
        slug: 'heart-bypass-surgery',
      },
      {
        title: 'Heart Valve Replacement',
        folder: 'cardiac',
        slug: 'heart-valve-replacement',
      },
      {
        title: 'Open Heart Surgery',
        folder: 'cardiac',
        slug: 'open-heart-surgery',
      },
      { title: 'Angioplasty', folder: 'cardiac', slug: 'angioplasty' },
      {
        title: 'AVR (Aortic Valve Replacement)',
        folder: 'cardiac',
        slug: 'avr-aortic-valve-replacement',
      },
      {
        title: 'Permanent Pacemaker Implant',
        folder: 'cardiac',
        slug: 'permanent-pacemaker-implant',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Pulmonology.png',
    title: 'Pulmonology',
    subSegments: [
      {
        title: 'Lung Transplant',
        folder: 'pulmonology',
        slug: 'lung-transplant',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Endocrinology.png',
    title: 'Endocrinology',
    subSegments: [
      {
        title: 'Hormone Replacement Therapy',
        folder: 'endocrinology',
        slug: 'hormone-replacement-therapy',
      },
      {
        title: 'Reproductive Endocrinology and Fertility Treatment',
        folder: 'endocrinology',
        slug: 'reproductive-endocrinology-and-fertility-treatment',
      },
      {
        title: 'Radioactive Iodine Therapy',
        folder: 'endocrinology',
        slug: 'radioactive-iodine-therapy',
      },
      {
        title: 'Thyroid Surgery (Thyroidectomy) Iodine Therapy',
        folder: 'endocrinology',
        slug: 'thyroid-surgery-thyroidectomy-iodine-therapy',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/ENT.png',
    title: 'ENT',
    subSegments: [
      {
        title: 'Cochlear Implant Surgery',
        folder: 'ent',
        slug: 'cochlear-implant-surgery',
      },
      {
        title: 'Rhinoplasty',
        folder: 'ent',
        slug: 'rhinoplasty',
      },
      {
        title: 'Superficial Parotidectomy',
        folder: 'ent',
        slug: 'superficial-parotidectomy',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Gastroenterology.png',
    title: 'Gastroenterology',
    subSegments: [
      {
        title: 'Hepatobiliary Surgery',
        folder: 'gastroenterology',
        slug: 'hepatobiliary-surgery',
      },
      {
        title: 'Gastrointestinal Carcinoid Tumor Surgery',
        folder: 'gastroenterology',
        slug: 'gastrointestinal-carcinoid-tumor-surgery',
      },
      {
        title: 'Gastric Bypass Surgery',
        folder: 'gastroenterology',
        slug: 'gastric-bypass-surgery',
      },
      {
        title: 'Pancreatectomy',
        folder: 'gastroenterology',
        slug: 'pancreatectomy',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Hepatology.png',
    title: 'Hepatology',
    subSegments: [
      {
        title: 'Cholecystectomy',
        folder: 'hepatology',
        slug: 'cholecystectomy',
      },
      {
        title: 'Radiofrequency Ablation',
        folder: 'hepatology',
        slug: 'radiofrequency-ablation',
      },
      {
        title: 'Bile Duct Resection',
        folder: 'hepatology',
        slug: 'bile-duct-resection',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/General-Surgery.png',
    title: 'General Surgery',
    subSegments: [
      {
        title: 'Holep',
        folder: 'gs',
        slug: 'holep',
      },
      {
        title: 'Cystoscopy',
        folder: 'gs',
        slug: 'cystoscopy',
      },
      {
        title: 'Fistulectomy',
        folder: 'gs',
        slug: 'fistulectomy',
      },
      {
        title: 'Endoscopic Decompression',
        folder: 'gs',
        slug: 'endoscopic-decompression',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Gastrointestinal.png',
    title: 'Gastrointestinal & Bariatric',
    subSegments: [
      {
        title: 'Appendix Surgery',
        folder: 'gb',
        slug: 'appendix-surgery',
      },
      {
        title: 'Fistula Surgery',
        folder: 'gb',
        slug: 'fistula-surgery',
      },
      {
        title: 'Gall Bladder Surgery (Cholecystectomy)',
        folder: 'gb',
        slug: 'gall-bladder-surgery-cholecystectomy',
      },
      {
        title: 'Hernia Surgery',
        folder: 'gb',
        slug: 'hernia-surgery',
      },
      {
        title: 'Pancreas Transplant',
        folder: 'gb',
        slug: 'pancreas-transplant',
      },
      {
        title: 'Bariatric Surgery/Weight Loss Surgery',
        folder: 'gb',
        slug: 'bariatric-surgery-weight-loss-surgery',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Urology.png',
    title: 'Urology',
    subSegments: [
      {
        title: 'Varicocele Surgery',
        folder: 'urology',
        slug: 'vericocele-surgery',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Bone-Marrow-Transplante.png',
    title: 'Bone Marrow Transplant',
    subSegments: [
      {
        title: 'BMT Procedure',
        folder: 'bmt',
        slug: 'bmt-procedure',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Internal-Medicine.png',
    title: 'Internal Medicine',
    subSegments: [
      {
        title: 'Musculoskeletal Medicine',
        folder: 'im',
        slug: 'musculoskeletal-medicine',
      },
      {
        title: 'Nephrology Medicine',
        folder: 'im',
        slug: 'nephrology-medicine',
      },
      {
        title: 'Neuro Critical Care Medicine',
        folder: 'im',
        slug: 'neuro-critical-care-medicine',
      },
      {
        title: 'Psychiatry Medicines',
        folder: 'im',
        slug: 'psychiatry-medicines',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Liver-Transplante.png',
    title: 'Liver Transplant',
    subSegments: [
      {
        title: 'Liver Cancer',
        folder: 'lt',
        slug: 'liver-cancer',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Nephrology.png',
    title: 'Nephrology',
    subSegments: [
      {
        title: 'Radical Nephroureterectomy',
        folder: 'nephrology',
        slug: 'radical-nephroureterectomy',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Neuro-Spine.png',
    title: 'Neuro Spine',
    subSegments: [
      {
        title: 'Brain Tumor Surgery',
        folder: 'ns',
        slug: 'brain-tumor-surgery',
      },
      {
        title: 'Cervical Cancer',
        folder: 'ns',
        slug: 'cervical-cancer',
      },
      {
        title: 'Cervical Decompression With Fixation Surgery',
        folder: 'ns',
        slug: 'cervical-decompression-with-fixation-surgery',
      },
      {
        title: 'Deep Brain Stimulation',
        folder: 'ns',
        slug: 'deep-brain-stimulation',
      },
      {
        title: 'Laminectomy and Excision Tumor',
        folder: 'ns',
        slug: 'laminectomy-and-excision-tumor',
      },
      {
        title: 'Scoliosis',
        folder: 'ns',
        slug: 'scoliosis',
      },
      {
        title: 'Spine Surgery',
        folder: 'ns',
        slug: 'spine-surgery',
      },
      {
        title: 'VP Shunt',
        folder: 'ns',
        slug: 'vp-shunt',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Neurology.png',
    title: 'Neurology',
    subSegments: [
      {
        title: 'Epliepsy',
        folder: 'neurology',
        slug: 'epliepsy',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Obstetrics-Gyne.png',
    title: 'Obstetrics & Gynecology',
    subSegments: [
      {
        title: 'IVF',
        folder: 'og',
        slug: 'ivf',
      },
      {
        title: 'Laparoscopic Myomectomy',
        folder: 'og',
        slug: 'laparoscopic-myomectomy',
      },
      {
        title: 'Male Infertility',
        folder: 'og',
        slug: 'male-infertility',
      },
      {
        title: 'Uterus Removal',
        folder: 'og',
        slug: 'uterus-removal',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Oncology.png',
    title: 'Oncology',
    subSegments: [
      {
        title: 'Blood Cancer',
        folder: 'oncology',
        slug: 'blood-cancer',
      },
      {
        title: 'Breast Cancer',
        folder: 'oncology',
        slug: 'breast-cancer',
      },
      {
        title: 'Breast Lumpectomy',
        folder: 'oncology',
        slug: 'breast-lumpectomy',
      },
      {
        title: 'Cancer Treatment',
        folder: 'oncology',
        slug: 'cancer-treatment',
      },
      {
        title: 'Chemotherapy',
        folder: 'oncology',
        slug: 'chemotherapy',
      },
      {
        title: 'Colon Cancer',
        folder: 'oncology',
        slug: 'colon-cancer',
      },
      {
        title: 'Cyberknife Treatment',
        folder: 'oncology',
        slug: 'cyberknife-treatment',
      },
      {
        title: 'Gastrointestinal Cancer',
        folder: 'oncology',
        slug: 'gastrointestinal-cancer',
      },
      {
        title: 'Image Guided Radiation Therapy',
        folder: 'oncology',
        slug: 'image-guided-radiation-therapy',
      },
      {
        title: 'Lung Cancer',
        folder: 'oncology',
        slug: 'lung-cancer',
      },
      {
        title: 'Mouth Cancer',
        folder: 'oncology',
        slug: 'mouth-cancer',
      },
      {
        title: 'Prostate Cancer',
        folder: 'oncology',
        slug: 'prostate-cancer',
      },
      {
        title: 'Radiation Therapy',
        folder: 'oncology',
        slug: 'radiation-therapy',
      },
      {
        title: 'Stomach Cancer',
        folder: 'oncology',
        slug: 'stomach-cancer',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Opthalmology.png',
    title: 'Ophthalmology',
    subSegments: [
      {
        title: 'Amblyopia Treatment',
        folder: 'ophthalmology',
        slug: 'amblyopia-treatment',
      },
      {
        title: 'Cataract Surgery',
        folder: 'ophthalmology',
        slug: 'cataract-surgery',
      },
      {
        title: 'Cornea Transplant',
        folder: 'ophthalmology',
        slug: 'cornea-transplant',
      },
      {
        title: 'Glaucoma Eye',
        folder: 'ophthalmology',
        slug: 'glaucoma-eye',
      },
      {
        title: 'Implantable Contact Lens Surgery',
        folder: 'ophthalmology',
        slug: 'implantable-contact-lens-surgery',
      },
      {
        title: 'Laser Eye Surgery',
        folder: 'ophthalmology',
        slug: 'laser-eye-surgery',
      },
      {
        title: 'Lasik Eye Surgery',
        folder: 'ophthalmology',
        slug: 'lasik-eye-surgery',
      },
      {
        title: 'Lazy Eye Treatment',
        folder: 'ophthalmology',
        slug: 'lazy-eye-treatment',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Orthopedics.png',
    title: 'Orthopedics',
    subSegments: [
      {
        title: 'ACL Surgery',
        folder: 'orthopedics',
        slug: 'acl-surgery',
      },
      {
        title: 'Hip Replacement Surgery',
        folder: 'orthopedics',
        slug: 'hip-replacement-surgery',
      },
      {
        title: 'Knee Replacement Surgery',
        folder: 'orthopedics',
        slug: 'knee-replacement-surgery',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Pediatric.png',
    title: 'Pediatrics',
    subSegments: [
      {
        title: 'Imperforate Anus Repair',
        folder: 'pediatrics',
        slug: 'imperforate-anus-repair',
      },
      {
        title: 'Appendectomy',
        folder: 'pediatrics',
        slug: 'appendectomy',
      },
      {
        title: 'Cleft Lip And Palate Repair',
        folder: 'pediatrics',
        slug: 'cleft-lip-and-palate-repair',
      },
      {
        title: 'Umbilical Hernia Repair',
        folder: 'pediatrics',
        slug: 'umbilical-hernia-repair',
      },
      {
        title: 'Bariatric Surgery',
        folder: 'pediatrics',
        slug: 'bariatric-surgery',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Rheumotology.png',
    title: 'Rheumatology',
    subSegments: [
      {
        title: 'Transcutaneous Electrical Nerve Stimulation (TENS)',
        folder: 'rheumatology',
        slug: 'transcutaneous-electrical-nerve-stimulation-tens',
      },
      {
        title: 'Conventional DMARD',
        folder: 'rheumatology',
        slug: 'conventional-dmard',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Dermatology.png',
    title: 'Dermatology & Cosmetology',
    subSegments: [
      {
        title: 'Facelift',
        folder: 'dc',
        slug: 'facelift',
      },
      {
        title: 'Hair Transplant',
        folder: 'dc',
        slug: 'hair-transplant',
      },
      {
        title: 'Laser Treatment',
        folder: 'dc',
        slug: 'laser-treatment',
      },
    ],
  },
  {
    image: '/contents/treatment-segments/Kidney-Transplante.png',
    title: 'Kidney Transplant',
    subSegments: [],
  },
]
