import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function SpecialRequest({ leadInfo }) {
  const [loading, setLoading] = useState(false)
  const [attendanceEntries, setattendanceEntries] = useState([])
  const [specialRequests, setSpecialRequests] = useState([])

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'REQUEST FOR',
      render: (text, record, index) => <span>{record.requestFor}</span>,
      key: 'DATE',
      align: 'center',
    },
    {
      title: 'DATE',
      render: (text, record, index) => (
        <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>
      ),
      key: 'DATE',
      align: 'center',
    },

    {
      title: 'REASON',
      render: (text, el, index) => (
        <div
          style={{
            border: '1px solid white',
            textAlign: 'center',
            maxHeight: 50,
            overflow: 'auto',
            fontSize: 10,
          }}
        >
          {el.requestFor === 'Early Leave' && el.detailsEarly}
          {el.requestFor === 'Late Entry' && el.detailsLate}
          {el.requestFor === 'Others' && el.detailsOthers}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'Status',
      render: (text, record, index) => {
        // return <span>{record.status}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.status}
            onChange={(event) => {
              handleSelectTag(event.target.value, 'status', record._id)
            }}
          >
            <option value="Not Approved">Not Approved</option>
            <option value="Approved">Approved</option>
          </select>
        )
      },
      key: 'leaveStatus',
      align: 'center',
    },
  ]

  const handleSelectTag = async (value, key, _id) => {
    //

    console.log(value)
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/su/updateSpecialRequest?ID=${_id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getMyAttendanceSummary = async () => {
    // setLoading(true)

    console.log('kamal')

    let pathString = `/su/getAttendanceViaId?id=${leadInfo._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        // console.log(response.data)

        // setattendanceEntries(response.data.attendanceEntries)

        let pathString2 = `/su/getSpecialRequestViaId?id=${leadInfo._id}`
        const response2 = await axiosInstance.get(pathString2)
        // console.log(response2.data)
        setSpecialRequests(response2.data.results)

        // setattendanceEntries(response2.data.attendanceEntries)

        const newArr = response.data.attendanceEntries.map((att, index) => {
          var dateString = moment(att.createdAt).format('YYYY-MM-DD')
          const entries = response2.data.results.filter((el, idx) => {
            if (el.addingDate === dateString) {
              return att
            }
          })
          console.log(entries)
          //   console.log({ ...att, specialReq: entries })
          return { ...att, specialReq: entries }
        })

        console.log(newArr)
        setattendanceEntries(newArr)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyAttendanceSummary()
  }, [])

  return (
    <div style={{ paddingTop: '1rem' }}>
      <Table
        dataSource={specialRequests}
        columns={columns.map((each) => ({
          ...each,
          className: 'maxWidthCell',
        }))}
        bordered
        pagination={false}
      />
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default SpecialRequest
