import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Input, Select, message, DatePicker } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'

import axiosInstance from '../../../../utils/axiosInstance'

const { Option } = Select

const LabelText = styled.p`
  color: #275058;
  font-size: 1rem;
  margin: 0;
  margin-bottom: 10px;
  //   text-transform: uppercase;
  //   border: 1px solid red;
  text-align: left;
`

function OverviewCard(props) {
  const { leadInfo } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [messageApi, contextHolder] = message.useMessage()
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(leadInfo || {})
  const [loading, setLoading] = useState(false)

  const onSave = async () => {
    //
    // console.log(formData)
    const isValid = Object.keys(formData).length >= 6 ? true : false
    if (!isValid) {
      messageApi.open({
        type: 'info',
        content: 'Please provide all the informations',
        duration: 5,
      })
      return
    }
    setLoading(true)

    try {
      const response = await axiosInstance.post(`/cm/newSalaryEntry`, formData)
      console.log(response.data)
      // setAllLeads(response.data.allLeads)

      setLoading(false)
      messageApi.open({
        type: 'success',
        content: 'Your form has been submitted',
        duration: 5,
      })
      window.location.reload()
    } catch (error) {
      console.error('Request Error:', error)
      messageApi.open({
        type: 'error',
        content: 'Failed',
        duration: 5,
      })
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const handleSelectTag = async (key, value) => {
    //
    console.log(key)
    console.log(value)
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${formData._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const handleBackButton = () => {
    // console.log(location?.state?.sentFrom?.pathname)
    if (location?.state?.sentFrom?.pathname) {
      navigate(location?.state?.sentFrom?.pathname)
      return
    }
    navigate(-1)
  }

  //   console.log(leadInfo)
  // if (!formData) return <></>

  return (
    <div style={styles.container}>
      {contextHolder}

      <div style={{ display: 'flex' }}>
        <div style={{ width: '10%' }}>
          <Button style={styles.backBtnStyle} onClick={handleBackButton}>
            Back{' '}
          </Button>
        </div>
        <div
          style={{
            height: 40,
            clipPath: ' polygon(7% 100%, 93% 100%, 100% 0, 0 0)',
            background: '#275058',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '80%',
          }}
        >
          <p
            style={{
              color: 'white',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: 2,
            }}
          >
            SALARY RECIEVED
          </p>
        </div>
        <div
          style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}
        >
          {!isEditing ? (
            <EditOutlined
              style={{
                color: '#275058',
                fontSize: '1.2rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsEditing(true)
                messageApi.open({
                  type: 'info',
                  content: 'Form is now editable',
                })
              }}
            />
          ) : (
            <Button
              type="primary"
              style={styles.backBtnStyle}
              onClick={() => {
                setIsEditing(false)
                onSave()
              }}
            >
              Submit
            </Button>
          )}
        </div>
      </div>

      {/* {leadInfo?.name} */}
      <div
        style={{
          ...styles.gridContainer,
          ...(!isEditing ? { pointerEvents: 'none' } : {}),
        }}
      >
        <div style={styles.gridItem}>
          <LabelText>DATE</LabelText>
          <DatePicker
            // value={formData.name}
            style={styles.commonInputBorder}
            onChange={(date, dateString) => {
              console.log(date, dateString)
              setFormData((prev) => {
                return { ...prev, receivedDate: dateString }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>MEDIUM</LabelText>
          <Select
            style={{
              padding: '12px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
              padding: 5,
              overflow: 'hidden',
              width: '90%',
            }}
            // value={formData.leadSource}
            onChange={(value) => {
              setFormData((prev) => {
                return { ...prev, medium: value }
              })
            }}
          >
            <Option value="Bank Transfer">Bank Transfer</Option>
            <Option value="Cash">Cash</Option>
            <Option value="Cheque">Cheque</Option>
          </Select>
        </div>
        <div style={styles.gridItem}>
          <LabelText>ACCOUNT NUMBER</LabelText>
          <Input
            style={styles.commonInputBorder}
            // value={formData.accNo}
            // placeholder="+88"
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, accNo: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>MONTH</LabelText>
          <Input
            style={styles.commonInputBorder}
            // value={formData.location}
            // placeholder="+88"
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, month: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>TIME</LabelText>
          <Input
            style={styles.commonInputBorder}
            // value={formData.location}
            // placeholder="+88"
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, time: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>AMOUNT</LabelText>
          <Input
            style={styles.commonInputBorder}
            // value={formData.location}
            // placeholder="+88"
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, amount: e.target.value }
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    border: '1px solid #275058',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  backBtnStyle: {
    marginTop: 10,
    borderRadius: 25,
    width: 50,
    fontSize: '.7rem',
    padding: 0,
    height: 20,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr', // Three equal columns
    // gap: '16px', // Adjust the gap between columns
  },
  gridItem: {
    // backgroundColor: '#e0e0e0',
    padding: '16px',
    // textAlign: 'center',
    // border: '1px solid #ccc',
  },
  commonInputBorder: {
    padding: '8px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
  },
}

export default OverviewCard
