import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../../../components/Navbar'
import OverviewCard from './OverviewCard'
import LeaveFormTopRight from './LeaveFormTopRight'
import axiosInstance from '../../../../utils/axiosInstance'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

function LeaveForm() {
  const [leadInfo, setLeadInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [leaveEntries, setLeaveEntries] = useState([])
  const auth = useSelector((state) => state.auth)

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'Leave From',
      dataIndex: 'leaveFrom',
      key: 'leaveFrom',
      align: 'center',
    },
    {
      title: 'Leave Till',
      dataIndex: 'leaveTill',
      key: 'leaveTill',
      align: 'center',
    },
    {
      title: 'Address',
      dataIndex: 'leaveAddress',
      key: 'leaveAddress',
      align: 'center',
    },
    {
      title: '  Purpose',
      dataIndex: 'leavePurpose',
      key: 'leavePurpose',
      align: 'center',
    },
    {
      title: '  Status',
      dataIndex: 'leaveStatus',
      key: 'leaveStatus',
      align: 'center',
    },
  ]

  const getMyLeaveEntries = async () => {
    // setLoading(true)

    let pathString = `/cm/getLeaveEntryOfUser?id=${auth?.user?._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        // console.log(response.data)

        setLeaveEntries(response.data.leaveEntries)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyLeaveEntries()
  }, [])

  return (
    <div>
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            // margin: '0 16px',
            padding: '30px 16px',
            backgroundColor: '#fff',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '50%' }}>
              <OverviewCard leadInfo={leadInfo} />
            </div>
            <div style={{ width: '50%' }}>
              <LeaveFormTopRight leadInfo={leadInfo} />
            </div>
          </div>
          <h2 style={{ color: '#275058', textDecoration: 'underline' }}>
            LEAVE DETAILS
          </h2>
          <Table
            dataSource={leaveEntries}
            columns={columns.map((each) => ({
              ...each,
              className: 'maxWidthCell',
            }))}
            bordered
            pagination={false}
          />
        </Content>
      </Layout>
    </div>
  )
}

export default LeaveForm
