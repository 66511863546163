import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Modal, Popconfirm, Input, message, Button } from 'antd'
import { CloseCircleOutlined, FileOutlined } from '@ant-design/icons'

import axiosInstance from '../../../utils/axiosInstance'
import getExtensionFromMimeType from '../../../utils/getExtensionFromMimeType'

function ServicePackage({ leadInfo }) {
  const { servicePackage } = leadInfo

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState({})

  const handleSelectTag = async (key, value) => {
    console.log(value)
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateServicePackageInfo?id=${leadInfo._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }

  const handleReload = () => {
    window.location.reload()
  }

  const confirm = async (e) => {
    try {
      const response = await axiosInstance.put(
        `/cm/updateServicePackageInfo?id=${leadInfo._id}`,
        {}
      )
      console.log(response.data)
      // setAllLeads(response.data.allLeads)
      message.success(`Successfully Updated!`)

      setLoading(false)
      handleReload()
    } catch (error) {
      console.error('Request Error:', error)
      setLoading(false)
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 100,
          marginTop: 30,
        }}
      >

        <div style={{ marginTop: 10 }}>
          <div
            style={{
              color: '#275058',
              fontSize: '2rem',
              fontWeight: 'bold',
              boxShadow: 'inset 0px 3px 3px #00000029',
              padding: 30,
            }}
          >
            PACKAGE <br /> SELECTED
          </div>
          <div
            style={{
              background: '#275058',
              color: '#fff',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 30,
            }}
          >
            <p style={{ margin: 0, fontSize: '2rem', fontWeight: 'bold' }}>
              PACKAGE
            </p>
            <p
              style={{
                margin: 0,
                marginTop: 10,
                width: '100px',
                height: '100px',
                fontSize: '3rem',
                border: '1px solid white',
                borderRadius: '50%',
                textAlign: 'center',
                lineHeight: '100px',
                fontWeight: 'bold',
              }}
            >
              {leadInfo.servicePackage?.selectedPackage?.substr(-2)}
            </p>
          </div>
        </div>
        <div>
        <Popconfirm
          title="Delete the entry"
          description="Are you sure to delete this?"
          onConfirm={confirm}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>

          {parseInt(leadInfo.servicePackage?.selectedPackage?.substr(-2)) >
            0 && (
            <>
              <div style={styles.optionRow}>
                <p
                  style={{
                    margin: 0,
                    fontSize: '1.7rem',
                    color: '#275058',
                    minWidth: 500,
                  }}
                >
                  TREATMENT PLAN DELIVERED
                </p>
                <div>
                  <select
                    style={styles.selectStyle}
                    defaultValue={servicePackage?.treatmentPlanDelivered}
                    onChange={(event) => {
                      handleSelectTag(
                        'treatmentPlanDelivered',
                        event.target.value
                      )
                    }}
                  >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
                <div>
                  <select
                    style={styles.selectStyle}
                    defaultValue={servicePackage?.treatmentPlanNumber}
                    onChange={(event) => {
                      handleSelectTag('treatmentPlanNumber', event.target.value)
                    }}
                  >
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                  </select>
                </div>
              </div>
              <div style={styles.optionRow}>
                <p
                  style={{
                    margin: 0,
                    fontSize: '1.7rem',
                    color: '#275058',
                    minWidth: 500,
                  }}
                >
                  CALL CENTER SUPPORT GIVEN
                </p>
                <div>
                  <select
                    style={styles.selectStyle}
                    defaultValue={servicePackage?.callCenterSupportGiven}
                    onChange={(event) => {
                      handleSelectTag(
                        'callCenterSupportGiven',
                        event.target.value
                      )
                    }}
                  >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
              </div>
            </>
          )}
          {parseInt(leadInfo.servicePackage?.selectedPackage?.substr(-2)) >
            1 && (
            <>
              <div style={styles.optionRow}>
                <p
                  style={{
                    margin: 0,
                    fontSize: '1.7rem',
                    color: '#275058',
                    minWidth: 500,
                  }}
                >
                  COUNSELLING SERVICE{' '}
                </p>
                <div>
                  <select
                    style={styles.selectStyle}
                    defaultValue={servicePackage?.counsellingService}
                    onChange={(event) => {
                      handleSelectTag('counsellingService', event.target.value)
                    }}
                  >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
              </div>
            </>
          )}
          {parseInt(leadInfo.servicePackage?.selectedPackage?.substr(-2)) >
            2 && (
            <>
              <div style={styles.optionRow}>
                <p
                  style={{
                    margin: 0,
                    fontSize: '1.7rem',
                    color: '#275058',
                    minWidth: 500,
                  }}
                >
                  VISA PROCESSING & ASSISTANCE{' '}
                </p>
                <div>
                  <select
                    style={styles.selectStyle}
                    defaultValue={servicePackage?.visaProcessingAndAssistance}
                    onChange={(event) => {
                      handleSelectTag(
                        'visaProcessingAndAssistance',
                        event.target.value
                      )
                    }}
                  >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
              </div>
            </>
          )}
          {parseInt(leadInfo.servicePackage?.selectedPackage?.substr(-2)) >
            3 && (
            <>
              <div style={styles.optionRow}>
                <p
                  style={{
                    margin: 0,
                    fontSize: '1.7rem',
                    color: '#275058',
                    minWidth: 500,
                  }}
                >
                  AIRPORT / HOTEL TRANSFER{' '}
                </p>
                <div>
                  <select
                    style={styles.selectStyle}
                    defaultValue={servicePackage?.airportHotelTransfer}
                    onChange={(event) => {
                      handleSelectTag(
                        'airportHotelTransfer',
                        event.target.value
                      )
                    }}
                  >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
              </div>
              <div style={styles.optionRow}>
                <p
                  style={{
                    margin: 0,
                    fontSize: '1.7rem',
                    color: '#275058',
                    minWidth: 500,
                  }}
                >
                  GUIDE SERVICES
                </p>
                <div>
                  <select
                    style={styles.selectStyle}
                    defaultValue={servicePackage?.guideSerivces}
                    onChange={(event) => {
                      handleSelectTag('guideSerivces', event.target.value)
                    }}
                  >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  optionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    marginTop: 20,
  },
  selectStyle: {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
    padding: 5,
    overflow: 'hidden',
    fontSize: '1.5rem',
    color: '#275058',
    paddingRight: 20,
    boxShadow: 'inset 0px 3px 3px #00000029',
  },
}

export default ServicePackage
