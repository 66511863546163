import React, { useState, useRef, useEffect } from 'react'
import { ConfigProvider, Breadcrumb, Button, Form, Input, Spin } from 'antd'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import axiosInstance from '../utils/axiosInstance'
import { authslice } from '../store/auth/authSlice'

function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [error, showError] = useState(false)
  const { setUser } = authslice.actions

  const StyledContainer = styled.div`
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2rem;
  `
  const SignupFormContainer = styled.div`
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 70px;
  `
  const SignupFormSubContainer = styled.div`
    margin-top: 20px;
    width: 30%;
    border: 1px solid #275058;
    padding: 30px;
    border-radius: 20px;

    @media (max-width: 1200px) {
      width: 80%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  `
  const StyledLogo = styled.img`
    cursor: pointer;
    height: 96px;
    width: 213px;
    @media only screen and (max-width: 480px) {
      height: 70px;
      width: 165px;
      margin-top: 25px;
    }
  `
  const inputStyle = {
    padding: '12px',
    borderRadius: '26px',
  }
  const onFinish = async (values) => {
    console.log('Success:', values)
    const reqObj = {
      ...values,
    }

    try {
      showError(false)
      setLoading(true)

      const response = await axiosInstance.post('/auth/cMLogin', reqObj)
      console.log(response.data)
      dispatch(
        setUser({ token: response.data.token, user: response.data.data.user })
      )
      // You can handle the response data here
      // router.push('/')
      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      showError(true)
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    // console.log(auth?.token)
    if (auth?.token) {
      navigate('/dashboard')
    }
  }, [auth])

  return (
    <div className="mainStyle">
      {/* <Button type="primary">Primary Button</Button> */}
      <StyledContainer>
        <SignupFormContainer>
          <StyledLogo
            src="/images/icon.png"
            alt="Icon"
            // onClick={() => router.push('/')}
          />
          <SignupFormSubContainer>
            <Form
              ref={formRef}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input a valid email!',
                  },
                ]}
              >
                <Input style={inputStyle} placeholder="EMAIL" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password style={inputStyle} placeholder="PASSWORD" />
              </Form.Item>
              {error && (
                <Form.Item name="err">
                  <p style={{ margin: 0, color: 'red' }}>
                    Incorrect email or password
                  </p>
                </Form.Item>
              )}
              {/* <Form.Item name="fg">
                <p
                  style={{
                    textAlign: 'right',
                    color: '#275058',
                    marginBottom: 0,
                    cursor: 'pointer',
                  }}
                  // onClick={() => router.push('/reset-password')}
                >
                  FORGOT PASSWORD?
                </p>
              </Form.Item> */}
            </Form>
          </SignupFormSubContainer>

          {!loading ? (
            <Button
              style={{
                marginTop: 20,
                borderRadius: 25,
                width: 200,
                fontWeight: 'bold',
                color: '#275058',
              }}
              onClick={() => formRef.current.submit()}
            >
              Log In{' '}
            </Button>
          ) : (
            <Spin style={{ marginTop: '2rem' }} size="large" />
          )}
        </SignupFormContainer>
      </StyledContainer>
    </div>
  )
}

export default Login
