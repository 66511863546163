import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Input, Select, message } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import { data as treatments } from '../../constant/treatments'

import axiosInstance from '../../utils/axiosInstance'

const { Option } = Select

const LabelText = styled.p`
  color: #275058;
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 10px;
  //   text-transform: uppercase;
  //   border: 1px solid red;
  text-align: left;
`

function OverviewCard(props) {
  const { leadInfo } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [messageApi, contextHolder] = message.useMessage()
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(leadInfo)
  const [loading, setLoading] = useState(false)

  const onSave = async () => {
    //
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${formData._id}`,
          formData
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
        messageApi.open({
          type: 'success',
          content: 'Patient Information has been updated',
          duration: 5,
        })
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const handleSelectTag = async (key, value) => {
    //
    console.log(key)
    console.log(value)
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${formData._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const handleBackButton = () => {
    // console.log(location?.state?.sentFrom?.pathname)
    if (location?.state?.sentFrom?.pathname) {
      navigate(location?.state?.sentFrom?.pathname)
      return
    }
    navigate(-1)
  }

  //   console.log(leadInfo)
  if (!formData) return <></>

  return (
    <div style={styles.container}>
      {contextHolder}

      <div style={{ display: 'flex' }}>
        <div style={{ width: '10%' }}>
          <Button style={styles.backBtnStyle} onClick={handleBackButton}>
            Back{' '}
          </Button>
        </div>
        <div
          style={{
            height: 40,
            clipPath: ' polygon(7% 100%, 93% 100%, 100% 0, 0 0)',
            background: '#275058',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '80%',
          }}
        >
          <p
            style={{
              color: 'white',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: 2,
            }}
          >
            PATIENT INFORMATION
          </p>
        </div>
        <div
          style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}
        >
          {!isEditing ? (
            <EditOutlined
              style={{
                color: '#275058',
                fontSize: '1.2rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsEditing(true)
                messageApi.open({
                  type: 'info',
                  content: 'Form is now editable',
                })
              }}
            />
          ) : (
            <Button
              type="primary"
              style={styles.backBtnStyle}
              onClick={() => {
                setIsEditing(false)
                onSave()
              }}
            >
              Save
            </Button>
          )}
        </div>
      </div>

      {/* {leadInfo?.name} */}
      <div
        style={{
          ...styles.gridContainer,
          ...(!isEditing ? { pointerEvents: 'none' } : {}),
        }}
      >
        <div style={styles.gridItem}>
          <LabelText>Patient Name</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.name}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, name: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>Lead Source</LabelText>
          <Select
            style={{
              padding: '12px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
              padding: 5,
              overflow: 'hidden',
              width: '90%',
            }}
            value={formData.leadSource}
            onChange={(value) => {
              setFormData((prev) => {
                return { ...prev, leadSource: value }
              })
              handleSelectTag('leadSource', value)
            }}
          >
            <Option value="Website">Website</Option>
            <Option value="Facebook">Facebook</Option>
            <Option value="Contact Center">Contact Center</Option>
          </Select>
        </div>
        <div style={styles.gridItem}>
          <LabelText>Lead Created On</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              //   display: 'inline-block',
              border: '1px solid #275058',
              width: '80%',
            }}
            value="27.04.2023"
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>Age (YEAR)</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              //   display: 'inline-block',
              border: '1px solid #275058',
              width: '80%',
            }}
            value={formData.age}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, age: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>Passport Received</LabelText>
          <Select
            style={{
              padding: '12px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
              padding: 5,
              overflow: 'hidden',
              width: '90%',
            }}
            value={formData.passportReceived}
            onChange={(value) => {
              setFormData((prev) => {
                return { ...prev, passportReceived: value }
              })
              handleSelectTag('passportReceived', value)
            }}
          >
            <Option value="YES">YES</Option>
            <Option value="NO">NO</Option>
          </Select>
        </div>
        <div style={styles.gridItem}>
          <LabelText>Lead Status</LabelText>
          <Select
            style={{
              padding: '12px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
              padding: 5,
              overflow: 'hidden',
              width: '90%',
            }}
            value={formData.leadStatus}
            onChange={(value) => {
              setFormData((prev) => {
                return { ...prev, leadStatus: value }
              })
              handleSelectTag('leadStatus', value)
            }}
          >
            <Option value="underProcess">Under Process</Option>
            <Option value="onGoing">On Going</Option>
            <Option value="Lost">Lost</Option>
            <Option value="Converted">Converted</Option>
          </Select>
        </div>
        <div style={styles.gridItem}>
          <LabelText>Contact / WhatsApp Number</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              //   display: 'inline-block',
              border: '1px solid #275058',
              width: '80%',
            }}
            value={formData.contact}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, contact: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>Report Received</LabelText>
          <Select
            style={{
              padding: '12px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
              padding: 5,
              overflow: 'hidden',
              width: '90%',
            }}
            value={formData.reportReceived}
            onChange={(value) => {
              setFormData((prev) => {
                return { ...prev, reportReceived: value }
              })
              handleSelectTag('reportReceived', value)
            }}
          >
            <Option value="YES">YES</Option>
            <Option value="NO">NO</Option>
          </Select>
        </div>
        <div style={styles.gridItem}>
          <LabelText>Location</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              //   display: 'inline-block',
              border: '1px solid #275058',
              width: '80%',
            }}
            value={formData.location}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, location: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>Email ID</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              //   display: 'inline-block',
              border: '1px solid #275058',
              width: '80%',
            }}
            value={formData.email}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, email: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>*Treatment Segment</LabelText>

          <Select
            style={{
              padding: '12px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
              padding: 5,
              overflow: 'hidden',
              width: '90%',
            }}
            value={formData.treatmentSegment}
            onChange={(e) => {
              console.log(e)
              setFormData((prev) => {
                return { ...prev, treatmentSegment: e }
              })
            }}
          >
            {treatments.map((segment, ind) => {
              return (
                <Option value={segment.title} key={ind}>
                  {segment.title}
                </Option>
              )
            })}
          </Select>
        </div>
        <div style={styles.gridItem}>
          <LabelText>Treatment Category</LabelText>
          <Select
            style={{
              padding: '12px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
              padding: 5,
              overflow: 'hidden',
              width: '90%',
            }}
            value={formData.treatmentCategory}
            onChange={(value) => {
              console.log(value)
              setFormData((prev) => {
                return { ...prev, treatmentCategory: value }
              })
            }}
          >
            <Option value="OPD">OPD</Option>
            <Option value="IPD">IPD</Option>
          </Select>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    border: '1px solid #275058',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  backBtnStyle: {
    marginTop: 10,
    borderRadius: 25,
    width: 50,
    fontSize: '.7rem',
    padding: 0,
    height: 20,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr', // Three equal columns
    // gap: '16px', // Adjust the gap between columns
  },
  gridItem: {
    // backgroundColor: '#e0e0e0',
    padding: '16px',
    // textAlign: 'center',
    // border: '1px solid #ccc',
  },
}

export default OverviewCard
