import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function SalaryAction({ cmUser }) {
  const [loading, setLoading] = useState(false)
  const [meetingEntries, seMeetingEntries] = useState([])
  const [formData, setFormData] = useState({})
  const [noOfSalaryDeducted, setNoOfSalaryDeducted] = useState(0)
  const [deductionAmount, setDeductionAmount] = useState(0)
  const [noOfIncrement, setNoOfIncrement] = useState(0)

  const getSalaryEntryOfAUser = async () => {
    // setLoading(true)

    let pathString = `/su/getSalaryEntryOfAUser?id=${cmUser._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        response.data.results.forEach((element) => {
          if (parseInt(element.deductionAmount) > 0) {
            setNoOfSalaryDeducted((p) => p + 1)
            setDeductionAmount((p) => p + parseFloat(element.deductionAmount))
          }
          if (parseInt(element.incrementAmount) > 0) {
            setNoOfIncrement((p) => p + 1)
          }
        })

        seMeetingEntries(response.data.results)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSalaryEntryOfAUser()
  }, [])

  return (
    <div style={{ color: '#275058', textAlign: 'center' }}>
      <h2 style={{ textDecoration: 'underline' }}>SALARY SUMMARY</h2>

      <div
        style={{
          color: '#275058',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          minHeight: 100,
        }}
      >
        <div>
          <p style={{ fontWeight: 'bold' }}>NO. OF SALARIES DISBURSED</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {meetingEntries.length}
          </div>
        </div>
        <div>
          <p style={{ fontWeight: 'bold' }}>NO. OF SALARY DEDUCTED</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {noOfSalaryDeducted}
          </div>
        </div>
      </div>
      <div
        style={{
          color: '#275058',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          minHeight: 100,
        }}
      >
        <div>
          <p style={{ fontWeight: 'bold' }}>DEDUCTION AMOUNT</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {deductionAmount}
          </div>
        </div>
        <div>
          <p style={{ fontWeight: 'bold' }}>NO. OF INCREMENT</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {noOfIncrement}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalaryAction
