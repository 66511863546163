import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb, Layout, Menu, theme, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

import axiosInstance from '../utils/axiosInstance'

import Navbar from '../components/Navbar'
import MySider from '../components/MySider'
import CapabilityBanner from '../components/CapabilityBanner'

const { Header, Content, Footer, Sider } = Layout

function Home() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const isSu = useState(auth?.user?.type === 'su' ? true : false)[0]
  const [SUInfo, setSUInfo] = useState({})
  const [CMInfo, setCMInfo] = useState({})

  const suTeamCards = [
    {
      title: 'REPORT RECEIVED',
      number: SUInfo?.totalPatients || 0,
      clickValue: '/reportReceived',
    },
    {
      title: 'PASSPORT RECEIVED',
      number: SUInfo?.totalPatients || 0,
      clickValue: '/passportReceived',
    },
    // { title: 'TOTAL PATIENTS', number: 0, clickValue: '/totalPatients' },
    {
      title: 'TOTAL PATIENTS',
      number: SUInfo?.totalPatients || 0,
      clickValue: '/allLeads',
    },
    {
      title: 'LEAD STATUS',
      number: SUInfo?.totalPatients || 0,
      clickValue: '/leadStatus',
    },
    {
      title: 'TELE-MEDICINE',
      number: SUInfo?.totalTelemedicineEntry || 0,
      clickValue: '/TeleMedicine',
    },
    {
      title: 'VISA & VIL',
      number: SUInfo?.totalVisaEntry || 0,
      clickValue: '/visaVil',
    },
    {
      title: 'SERVICE PACKAGE SOLD',
      number: SUInfo?.totalServicePackageSold || 0,

      clickValue: '/servicePackage',
    },
    {
      title: 'FLY-OUT CONFIRMED',
      number: SUInfo?.totalConfirmedFlyOut || 0,
      clickValue: '/flyOut',
    },
    {
      title: 'PATIENT DISCHARGED',
      number: SUInfo?.totalDischarged || 0,
      clickValue: '/patientDischarged',
    },
  ]

  const cmTeamCards = [
    {
      title: 'TOTAL PATIENTS',
      number: CMInfo?.totalPatients || 0,
      clickValue: '/allLeads',
    },
    {
      title: 'TREATEMENT PLAN REQUESTED',
      number: CMInfo?.leadRequests || 0,
      clickValue: '/dashboard',
    },
    {
      title: 'TREATEMENT PLAN REQUESTED DELIVERED',
      number: `Not found`,
      clickValue: '/dashboard',
    },
    {
      title: 'VISA INVITATION LETTER REQUEST',
      number: `Not found`,
      clickValue: '/dashboard',
    },
    {
      title: 'VISA UNDER PROCESS',
      number: CMInfo?.visaUnderProcessTotal || 0,
      clickValue: '/dashboard',
    },
    {
      title: 'FLY-OUT CONFIRMED',
      number: CMInfo?.totalConfirmedFlyOut || 0,
      clickValue: '/dashboard',
    },
    {
      title: 'TELE-MEDICINE REQUEST',
      number: CMInfo?.totalTelemedicineEntry || 0,
      clickValue: '/dashboard',
    },
    {
      title: 'SERVICE PACKAGE SOLD',
      number: CMInfo?.totalServicePackageSold || 0,
      clickValue: '/dashboard',
    },
    {
      title: 'PATIENT DISCHARGED',
      number: CMInfo?.totalDischarged || 0,
      clickValue: '/dashboard',
    },
  ]

  const commonCards = [
    {
      title: 'WEBSITE LEADS',
      number: SUInfo?.websiteLeads || CMInfo?.websiteLeads || 0,
      clickValue: '/allLeads?leadSource=Website',
    },
    {
      title: 'FACEBOOK LEADS',
      number: SUInfo?.facebookLeads || CMInfo?.facebookLeads || 0,
      clickValue: '/allLeads?leadSource=Facebook',
    },
    {
      title: 'CONTACT CENTER LEADS',
      number: SUInfo?.contactCenterLeads || CMInfo?.contactCenterLeads || 0,
      clickValue: '/allLeads?leadSource=contactCenter',
    },
    {
      title: 'IPD / OPD',
      number: SUInfo?.totalPatients || CMInfo?.totalPatients || 0,
      clickValue: '/allLeads',
    },
    { title: 'CHANNEL PARTNERS', number: 0, clickValue: '/allLeads' },
    { title: 'ON GOING CAMPAIGN', number: 0, clickValue: '/allLeads' },
  ]

  const dashboardCountSU = async () => {
    try {
      let pathString = `/su/dashboardCountSU`
      const response = await axiosInstance.get(pathString)
      console.log(response.data)
      setSUInfo(response.data)
    } catch (error) {
      console.error('Request Error:', error)
    }
  }
  const dashboardCountCM = async () => {
    try {
      let pathString = `/cm/dashboardCountCM`
      const response = await axiosInstance.get(pathString)
      console.log(response.data)
      setCMInfo(response.data)
    } catch (error) {
      console.error('Request Error:', error)
    }
  }

  useEffect(() => {
    if (isSu) {
      dashboardCountSU()
    } else {
      dashboardCountCM()
    }
  }, [])

  return (
    <>
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        {' '}
        <MySider></MySider>
        <Content
          style={{
            // margin: '0 16px',
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {[...(isSu ? suTeamCards : cmTeamCards)].map((el, idx) => (
              <div
                onClick={() => navigate(el.clickValue)}
                key={idx}
                style={{
                  cursor: 'pointer',
                  border: '1px solid #275058',
                  color: '#275058',
                  width: '30%', // Set the width to 30% for each column
                  marginBottom: '20px', // Add some spacing between columns
                  boxSizing: 'border-box', // Ensure padding and borders don't affect width
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 120,
                  boxShadow: 'inset 0px 3px 3px #00000029',
                }}
              >
                <h1
                  style={{
                    margin: 0,
                    fontSize: '1.2rem',
                    textAlign: 'center',
                  }}
                >
                  {el.title}
                </h1>
                <h1 style={{ margin: 0, marginTop: 10, fontSize: '1.3rem' }}>
                  {el.number}
                </h1>
              </div>
            ))}
          </div>

          {/* <h1>Contents goes here</h1> */}
          <div style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              style={{
                borderRadius: 25,
                width: 200,
                fontWeight: 'bold',
                letterSpacing: '1px',
                fontSize: '1.2rem',
                padding: 0,
              }}
              onClick={() => navigate('/createLead')}
            >
              Create Lead{' '}
            </Button>
          </div>

          <div
            style={{
              marginTop: 20,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {commonCards.map((el, idx) => (
              <div
                onClick={() => navigate(el.clickValue)}
                key={idx}
                style={{
                  cursor: 'pointer',
                  border: '1px solid #275058',
                  color: '#275058',
                  width: '30%', // Set the width to 30% for each column
                  marginBottom: '20px', // Add some spacing between columns
                  boxSizing: 'border-box', // Ensure padding and borders don't affect width
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 120,
                  boxShadow: 'inset 0px 3px 3px #00000029',
                }}
              >
                <h1 style={{ margin: 0, fontSize: '1.3rem' }}>{el.title}</h1>
                <h1 style={{ margin: 0, marginTop: 10, fontSize: '1.3rem' }}>
                  {el.number}
                </h1>
              </div>
            ))}
          </div>

          <CapabilityBanner />
        </Content>
      </Layout>
    </>
  )
}

export default Home
