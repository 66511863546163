import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function Incentives({ leadInfo }) {
  const [loading, setLoading] = useState(false)
  const [leaveEntries, seMeetingEntries] = useState([])
  const [formData, setFormData] = useState({})

  const handleSelectTag = async (value, key, _id) => {
    //
    // console.log(value)
    // console.log({
    //   formData: {
    //     [_id]: { [key]: value },
    //   },
    // })
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(`/su/updateIncentiveEntry`, {
          formData: {
            [_id]: { [key]: value },
          },
        })
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'RECEIVED DATE',
      dataIndex: 'receivedDate',
      key: 'receivedDate',
      align: 'center',
    },
    {
      title: 'MEDIUM',
      dataIndex: 'medium',
      key: 'medium',
      align: 'center',
    },
    {
      title: 'AMOUNT', // TODO: Ask why this is not taken from the CM user form
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: 'INCENTIVE FOR',
      dataIndex: 'incentiveFor',
      key: 'incentiveFor',
      align: 'center',
    },
    {
      title: 'TARGET',
      dataIndex: 'target',
      key: 'target',
      align: 'center',
    },
    {
      title: 'ACHIEVEMENT',
      dataIndex: 'achievement',
      key: 'achievement',
      align: 'center',
    },
    {
      title: 'CAMPAIGN NAME',
      dataIndex: 'campaignName',
      key: 'campaignName',
      align: 'center',
    },
    {
      title: 'CAMPAIGN FROM',
      dataIndex: 'timeLineFrom',
      key: 'timeLineFrom',
      align: 'center',
    },
    {
      title: 'CAMPAIGN TILL',
      dataIndex: 'timeLineTo',
      key: 'timeLineTo',
      align: 'center',
    },
    {
      title: 'STATUS',
      render: (text, record, index) => {
        // return <span>{record.status}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.status}
            onChange={(event) => {
              handleSelectTag(event.target.value, 'status', record._id)
            }}
          >
            <option value="Not Approved">Not Approved</option>
            <option value="Approved">Approved</option>
          </select>
        )
      },
      key: 'status',
      align: 'center',
    },
  ]

  const getIncentivesOfAUser = async () => {
    // setLoading(true)

    let pathString = `/su/getIncentivesOfAUser?id=${leadInfo._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        seMeetingEntries(response.data.results)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getIncentivesOfAUser()
  }, [])

  return (
    <div>
      {' '}
      <br />
      <Table
        dataSource={leaveEntries}
        columns={columns.map((each) => ({
          ...each,
          className: 'maxWidthCell',
        }))}
        bordered
        pagination={false}
      />
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default Incentives
