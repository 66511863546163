import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function OfficeRequirements({ leadInfo }) {
  const [loading, setLoading] = useState(false)
  const [officeRequirements, seMeetingEntries] = useState([])
  const [formData, setFormData] = useState({})

  const handleSelectTag = async (value, key, _id) => {
    //
    // console.log(value)
    // console.log({
    //   formData: {
    //     [_id]: { [key]: value },
    //   },
    // })
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/su/updateOfficeRequirement`,
          {
            formData: {
              [_id]: { [key]: value },
            },
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'REQUEST FOR',
      dataIndex: 'requestFor',
      key: 'requestFor',
      align: 'center',
    },
    {
      title: 'REQUEST DATE',
      dataIndex: 'requestDate',
      key: 'requestDate',
      align: 'center',
    },
    {
      title: 'REQUIRED DATE',
      dataIndex: 'requiredDate',
      key: 'requiredDate',
      align: 'center',
    },
    {
      title: 'REQUIRED QUANTITY',
      dataIndex: 'requiredQuantity',
      key: 'requiredQuantity',
      align: 'center',
    },
    {
      title: 'REQUIRED BUDGET',
      dataIndex: 'requiredBudget',
      key: 'requiredBudget',
      align: 'center',
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor',
      key: 'vendor',
      align: 'center',
    },
    {
      title: 'PAYMENT METHOD',
      render: (text, record, index) => {
        // return <span>{record.paymentMethod}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.paymentMethod}
            onChange={(event) => {
              handleSelectTag(event.target.value, 'paymentMethod', record._id)
            }}
          >
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
          </select>
        )
      },
      key: 'paymentMethod',
      align: 'center',
    },
    {
      title: 'NOTES',
      dataIndex: 'others',
      key: 'others',
      align: 'center',
    },
    {
      title: 'STATUS',
      render: (text, record, index) => {
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.requirementStatus}
            onChange={(event) => {
              handleSelectTag(
                event.target.value,
                'requirementStatus',
                record._id
              )
            }}
          >
            <option value="Not Approved">Not Approved</option>
            <option value="Approved">Approved</option>
          </select>
        )

        //  return <span>{record.requirementStatus}</span>
      },
      key: 'requirementStatus',
      align: 'center',
    },
  ]

  const getOfficeReqOfAUser = async () => {
    // setLoading(true)

    let pathString = `/su/getOfficeReqOfAUser?id=${leadInfo._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        seMeetingEntries(response.data.results)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getOfficeReqOfAUser()
  }, [])
  return (
    <div>
      <br />
      <Table
        dataSource={officeRequirements}
        columns={columns.map((each) => ({
          ...each,
          className: 'maxWidthCell',
        }))}
        bordered
        pagination={false}
      />
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default OfficeRequirements
