import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import moment from 'moment'
import axiosInstance from '../../../../../utils/axiosInstance'

// allowedLeaves
const causalLeaves = 12
const annualLeaves = 10
const medicalLeaves = 10

function LeaveAction({ cmUser }) {
  const [loading, setLoading] = useState(false)
  const [leaveEntries, setLeaveEntries] = useState([])
  const [approvedLeave, setApprovedLeave] = useState(0)
  const [notapprovedLeave, setNotApprovedLeave] = useState(0)
  const [availed, setAvailed] = useState({
    Casual: 0,
    Annual: 0,
    Medical: 0,
  })
  const [left, setLeft] = useState({
    Casual: 0,
    Annual: 0,
    Medical: 0,
  })

  const getMyLeaveEntries = async () => {
    // setLoading(true)

    let pathString = `/cm/getLeaveEntryOfUser?id=${cmUser?._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        const leaves = response.data.leaveEntries

        setLeaveEntries(leaves)

        let al = 0 // approved ( LEAVE HISTORY )
        let nal = 0 // not approved ( LEAVE HISTORY )
        leaves.forEach((element, idx) => {
          if (element.leaveStatus === 'Approved') {
            al++

            if (element.leaveTill) {
              // Convert the assignedDate string to a Date object
              let assignedDateObj = new Date(element.leaveTill)
              // console.log(assignedDateObj)

              // Get the current date
              let currentDate = new Date()
              // console.log(currentDate)

              if (assignedDateObj < currentDate) {
                // console.log('The assigned date has passed.')
                if (element.leaveType === 'Casual') {
                  setAvailed((prev) => ({ ...prev, Casual: prev.Casual + 1 }))
                }
                if (element.leaveType === 'Annual') {
                  setAvailed((prev) => ({ ...prev, Annual: prev.Annual + 1 }))
                }
                if (element.leaveType === 'Medical') {
                  setAvailed((prev) => ({ ...prev, Medical: prev.Medical + 1 }))
                }
              } else {
                // console.log('The assigned date is in the future.')
                // * Logic version 1 ( Logic version 2 done at "data2" variable )
                // if (element.leaveType === 'Casual') {
                //   setLeft((prev) => ({ ...prev, Casual: prev.Casual + 1 }))
                // }
                // if (element.leaveType === 'Annual') {
                //   setLeft((prev) => ({ ...prev, Annual: prev.Annual + 1 }))
                // }
                // if (element.leaveType === 'Medical') {
                //   setLeft((prev) => ({ ...prev, Medical: prev.Medical + 1 }))
                // }
                // * Logic version 2 No need to count left leaves here instead count at "data2" variable
              }
            }
          }
          if (element.leaveStatus === 'Not Approved') nal++

          if (idx + 1 == leaves.length) setApprovedLeave(al)
          if (idx + 1 == leaves.length) setNotApprovedLeave(nal)
        })
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyLeaveEntries()
  }, [])

  const column1 = [
    {
      title: 'Approved',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Not Approved',
      dataIndex: 'age',
      key: 'age',
      align: 'center',
    },
  ]
  const data1 = [
    {
      key: '1',
      name: approvedLeave,
      age: notapprovedLeave,
    },
  ]

  const column2 = [
    {
      title: '',
      // dataIndex: 'name',
      render: (text, record, index) => (
        <span>{index === 0 ? 'Availed' : 'Left'}</span>
      ),
      key: 'availed',
      align: 'center',
    },
    {
      title: 'Casual',
      dataIndex: 'casual',
      key: 'casual',
      align: 'center',
    },
    {
      title: 'Annual',
      dataIndex: 'annual',
      key: 'annual',
      align: 'center',
    },
    {
      title: 'Medical',
      dataIndex: 'medical',
      key: 'medical',
      align: 'center',
    },
  ]

  const data2 = [
    {
      key: '1',
      casual: availed.Casual,
      annual: availed.Annual,
      medical: availed.Medical,
    },
    {
      key: '2',
      // Logic version 1 ( only keep at left if the date is not passed)
      // casual: left.Casual,
      // annual: left.Annual,
      // medical: left.Medical,
      // Logic version 2 ( updated requirement by team: keep a hardcoded amount of leaves )
      casual: causalLeaves - availed.Casual,
      annual: annualLeaves - availed.Annual,
      medical: medicalLeaves - availed.Medical,
    },
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200,
        // border: '1px solid green',
        color: '#275058',
      }}
    >
      {' '}
      <p
        style={{
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          textDecoration: 'underline',
          fontSize: '2rem',
        }}
      >
        LEAVE SUMMARY
      </p>
      <Table dataSource={data2} columns={column2} bordered pagination={false} />
      <p
        style={{
          textDecoration: 'underline',
          fontSize: '1.5rem',
        }}
      >
        LEAVE HISTORY
      </p>
      <Table dataSource={data1} columns={column1} bordered pagination={false} />
    </div>
  )
}

export default LeaveAction
