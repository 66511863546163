import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BellOutlined, UserOutlined } from '@ant-design/icons'
import { Badge, Space, Dropdown, Button, message } from 'antd'
import { authslice } from '../store/auth/authSlice'

import axiosInstance from '../utils/axiosInstance'

const StyledLogo = styled.img`
  cursor: pointer;
  height: 60px;
  width: 150px;
  @media only screen and (max-width: 480px) {
    height: 70px;
    width: 165px;
    margin-top: 25px;
  }
`

function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  const [messageApi, contextHolder] = message.useMessage()
  const [totalUnreadNotif, setTotalUnreadNotif] = useState(0)
  const isSu = useState(auth?.user?.type === 'su' ? true : false)[0]

  const { logout } = authslice.actions

  const getUnreadNotificationCount = async () => {
    try {
      let pathString = `/cm/getUnreadNotificationCount`
      const response = await axiosInstance.get(pathString)
      // console.log(response.data?.unreadCount)
      setTotalUnreadNotif(response.data?.unreadCount)
    } catch (error) {
      console.error('Request Error:', error)
    }
  }

  const checkInHit = async () => {
    try {
      let pathString = `/cm/checkIn`
      const response = await axiosInstance.post(pathString)
      messageApi.open({
        type: 'success',
        content: 'Record updated!',
      })
    } catch (error) {
      console.error('Request Error:', error)
      messageApi.open({
        type: 'error',
        content: 'Failed to update',
      })
    }
  }
  const checOutHit = async () => {
    try {
      let pathString = `/cm/checkOut`
      const response = await axiosInstance.post(pathString)
      messageApi.open({
        type: 'success',
        content: 'Record updated!',
      })
    } catch (error) {
      console.error('Request Error:', error)
      messageApi.open({
        type: 'error',
        content: 'Failed to update',
      })
    }
  }

  useEffect(() => {
    //
    getUnreadNotificationCount()

    // Remove this below portion after 366 days ( or if you can make sure the "mahbub.kareem" email is logged out successfully )
    // Check if the flag is present in localStorage
    const logoutFlag_mk101 = localStorage.getItem('logoutFlag_mk101')
    // If the flag is not present, call logout() and set the flag
    if (!logoutFlag_mk101) {
      dispatch(logout())
      // Set the flag in localStorage to indicate that logout() has been called
      localStorage.setItem('logoutFlag_mk101', 'true')
    }
  }, [])

  const items = [
    ...(isSu
      ? []
      : [
          {
            key: '1',
            label: (
              <Button
                type="link"
                style={{ width: '100%', textAlign: 'left' }}
                onClick={() => {
                  checkInHit()
                }}
              >
                Check in
              </Button>
            ),
          },
          {
            key: '2',
            label: (
              <Button
                type="link"
                style={{ width: '100%', textAlign: 'left' }}
                onClick={() => {
                  checOutHit()
                }}
              >
                Check-out
              </Button>
            ),
          },
          {
            key: '3',
            label: (
              <Button
                type="link"
                onClick={() => navigate('/leaveForm')}
                style={{ width: '100%', textAlign: 'left' }}
              >
                Leave
              </Button>
            ),
          },
          {
            key: '4',
            label: (
              <Button
                type="link"
                onClick={() => navigate('/meetingReqForm')}
                style={{ width: '100%', textAlign: 'left' }}
              >
                Meetings
              </Button>
            ),
          },
          {
            key: '5',
            label: (
              <Button
                type="link"
                onClick={() => navigate('/salaryReceived')}
                style={{ width: '100%', textAlign: 'left' }}
              >
                Salary
              </Button>
            ),
          },
          {
            key: '6',
            label: (
              <Button
                type="link"
                onClick={() => navigate('/incentivesForm')}
                style={{ width: '100%', textAlign: 'left' }}
              >
                Incentives
              </Button>
            ),
          },
          {
            key: '7',
            label: (
              <Button
                type="link"
                onClick={() => navigate('/special-Request')}
                style={{ width: '100%', textAlign: 'left' }}
              >
                Special Request
              </Button>
            ),
          },
          {
            key: '8',
            label: (
              <Button
                type="link"
                onClick={() => navigate('/officeRequirement')}
                style={{ width: '100%', textAlign: 'left' }}
              >
                Office Requirement
              </Button>
            ),
          },
          {
            key: '9',
            label: (
              <Button
                type="link"
                onClick={() => navigate('/attendanceSummary')}
                style={{ width: '100%', textAlign: 'left' }}
              >
                Attendance Summary
              </Button>
            ),
          },
        ]),
    {
      key: '10',
      label: (
        <Button
          danger
          type="link"
          onClick={() => {
            dispatch(logout())
          }}
        >
          Logout
        </Button>
      ),
    },
  ]

  return (
    <div
      style={{
        height: 70,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 3px 6px #00000029',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        position: 'relative',
      }}
    >
      {contextHolder}
      <div>
        <StyledLogo
          src="/images/icon.png"
          alt="Icon"
          onClick={() => navigate('/dashboard')}
        />
      </div>
      <div>
        <p style={{ margin: 0, fontSize: '1.5rem' }}>
          Hello {auth?.user?.name}! Here's what's happening in your account
          today
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 15,
        }}
      >
        <div
          onClick={() => {
            navigate('/notifications')
          }}
        >
          {' '}
          <Badge count={totalUnreadNotif} overflowCount={2000}>
            <BellOutlined
              style={{
                color: '#275058',
                fontSize: '1.4rem',
                cursor: 'pointer',
              }}
            />
          </Badge>
        </div>
        <div>
          <p
            style={{
              margin: 0,
              color: '#275058',
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            {auth?.user?.name}{' '}
          </p>
        </div>
        <div>
          <Dropdown
            overlayStyle={{ width: 300, backgroundColor: '#275058' }}
            menu={{
              items,
            }}
          >
            <img
              src="/contents/icons/PROFILE.png"
              style={{ height: '1.8rem' }}
              alt=""
            />
          </Dropdown>
          {/* <UserOutlined style={{ color: '#275058', fontSize: '1.4rem' }} /> */}
        </div>
      </div>
    </div>
  )
}

export default Navbar
