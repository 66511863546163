import React, { useState } from 'react'
import { Upload, Button, message, Dropdown, Spin } from 'antd'
import { UploadOutlined, CaretDownOutlined } from '@ant-design/icons'

import axiosInstance from '../../../utils/axiosInstance'

function ActionServicePackage({ leadInfo }) {
  const [loading, setLoading] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState('')

  const items = [
    {
      key: '1',
      label: (
        <div
          style={styles.packageBtn}
          onClick={() => {
            setSelectedPackage('PACKAGE-01')
          }}
        >
          PACKAGE 01{' '}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          style={styles.packageBtn}
          onClick={() => {
            setSelectedPackage('PACKAGE-02')
          }}
        >
          PACKAGE 02{' '}
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div
          style={styles.packageBtn}
          onClick={() => {
            setSelectedPackage('PACKAGE-03')
          }}
        >
          PACKAGE 03{' '}
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div
          style={styles.packageBtn}
          onClick={() => {
            setSelectedPackage('PACKAGE-04')
          }}
        >
          PACKAGE 04{' '}
        </div>
      ),
    },
  ]

  const sendPackageUpdate = async () => {
    //
    if (!selectedPackage) return
    try {
      // showError(false)
      setLoading(true)

      try {
        const response = await axiosInstance.post(
          `/cm/updateServicePackage?id=${leadInfo._id}`,
          { selectedPackage }
        )
        setLoading(false)
        message.success('Success')
        window.location.reload()
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200,
        // border: '1px solid green',
      }}
    >
      <p
        style={{
          color: '#275058',
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          textDecoration: 'underline',
          fontSize: '2rem',
        }}
      >
        ACTION{' '}
      </p>

      <div>
        <Dropdown
          overlayStyle={{ backgroundColor: 'red' }}
          menu={{
            items,
          }}
          placement="bottomLeft"
        >
          <Button
            type="primary"
            style={{
              borderRadius: 25,
              textTransform: 'uppercase',
              height: 50,
              width: 300,
              fontSize: '1.2rem',
            }}
          >
            {selectedPackage ? (
              selectedPackage
            ) : (
              <>
                {' '}
                SERVICE PACKAGE <CaretDownOutlined />
              </>
            )}
          </Button>
        </Dropdown>

        {loading && (
          <div className="overlay">
            <div style={{ textAlign: 'center', marginTop: '9rem' }}>
              <Spin size="large" />
            </div>
          </div>
        )}

        <div
          style={{
            marginTop: 40,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            // type="primary"
            style={styles.saveBtn}
            onClick={() => {
              sendPackageUpdate()
            }}
          >
            Save{' '}
          </Button>
          <Button
            // type="primary"
            style={styles.saveBtn}
            onClick={() => {
              window.location.reload()
            }}
          >
            Cancel{' '}
          </Button>
        </div>
      </div>
    </div>
  )
}

const styles = {
  saveBtn: {
    boxShadow: 'inset 0px 3px 3px #00000029',
    borderRadius: 15,
    textTransform: 'uppercase',
    width: 100,
    fontSize: '1.1rem',
    color: '#275058',
    padding: 0,
  },
  packageBtn: {
    borderRadius: 25,
    border: '1px solid #275058',
    padding: 5,
    fontSize: '1.5rem',
    color: '#275058',
    textAlign: 'center',
    boxShadow: 'inset 0px 3px 3px #00000029',
  },
}

export default ActionServicePackage
