import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import moment from 'moment'
import axiosInstance from '../../../../../utils/axiosInstance'

function Leave({ leadInfo }) {
  const [loading, setLoading] = useState(false)
  const [leaveEntries, setLeaveEntries] = useState([])

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'Leave Type',
      dataIndex: 'leaveType',
      key: 'leaveType',
      align: 'center',
    },
    {
      title: 'Leave From',
      dataIndex: 'leaveFrom',
      key: 'leaveFrom',
      align: 'center',
    },
    {
      title: 'Leave Till',
      dataIndex: 'leaveTill',
      key: 'leaveTill',
      align: 'center',
    },
    {
      title: '  Purpose',
      dataIndex: 'leavePurpose',
      key: 'leavePurpose',
      align: 'center',
    },
    {
      title: 'Address During Leave',
      dataIndex: 'leaveAddress',
      key: 'leaveAddress',
      align: 'center',
    },
    {
      title: 'Contact',
      dataIndex: 'mainContact',
      key: 'mainContact',
      align: 'center',
    },
    {
      title: 'REQUEST DATE',
      //   dataIndex: 'createdAt',
      // {moment(el.createdAt).format('DD/MM/YYYY')}
      render: (text, record, index) => (
        <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>
      ),
      key: 'createdAt',
      align: 'center',
    },
    {
      title: 'Status',
      // dataIndex: 'leaveStatus',
      render: (text, record, index) => {
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.leaveStatus}
            onChange={(event) => {
              handleSelectTag(event.target.value, 'leaveStatus', record._id)
            }}
          >
            <option value="Not Approved">Not Approved</option>
            <option value="Approved">Approved</option>
          </select>
        )

        //  return <span>{record.leaveStatus}</span>
      },
      key: 'leaveStatus',
      align: 'center',
    },
  ]

  const getMyLeaveEntries = async () => {
    // setLoading(true)

    let pathString = `/cm/getLeaveEntryOfUser?id=${leadInfo?._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        setLeaveEntries(response.data.leaveEntries)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const handleSelectTag = async (value, key, _id) => {
    //

    console.log(value)
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLeaveEntry?leaveFormID=${_id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyLeaveEntries()
  }, [])

  return (
    <div

    // style={{ padding: 20 }}
    >
      {' '}
      <Table
        style={{ width: '97%', margin: 20 }}
        dataSource={leaveEntries}
        columns={columns.map((each) => ({
          ...each,
          className: 'maxWidthCell',
        }))}
        bordered
        pagination={false}
      />
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default Leave
