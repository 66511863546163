import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  TimePicker,
  Spin,
  Tooltip,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'
import dayjs from 'dayjs'

function Attendance({ leadInfo }) {
  const [loading, setLoading] = useState(false)
  const [attendanceEntries, setattendanceEntries] = useState([])
  const [formData, setFormData] = useState({})
  const auth = useSelector((state) => state.auth)

  const onSave = async () => {
    // console.log(formData)
    // return
    try {
      console.log(formData)
      const response = await axiosInstance.put(`/su/updateAttendenceEntry`, {
        formData,
      })
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'DATE',
      render: (text, record, index) => (
        <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>
      ),
      key: 'DATE',
      align: 'center',
    },
    {
      title: (
        <>
          <Tooltip
            color="red"
            title="All entry after 10:15 am will create another record as late entry. (C)" // At special request form
          >
            CHECK-IN TIME <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      render: (text, record, index) => (
        <span>{moment(record.checkInTime).format('hh:mm A')}</span>
      ),
      key: 'checkInTime',
      align: 'center',
    },
    {
      title: 'CHECK-OUT TIME',
      // render: (text, record, index) => (
      // <span>
      //   {record.checkOutTime
      //     ? moment(record.checkOutTime).format('hh:mm A')
      //     : ''}
      // </span>
      // ),
      render: (text, record, index) => (
        <>
          {!record.checkOutTime ? (
            <TimePicker
              use12Hours
              // defaultValue={moment(1701576668414, 'HH:mm:ss')}
              // defaultValue={moment(record?.checkOutTime, 'HH:mm:ss')}
              // defaultValue={new Date(record.checkInTime)}  // ! No idea how to show the epoch timestamp properly here at defaultValue
              onSelect={(value) => {
                //
              }}
              onChange={(value) => {
                // * its always giving current timestamp so we need the date which was the checkin
                // const epochTimestamp = new Date(value).getTime()

                if (record.checkInTime) {
                  // Get checking date
                  const checkInTime = new Date(record.checkInTime)
                  const day = checkInTime.getUTCDate()
                  const month = checkInTime.getUTCMonth() + 1 // Months are zero-based, so add 1
                  const year = checkInTime.getUTCFullYear()
                  console.log(day, month, year)

                  // add the checkin date to the selected time
                  const selectedCheckoutTimeValue = new Date(value)
                  selectedCheckoutTimeValue.setDate(day)
                  selectedCheckoutTimeValue.setMonth(month - 1) // making months zero based again
                  selectedCheckoutTimeValue.setFullYear(year)
                  console.log(selectedCheckoutTimeValue.getTime())

                  // now update the time on for the day of checkin
                  setFormData((prev) => {
                    return {
                      ...prev,
                      [record._id]: {
                        ...prev[record._id],
                        checkOutTime: selectedCheckoutTimeValue.getTime(),
                      },
                    }
                  })
                }
              }}
            />
          ) : (
            <Input
              style={{ ...styles.invisibleInput, color: '#000' }}
              disabled={!!record.checkOutTime}
              defaultValue={
                !record.checkOutTime
                  ? ''
                  : moment(record.checkOutTime).format('hh:mm A')
              }
            />
          )}
        </>
      ),
      key: 'checkOutTime',
      align: 'center',
    },

    {
      title: 'EARLY LEAVE',
      render: (text, record, index) => (
        <span>
          {record.specialReq.map((el) => {
            if (el.requestFor === 'Early Leave') {
              return 'Yes'
            } else {
              return ''
            }
          })}
        </span>
      ),
      align: 'center',
    },
    {
      title: 'REASON',
      render: (text, record, index) => (
        <div
          style={{
            border: '1px solid white',
            textAlign: 'center',
            maxHeight: 50,
            overflow: 'auto',
            fontSize: 10,
          }}
        >
          {record.specialReq.map((el) => {
            if (el.requestFor === 'Early Leave') {
              console.log('true')
              return el.detailsEarly
            } else {
              return ''
            }
          })}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'LATE ENTRY',
      render: (text, record, index) => (
        <div>
          {record.specialReq.map((el) => {
            if (el.requestFor === 'Late Entry') {
              return 'Yes'
            } else {
              return ''
            }
          })}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'REASON',
      render: (text, record, index) => (
        <div
          style={{
            border: '1px solid white',
            maxHeight: 50,
            overflow: 'auto',
            fontSize: 10,
          }}
        >
          {record.specialReq.map((el) => {
            if (el.requestFor === 'Late Entry') {
              console.log('true')
              return el.detailsLate
            } else {
              return ''
            }
          })}
        </div>
      ),
      align: 'center',
      className: 'reasonAttendence',
    },
  ]

  const getMyAttendanceSummary = async () => {
    // setLoading(true)

    let pathString = `/su/getAttendanceViaId?id=${leadInfo._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        // console.log(response.data)

        // setattendanceEntries(response.data.attendanceEntries)

        let pathString2 = `/su/getSpecialRequestViaId?id=${leadInfo._id}`
        const response2 = await axiosInstance.get(pathString2)
        // console.log(response2.data)

        // setattendanceEntries(response2.data.attendanceEntries)

        const newArr = response.data.attendanceEntries.map((att, index) => {
          var dateString = moment(att.createdAt).format('YYYY-MM-DD')
          const entries = response2.data.results.filter((el, idx) => {
            if (el.addingDate === dateString) {
              return att
            }
          })
          // console.log(entries)
          //   console.log({ ...att, specialReq: entries })
          return { ...att, specialReq: entries }
        })

        // console.log(newArr)
        setattendanceEntries(newArr)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyAttendanceSummary()
  }, [])

  useEffect(() => {
    console.log(dayjs(1701576668414, 'HH:mm:ss'))
    console.log(moment(1701576668414, 'HH:mm:ss'))
  }, [])

  return (
    <div>
      <br />
      <p
        style={{
          display: 'inline-block',
          padding: 5,
          border: '1px solid #275058',
          color: '#275058',
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          cursor: 'pointer',
        }}
        onClick={onSave}
      >
        Update
      </p>
      <Table
        dataSource={attendanceEntries}
        columns={columns.map((each) => ({
          ...each,
          className: 'maxWidthCell',
        }))}
        bordered
        pagination={false}
      />
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default Attendance
