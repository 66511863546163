import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  Input,
  Select,
  message,
  Modal,
  Form,
  Upload,
  Spin,
  Tooltip,
} from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  EditOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'

import axiosInstance from '../../../../utils/axiosInstance'
import getExtensionFromMimeType from '../../../../utils/getExtensionFromMimeType'

const { Option } = Select

const LabelText = styled.p`
  color: #275058;
  font-size: 1rem;
  margin: 0;
  margin-bottom: 10px;
  //   text-transform: uppercase;
  //   border: 1px solid red;
  text-align: left;
`

function OverviewCard(props) {
  const { leadInfo } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [messageApi, contextHolder] = message.useMessage()
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(leadInfo)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalTwoOpen, setIsModalTwoOpen] = useState(false)
  const [agreementFileList, setAgreementFileList] = useState([])
  const [validationText, setValidationText] = useState('')
  const [program, setProgram] = useState('')
  const [modalContent, setModalContent] = useState(null)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onProgramChange = (value) => {
    console.log(value)
    setProgram(value)
  }

  const agreementsUploadProps = {
    name: 'file',
    multiple: true,
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setAgreementFileList(info.fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }
  const validateLeadData = () => {
    setValidationText('')
    const missingFields = []

    if (agreementFileList.length === 0) missingFields.push('Image')

    return missingFields
  }

  const handleSubmit = async () => {
    console.log(formData)
    try {
      const missingFields = validateLeadData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      // showError(false)
      setLoading(true)

      const fData = new FormData()

      fData.append('_id', leadInfo._id)

      for (let i = 0; i < agreementFileList.length; i++) {
        fData.append('agreementFileList', agreementFileList[i].originFileObj)
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 1000)) // TODO: Undo
        const response = await axiosInstance.put(
          '/cm/replaceAgreement',
          fData,
          {
            headers: {}, // not sure if its working or not
          }
        )
        console.log(response.data)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
      window.location.reload()
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const onFinish = async (values) => {
    // console.log('Success:', values)
    console.log(values)
    const {
      patientName,
      preferredHospital,
      age,
      contact,
      email,
      notes,
      treatmentSegment,
      reports,
      passports,
      requestDate,
      preferredDoctor,
      preferredDestination,
    } = values
    try {
      setLoading(true)
      const formData = new FormData()

      formData.append('assignedFor', leadInfo._id)
      if (program) formData.append('program', program)
      if (patientName) formData.append('patientName', patientName)
      if (preferredDestination)
        formData.append('preferredDestination', preferredDestination)
      if (preferredHospital)
        formData.append('preferredHospital', preferredHospital)
      if (requestDate) formData.append('requestDate', requestDate)
      if (preferredDoctor) formData.append('preferredDoctor', preferredDoctor)
      if (age) formData.append('age', age)
      if (contact) formData.append('contact', contact)
      if (email) formData.append('email', email)
      if (notes) formData.append('notes', notes)
      if (treatmentSegment)
        formData.append('treatmentSegment', treatmentSegment)

      for (let i = 0; i < reports?.fileList.length; i++) {
        formData.append('reportsFileList', reports.fileList[i].originFileObj)
      }
      for (let i = 0; i < passports?.fileList.length; i++) {
        formData.append('passportFileList', passports.fileList[i].originFileObj)
      }
      const response = await axiosInstance.post('/su/assignALead', formData, {
        headers: {}, // not sure if its working or not
      })
      console.log(response.data)
      setLoading(false)
      window.location.reload()
    } catch (err) {
      console.error('Request Error:', err)
      setLoading(false)
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const rules = [
    {
      required: true,
    },
  ]
  const onSave = async () => {
    const payload = formData

    delete payload.type
    delete payload.__v
    console.log(payload)

    //
    setLoading(true)

    // TODO: CREATE A NEW UPDATE ROUTE TO UPDATE THE USER PROFILE
    try {
      const pathString = `/cm/updateAUser?userId=${payload._id}`
      delete payload._id
      const response = await axiosInstance.put(pathString, payload)
      console.log(response.data)
      // setAllLeads(response.data.allLeads)

      setLoading(false)
      messageApi.open({
        type: 'success',
        content: 'Updated',
        duration: 5,
      })
      window.location.reload()
    } catch (error) {
      console.error('Request Error:', error)
    }
  }

  const handleSelectTag = async (key, value) => {
    //
    console.log(key)
    console.log(value)
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${formData._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const handleBackButton = () => {
    // console.log(location?.state?.sentFrom?.pathname)
    if (location?.state?.sentFrom?.pathname) {
      navigate(location?.state?.sentFrom?.pathname)
      return
    }
    navigate(-1)
  }

  //   console.log(leadInfo)
  if (!formData) return <></>

  return (
    <div style={styles.container}>
      {loading && (
        <div className="overlay">
          <div style={{ textAlign: 'center', marginTop: '9rem' }}>
            <Spin size="large" />
          </div>
        </div>
      )}
      {contextHolder}
      <Modal
        title="Assign Lead Form"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={1000}
      >
        <Form
          name="basic"
          // layout="vertical"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="program" label="Program" rules={rules}>
            <Select
              placeholder="Select a option"
              onChange={onProgramChange}
              style={{ border: '1px solid #d9d9d9' }}
            >
              <Option value="IMT">IMT</Option>
              <Option value="TMP">TMP</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Patient Name" name="patientName" rules={rules}>
            <Input />
          </Form.Item>

          <Form.Item label="Age" name="age" rules={rules}>
            <Input />
          </Form.Item>

          <Form.Item label="Contact Number" name="contact" rules={rules}>
            <Input />
          </Form.Item>

          <Form.Item label="Email ID" name="email" rules={rules}>
            <Input />
          </Form.Item>

          {program == 'TMP' && (
            <>
              <Form.Item label="Request Date" name="requestDate" rules={rules}>
                <Input />
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Treatment Segment"
            name="treatmentSegment"
            rules={rules}
          >
            <Input />
          </Form.Item>

          {/* IMT */}
          {program == 'IMT' && (
            <Form.Item
              label="Preferred Destination"
              name="preferredDestination"
              rules={rules}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            label="Preferred Hospital"
            name="preferredHospital"
            rules={rules}
          >
            <Input />
          </Form.Item>

          {program == 'TMP' && (
            <>
              <Form.Item
                label="Preferred Doctor"
                name="preferredDoctor"
                rules={rules}
              >
                <Input />
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Reports"
            name="reports"
            // valuePropName="reportsFileList"
            // getValueFromEvent={normFile}
          >
            <Upload
              // name="file"
              // listType="picture"
              customRequest={(info) => {
                const { onProgress, onSuccess } = info
                onProgress({ percent: 100 })
                onSuccess('Ok')
              }}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Passports"
            name="passports"
            // valuePropName="reportsFileList"
            // getValueFromEvent={normFile}
          >
            <Upload
              // name="file"
              // listType="picture"
              customRequest={(info) => {
                const { onProgress, onSuccess } = info
                onProgress({ percent: 100 })
                onSuccess('Ok')
              }}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Notes" name="notes" rules={rules}>
            <Input.TextArea />
          </Form.Item>

          {loading && 'Loading...'}
          {!loading && (
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Assign
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
      {isModalTwoOpen && (
        <Modal
          title="Agreement"
          open={isModalTwoOpen}
          // onOk={handleOk}
          onCancel={() => {
            setIsModalTwoOpen(false)
          }}
          bodyStyle={{ height: 900 }}
          width={900}
          footer={null}
        >
          {/* <p>{JSON.stringify(modalContent)}</p> */}

          {modalContent?.type?.includes('pdf') ? (
            <iframe
              src={modalContent?.url}
              frameborder="0"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          ) : (
            <img
              src={modalContent?.url}
              alt=""
              srcset=""
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </Modal>
      )}
      <div style={{ display: 'flex' }}>
        <div style={{ width: '10%' }}>
          <Button style={styles.backBtnStyle} onClick={handleBackButton}>
            Back{' '}
          </Button>
        </div>
        <div
          style={{
            height: 40,
            clipPath: ' polygon(7% 100%, 93% 100%, 100% 0, 0 0)',
            background: '#275058',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '80%',
          }}
        >
          <p
            style={{
              color: 'white',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: 2,
            }}
          >
            PERSONAL INFORMATION
          </p>
        </div>
        <div
          style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}
        >
          {!isEditing ? (
            <EditOutlined
              style={{
                color: '#275058',
                fontSize: '1.2rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsEditing(true)
                messageApi.open({
                  type: 'info',
                  content: 'Form is now editable',
                })
              }}
            />
          ) : (
            <Button
              type="primary"
              style={styles.backBtnStyle}
              onClick={() => {
                setIsEditing(false)
                onSave()
              }}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      {/* {leadInfo?.name} */}
      <div
        style={{
          ...styles.gridContainer,
          // ...(!isEditing ? { pointerEvents: 'none' } : {}),
        }}
      >
        <div style={styles.gridItem}>
          <LabelText>Name</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.name}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, name: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>DEPARTMENT</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.department}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, department: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>DESIGNATION</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.designation}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, designation: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>JOINING DATE</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.joiningDate}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, joiningDate: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>CONFIRMATION DATE</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.confirmationDate}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, confirmationDate: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>AGE</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.age}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, age: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>CONTACT NUMBER</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.telephone}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, telephone: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>EMAIL</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.email}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, email: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>ADDRESS</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.address}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, address: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>AGREEMENT COPY</LabelText>

          <div
            style={{
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
              width: 240,
              // height: 40,
              textAlign: 'center',
              paddingBottom: 20,
            }}
          >
            <Button type="link">
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={async () => {
                  try {
                    if (
                      leadInfo?.agreements?.agreementFileList &&
                      leadInfo?.agreements?.agreementFileList.length > 0
                    ) {
                      console.log(
                        leadInfo?.agreements?.agreementFileList[0].filename
                      )
                      const response = await axiosInstance.post(
                        '/cm/getAFile',
                        {
                          fileName:
                            leadInfo?.agreements?.agreementFileList[0].filename,
                        }
                      )
                      console.log(response.data)
                      setIsModalTwoOpen(true)
                      // showModal()

                      setModalContent({
                        type: getExtensionFromMimeType(
                          leadInfo?.agreements?.agreementFileList[0].mimetype
                        ),
                        url: `${process.env.REACT_APP_BASE_URL}/${response.data.url}`,
                      })
                    }
                  } catch (err) {
                    console.log(err)
                    // handleReload()
                  }
                }}
              >
                View
              </span>
            </Button>
            <Upload {...agreementsUploadProps}>
              <div>
                <PlusCircleOutlined
                  style={{ color: '#275058', fontSize: '1rem' }}
                />
              </div>
            </Upload>
            <Tooltip
              color="red"
              title="Only Images and PDF are supported to upload as an agreement"
            >
              <button onClick={handleSubmit}>Save</button>
            </Tooltip>
          </div>
        </div>
        <div style={styles.gridItem}>
          <LabelText>CURRENT SALARY</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.currentSalary}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, currentSalary: e.target.value }
              })
            }}
          />
        </div>
        <div style={styles.gridItem}>
          <LabelText>NEXT INCREMENT DATE</LabelText>
          <Input
            style={{
              padding: '8px',
              borderRadius: '26px',
              display: 'inline-block',
              border: '1px solid #275058',
            }}
            value={formData.nextIncrementDate}
            onChange={(e) => {
              console.log(e.target.value)
              setFormData((prev) => {
                return { ...prev, nextIncrementDate: e.target.value }
              })
            }}
          />
        </div>
      </div>{' '}
      <div style={{ textAlign: 'center' }}>
        <Button type="primary" onClick={showModal}>
          Assign Lead
        </Button>
      </div>
    </div>
  )
}

const styles = {
  container: {
    border: '1px solid #275058',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  backBtnStyle: {
    marginTop: 10,
    borderRadius: 25,
    width: 50,
    fontSize: '.7rem',
    padding: 0,
    height: 20,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr', // Three equal columns
    // gap: '16px', // Adjust the gap between columns
  },
  gridItem: {
    // backgroundColor: '#e0e0e0',
    padding: '16px',
    // textAlign: 'center',
    // border: '1px solid #ccc',
  },
}

export default OverviewCard
