import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { UploadOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  Layout,
  Button,
  Radio,
  Upload,
  Input,
  Modal,
  Alert,
  List,
  Popconfirm,
  Select,
  Spin,
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Navbar from '../../components/Navbar'
import axiosInstance from '../../utils/axiosInstance'

const { Content } = Layout
const { Option } = Select

const LabelText = styled.p`
  color: #275058;
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
`

const inputStyle = {
  padding: '12px',
  borderRadius: '26px',
  display: 'inline-block',
  border: '1px solid #275058',
}

function AddService() {
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal()

  const [size, setSize] = useState('ADD')
  const [allServices, setAllServices] = useState([])
  const [serviceFileList, setServiceFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [validationText, setValidationText] = useState('')

  const treatmentPlanUploadProps = {
    name: 'file',
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setServiceFileList(info.fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }

  const countDown = () => {
    let secondsToGo = 2
    const instance = modal.success({
      title: 'Service added successfully!',
      content: ``,
      onOk: () => navigate('/dashboard'),
    })
    const timer = setInterval(() => {
      secondsToGo -= 1
      // instance.update({
      //   content: `This modal will be destroyed after ${secondsToGo} second.`,
      // })
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
      instance.destroy()
      navigate('/dashboard')
    }, secondsToGo * 1000)
  }

  const validateLeadData = () => {
    setValidationText('')
    const missingFields = []

    // Check if mandatory fields are empty, null, or undefined
    if (!formData.serviceName) missingFields.push('Name')
    if (!formData.servicePackage) missingFields.push('Package')
    if (!formData.servicePoints) missingFields.push('Points')
    if (serviceFileList.length === 0) missingFields.push('Image')

    return missingFields
  }

  const handleSubmit = async () => {
    console.log(formData)
    try {
      const missingFields = validateLeadData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      // showError(false)
      setLoading(true)

      const fData = new FormData()

      // Add your fields to the FormData object
      fData.append('serviceName', formData.serviceName)
      fData.append('servicePackage', formData.servicePackage)
      fData.append('servicePoints', formData.servicePoints)

      for (let i = 0; i < serviceFileList.length; i++) {
        fData.append('serviceFileList', serviceFileList[i].originFileObj)
      }

      try {
        const response = await axiosInstance.post('/cm/createService', fData, {
          headers: {}, // not sure if its working or not
        })
        console.log(response.data)

        countDown() // showing success modal
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllServices = async () => {
    //
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.get('/cm/getAllServices')
        console.log(response.data)
        setAllServices(response.data.allServices)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllServices()
  }, [])

  const addService = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>ADD SERVICES</p>

        <div style={{ display: 'flex', gap: 40 }}>
          <Upload {...treatmentPlanUploadProps}>
            <div
              style={{
                marginTop: 20,
                width: '300px',
                height: '150px',
                background:
                  'radial-gradient(circle, rgba(37, 81, 89, 0.8) 0%, rgba(36, 78, 86, 1) 100%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
            >
              <PlusCircleOutlined
                style={{ color: 'white', fontSize: '2rem' }}
              />
              <p style={{ margin: 0 }}>PICTURE</p>
            </div>
          </Upload>

          <div style={{ border: '1px solid white', minWidth: 800 }}>
            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                marginBottom: 10,
                gap: 50,
              }}
            >
              <div style={{ width: '40%' }}>
                <LabelText>*SERVICE NAME</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Name"
                  //   value={name}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, serviceName: value }
                    })
                  }}
                />
              </div>

              <div style={{ width: '40%' }}>
                <LabelText>*SERVICE PACKAGE</LabelText>
                <Select
                  style={styles.selectStyle}
                  value={formData.servicePackage}
                  onChange={(value) => {
                    // console.log(value)
                    setFormData((prev) => {
                      return { ...prev, servicePackage: value }
                    })
                  }}
                >
                  <Option value={'1'}>1</Option>
                  <Option value={'2'}>2</Option>
                  <Option value={'3'}>3</Option>
                  <Option value={'4'}>4</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}></div>
        <LabelText>*SERVICE POINTS</LabelText>
        <ReactQuill
          theme="snow"
          // value={value}
          onChange={(value) => {
            setFormData((prev) => {
              return { ...prev, servicePoints: value }
            })
          }}
          style={{ height: '30vh' }}
        />

        {validationText && (
          <div style={{ marginTop: 60 }}>
            <Alert message={validationText} type="error" />
          </div>
        )}

        {loading && (
          <div className="overlay">
            <div style={{ textAlign: 'center', marginTop: '9rem' }}>
              <Spin size="large" />
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 20,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => handleSubmit()}
          >
            SAVE{' '}
          </Button>
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => navigate('/dashboard')}
          >
            Cancel{' '}
          </Button>
        </div>
      </>
    )
  }
  const deleteService = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>
          DELETE SERVICE
        </p>

        <List
          style={{ maxWidth: '50%' }}
          size="large"
          // header={<div>All</div>}
          // footer={<div>Footer</div>}
          bordered
          dataSource={allServices}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a
                  href={`${process.env.REACT_APP_LIVE_SITE_URL}/service/${item.pID}`}
                  target="_blank"
                >
                  View Live
                </a>,
                <Button
                  onClick={() => {
                    navigate(`/editService?id=${item._id}`)
                  }}
                >
                  Edit
                </Button>,
                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete this?"
                  onConfirm={async () => {
                    try {
                      const response = await axiosInstance.delete(
                        `/cm/deleteServiceById?id=${item._id}`
                      )
                      console.log(response.data)
                      // handleReload()
                      getAllServices()
                    } catch (err) {
                      console.log(err)
                      // handleReload()
                    }
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger>Delete</Button>{' '}
                </Popconfirm>,
              ]}
            >
              {item.serviceName} - Package: {item.servicePackage}
            </List.Item>
          )}
        />
      </>
    )
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={styles.backBtn}
              onClick={() => navigate('/dashboard')}
            >
              Back{' '}
            </Button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={styles.actionText}>OUR SERVICES</p>
            <a
              href={`${process.env.REACT_APP_LIVE_SITE_URL}/services`}
              target="_blank"
              style={{ marginLeft: 20 }}
            >
              View Section Live
            </a>
          </div>

          <Radio.Group value={size} onChange={(e) => setSize(e.target.value)}>
            <Radio.Button
              value="ADD"
              style={{
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              ADD
            </Radio.Button>
            <Radio.Button
              value="DELETE"
              style={{
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              ACTIONS
            </Radio.Button>
          </Radio.Group>

          {size === 'ADD' ? addService() : deleteService()}
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  backBtn: {
    marginTop: 20,
    borderRadius: 25,
    width: 70,
    fontSize: '.8rem',
    padding: 0,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  actionText: {
    color: '#275058',
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
    fontSize: '2rem',
  },
  selectStyle: {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
    padding: 5,
    overflow: 'hidden',
    width: '90%',
  },
}

export default AddService
