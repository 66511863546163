import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../../../components/Navbar'
import SalaryOverviewCard from './SalaryOverviewCard'

import axiosInstance from '../../../../utils/axiosInstance'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

function SalaryReceived() {
  const [leadInfo, setLeadInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [salaryEntries, setsalaryEntries] = useState([])
  const auth = useSelector((state) => state.auth)

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'RECEIVED DATE',
      dataIndex: 'receivedDate',
      key: 'receivedDate',
      align: 'center',
    },
    {
      title: 'MEDIUM',
      dataIndex: 'medium',
      key: 'medium',
      align: 'center',
    },
    {
      title: 'ACCOUNT NUMBER',
      dataIndex: 'accNo',
      key: 'accNo',
      align: 'center',
    },
    {
      title: 'MONTH',
      dataIndex: 'month',
      key: 'month',
      align: 'center',
    },
    {
      title: 'TIME',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
  ]

  const getMyLeaveEntries = async () => {
    // setLoading(true)

    let pathString = `/cm/getSalaryEntryOfUser`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        setsalaryEntries(response.data.salaryEntries)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyLeaveEntries()
  }, [])

  return (
    <div>
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            // margin: '0 16px',
            padding: '30px 16px',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{}}>
              <SalaryOverviewCard leadInfo={leadInfo} />
            </div>
          </div>
          <h2 style={{ color: '#275058', textDecoration: 'underline' }}>
            SALARY SUMMARY
          </h2>
          <Table
            dataSource={salaryEntries}
            columns={columns.map((each) => ({
              ...each,
              className: 'maxWidthCell',
            }))}
            bordered
            pagination={false}
          />
        </Content>
      </Layout>
    </div>
  )
}

export default SalaryReceived
