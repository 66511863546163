import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { Modal, Popconfirm } from 'antd'

import { CloseCircleOutlined } from '@ant-design/icons'

import getExtensionFromMimeType from '../../../utils/getExtensionFromMimeType'
import axiosInstance from '../../../utils/axiosInstance'

function ReportsPassports(props) {
  const { leadInfo } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }

  const handleReload = () => {
    window.location.reload()
  }

  const generateTable = () => {
    const tableStyles = {
      container: {
        marginTop: 20,
        width: '70%',
      },
      tableHeader: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '97%',
        backgroundColor: '#275058',
      },
      tableHeaderText: {
        width: '19%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '.9rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid white',
      },
      contentRow: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '97%',
      },
      contentRowText: {
        width: '19%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        fontSize: '1rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        overflow: 'hidden',
      },
    }
    if (!leadInfo) return <></>
    const combined = [...leadInfo.reportsFileList, ...leadInfo.passportFileList]
    // console.log(combined)
    return (
      <div style={tableStyles.container}>
        <div style={tableStyles.tableHeader}>
          <div style={{ ...tableStyles.tableHeaderText, width: '3%' }}>SL.</div>
          <div style={tableStyles.tableHeaderText}>CATEGORY</div>
          <div style={tableStyles.tableHeaderText}>DOCUMENT</div>
          <div style={tableStyles.tableHeaderText}>DATE</div>
          <div style={tableStyles.tableHeaderText}>DELETE / REMOVE</div>

          <div
            style={{
              ...tableStyles.tableHeaderText,
              borderRight: '1px solid #275058',
            }}
          >
            Actions{' '}
          </div>
        </div>
        {combined.map((el, idx) => {
          return (
            <div style={tableStyles.contentRow}>
              <div style={{ ...tableStyles.contentRowText, width: '3%' }}>
                <span style={{ fontWeight: 'bold' }}>{idx + 1}.</span>
              </div>
              <div style={tableStyles.contentRowText}>
                {el.fieldname === 'reportsFileList' ? 'Reports' : 'Passports'}
              </div>
              <div style={tableStyles.contentRowText}>{el.originalname}</div>
              <div style={tableStyles.contentRowText}>
                {moment(el.createdAt).format('DD.MM.YYYY')}
              </div>
              <div style={tableStyles.contentRowText}>
                <Popconfirm
                  title="Delete the file"
                  description="Are you sure to delete this file?"
                  onConfirm={async () => {
                    try {
                      const response = await axiosInstance.post(
                        '/cm/deleteAFile',
                        {
                          _id: leadInfo?._id,
                          fieldname: el.fieldname,
                          filename: el.filename,
                        }
                      )
                      console.log(response.data)
                      handleReload()
                    } catch (err) {
                      console.log(err)
                      handleReload()
                    }
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <CloseCircleOutlined style={{ cursor: 'pointer' }} />
                </Popconfirm>
              </div>
              <div
                style={{
                  ...tableStyles.contentRowText,
                  // width: '4%',
                  borderRight: '1px solid white',
                  cursor: 'pointer',
                }}
              >
                {/* {JSON.stringify(el)} */}
                {}
                <p
                  style={{ margin: 0, color: '#275058', fontSize: '.7rem' }}
                  onClick={async () => {
                    try {
                      const response = await axiosInstance.post(
                        '/cm/getAFile',
                        {
                          fileName: el.filename,
                        }
                      )
                      console.log(response.data)

                      showModal()

                      setModalContent({
                        type: getExtensionFromMimeType(el.mimetype),
                        url: `${process.env.REACT_APP_BASE_URL}/${response.data.url}`,
                      })
                    } catch (err) {
                      console.log(err)
                      handleReload()
                    }
                  }}
                >
                  Open
                </p>
                <p
                  style={{ margin: 0, color: '#275058', fontSize: '.7rem' }}
                  onClick={async () => {
                    try {
                      const response = await axiosInstance.post(
                        '/cm/getAFile',
                        {
                          fileName: el.filename,
                        }
                      )
                      console.log(response.data)

                      handleDownload(
                        `${process.env.REACT_APP_BASE_URL}/${response.data.url}`,
                        response.data.url
                      )
                    } catch (err) {
                      console.log(err)
                      handleReload()
                    }
                  }}
                >
                  Download
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const handleDownload = async (url, fileName) => {
    try {
      const response = await axiosInstance.get(url, {
        responseType: 'blob', // Set the response type to blob
      })

      // Create a blob object from the response data
      const blob = new Blob([response.data])

      // Create a URL for the blob
      const blobUrl = window.URL.createObjectURL(blob)

      // Create a link element and trigger the download
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = fileName
      link.click()

      // Clean up the URL object to release resources
      window.URL.revokeObjectURL(blobUrl)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  return (
    <div>
      <p
        style={{
          color: '#275058',
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          textDecoration: 'underline',
        }}
      >
        PATIENT REPORTS & PASSPORT
      </p>
      {isModalOpen && (
        <Modal
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ height: 900 }}
          width={900}
        >
          {/* <p>{JSON.stringify(modalContent)}</p> */}

          {modalContent?.type?.includes('pdf') ? (
            <iframe
              src={modalContent?.url}
              frameborder="0"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          ) : (
            <img
              src={modalContent?.url}
              alt=""
              srcset=""
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </Modal>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {generateTable()}
      </div>
    </div>
  )
}

export default ReportsPassports
