import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function MeetingAction({ cmUser }) {
  const [loading, setLoading] = useState(false)
  const [attendanceEntries, setattendanceEntries] = useState([])
  const [meetingConducted, setMeetingConducted] = useState(0)
  const [meetingPending, setMeetingPending] = useState(0)

  const getMyAttendanceSummary = async () => {
    // setLoading(true)

    try {
      let pathString2 = `/su/getMeetingEntryOfAUser?id=${cmUser._id}`
      const response2 = await axiosInstance.get(pathString2)
      // console.log(response2.data)

      setattendanceEntries(response2.data.results)

      const entries = response2.data.results.filter((el, idx) => {
        if (el.meetingConductedStatus === 'Yes') {
          setMeetingConducted((p) => p + 1)
        }
        if (el.meetingConductedStatus === 'No') {
          setMeetingPending((p) => p + 1)
        }
      })

      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
    }
  }

  useEffect(() => {
    getMyAttendanceSummary()
  }, [])

  return (
    <div style={{ color: '#275058', textAlign: 'center' }}>
      <h2 style={{ textDecoration: 'underline' }}>MEETING SUMMARY</h2>
      <div
        style={{
          color: '#275058',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          minHeight: 300,
        }}
      >
        <div>
          <p style={{ textDecoration: 'underline' }}>REQUEST</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {attendanceEntries.length}
          </div>
        </div>
        <div>
          <p style={{ textDecoration: 'underline' }}>CONDUCTED</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {meetingConducted}
          </div>
        </div>
        <div>
          <p style={{ textDecoration: 'underline' }}>PENDING</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {meetingPending}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeetingAction
