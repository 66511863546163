import React from 'react'

import LeaveAction from './LeaveAction'
import AttendanceAction from './AttendanceAction'
import SpecialReqAction from './SpecialReqAction'
import MeetingAction from './MeetingAction'
import SalaryAction from './SalaryAction'
import OfficeReqAction from './OfficeReqAction'
import IncentiveAction from './IncentiveAction'

function ActionCard(props) {
  const { selectedTab, cmUser } = props

  const generateJsx = () => {
    // console.log(selectedTab)
    if (selectedTab === '1') {
      return <AttendanceAction cmUser={cmUser} />
    } else if (selectedTab === '2') {
      return <LeaveAction cmUser={cmUser} />
    } else if (selectedTab === '3') {
      return <MeetingAction cmUser={cmUser} />
    } else if (selectedTab === '4') {
      return <SalaryAction cmUser={cmUser} />
    } else if (selectedTab === '5') {
      return <IncentiveAction cmUser={cmUser} />
    } else if (selectedTab === '6') {
      return <SpecialReqAction cmUser={cmUser} />
    } else if (selectedTab === '7') {
      return <OfficeReqAction cmUser={cmUser} />
    }
  }

  return <div>{generateJsx()}</div>
}

export default ActionCard
