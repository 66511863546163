import React, { useEffect } from 'react'
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Layout,
  Button,
  Radio,
  Upload,
  Input,
  Modal,
  Alert,
  List,
  Popconfirm,
  Select,
  Spin,
} from 'antd'

import axiosInstance from '../../utils/axiosInstance'
import TopRowCommon from '../../components/TopRowCommon'
import Navbar from '../../components/Navbar'
import { fetchAllLeads } from '../../store/auth/leadSlice'

const { Content } = Layout

function PassportReceived() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const allLeads = useSelector((state) => state.leads.allLeads)

  useEffect(() => {
    if (allLeads.length === 0) dispatch(fetchAllLeads())
  }, [])

  const handleSelectTag = async (key, value, el) => {}

  const generateTable = () => {
    const tableStyles = {
      container: {
        marginTop: 20,
      },
      tableHeader: {
        // border: '1px solid red',
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '95.2%',
      },
      tableHeaderText: {
        width: '15%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        fontWeight: 'bold',
        fontSize: '.9rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        // border: '1px solid blue',
      },
      contentRow: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
      },
      contentRowText: {
        width: '15%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        // fontWeight: 'bold',
        fontSize: '1rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        overflow: 'hidden',
      },
      selectStyle: {
        borderRadius: '25px',
        backgroundColor: '#EBEBEB',
        color: '#275058',
        padding: 8,
        border: 'none',
        // appearance: 'none',
        paddingRight: '15px',
        fontWeight: 'bold',
        maxWidth: 'auto',
      },
    }

    return (
      <div style={tableStyles.container}>
        <div style={tableStyles.tableHeader}>
          <div style={{ ...tableStyles.tableHeaderText, width: '3%' }}>SL.</div>
          <div style={tableStyles.tableHeaderText}>PATIENT NAME</div>
          <div style={tableStyles.tableHeaderText}>CONTACT</div>
          <div style={tableStyles.tableHeaderText}>EMAIL ID</div>
          <div style={tableStyles.tableHeaderText}>PASSPORT RECEIVED</div>
          <div style={tableStyles.tableHeaderText}>PASSPORT RECEIVED DATE</div>
          <div style={tableStyles.tableHeaderText}>DOCUMENT</div>
        </div>
        {allLeads.map((el, idx) => {
          return (
            <div style={tableStyles.contentRow}>
              <div style={{ ...tableStyles.contentRowText, width: '2.8%' }}>
                {idx + 1}.
              </div>
              <div style={tableStyles.contentRowText}>{el.name}</div>
              <div style={tableStyles.contentRowText}>{el.contact}</div>
              <div style={tableStyles.contentRowText}>{el.email}</div>
              <div style={tableStyles.contentRowText}>
                {/* {JSON.stringify(!!el.passportFileList)} */}
                <select
                  disabled
                  style={tableStyles.selectStyle}
                  value={el.passportReceived}
                  onChange={(event) =>
                    handleSelectTag('passportReceived', event.target.value, el)
                  }
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>

              <div style={tableStyles.contentRowText}>
                {moment(el.createdAt).format('DD.MM.YYYY')}
              </div>
              <div style={tableStyles.contentRowText}>
                <a
                  href={`/pi/${el.pID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: 'pointer', color: '#275058' }}
                >
                  View
                </a>
              </div>
              <div
                style={{
                  ...tableStyles.contentRowText,
                  width: '4%',
                  borderRight: '1px solid white',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(`/pi/${el.pID}?tab=1`, {
                    state: { sentFrom: location },
                  })
                }
              >
                <img
                  src="/contents/icons/PROFILE.png"
                  style={{ height: '1.4rem' }}
                  alt=""
                />
                <p style={{ margin: 0, color: '#275058', fontSize: '.7rem' }}>
                  PROFILE
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <Layout style={styles.layoutStyle}>
        <Content style={styles.contentStyle}>
          <TopRowCommon title="PASSPORT RECEIVED" />
          <div style={{ marginLeft: '0%' }}>{generateTable()}</div>
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  layoutStyle: {
    minHeight: '100vh',
  },
  contentStyle: {
    padding: '0 16px',
    backgroundColor: '#fff',
  },
}

export default PassportReceived
