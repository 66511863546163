import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function Meetings({ leadInfo }) {
  const [loading, setLoading] = useState(false)
  const [leaveEntries, seMeetingEntries] = useState([])

  const handleSelectTag = async (value, key, _id) => {
    //

    console.log(value)
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateMeetingEntry?ID=${_id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'REQUEST DATE',
      render: (text, record, index) => (
        // <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>
        <span>{record.meetingDate}</span>
      ),
      key: 'DATE',
      align: 'center',
    },
    {
      title: 'ORGANIZATION',
      dataIndex: 'organization',
      key: 'organization',
      align: 'center',
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      key: 'location',
      align: 'center',
    },
    {
      title: 'TIME',
      dataIndex: 'meetingTime',
      key: 'meetingTime',
      align: 'center',
    },
    {
      title: 'CONCERNED PERSON & DEPARTMENT',
      dataIndex: 'concernedPersonDeparment',
      key: 'concernedPersonDeparment',
      align: 'center',
    },
    {
      title: 'CONTACT NUMBER',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      align: 'center',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: 'PURPOSE',
      dataIndex: 'purposeOfMeeting',
      key: 'purposeOfMeeting',
      align: 'center',
    },
    {
      title: 'STATUS',
      // dataIndex: 'meetingConductedStatus',
      render: (text, record, index) => {
        // return <span>{record.status}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.meetingConductedStatus}
            onChange={(event) => {
              handleSelectTag(
                event.target.value,
                'meetingConductedStatus',
                record._id
              )
            }}
          >
            <option value="Yes">CONDUCTED</option>
            <option value="No">PENDING</option>
          </select>
        )
      },
      key: 'meetingConductedStatus',
      align: 'center',
    },
    {
      title: 'ACTION',
      // dataIndex: 'suAvailable',
      render: (text, record, index) => {
        // return <span>{record.status}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.suAvailable}
            onChange={(event) => {
              handleSelectTag(event.target.value, 'suAvailable', record._id)
            }}
          >
            <option value="Yes">AVAILABLE</option>
            <option value="No">NOT AVAILABLE</option>
          </select>
        )
      },
      key: 'suAvailable',
      align: 'center',
    },
  ]

  const getMeetingEntryOfAUser = async () => {
    // setLoading(true)

    let pathString = `/su/getMeetingEntryOfAUser?id=${leadInfo._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        seMeetingEntries(response.data.results)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMeetingEntryOfAUser()
  }, [])

  return (
    <div>
      <br />{' '}
      <Table
        dataSource={leaveEntries}
        columns={columns.map((each) => ({
          ...each,
          className: 'maxWidthCell',
        }))}
        bordered
        pagination={false}
      />
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default Meetings
