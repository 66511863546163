import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function SpecialReqAction({ cmUser }) {
  const [loading, setLoading] = useState(false)
  const [attendanceEntries, setattendanceEntries] = useState([])
  const [lateEntries, setLateEntries] = useState(0)
  const [earlyLeave, setEarlyLeave] = useState(0)
  const [others, setOthers] = useState(0)

  const getMyAttendanceSummary = async () => {
    // setLoading(true)

    console.log('kamal')

    let pathString = `/su/getAttendanceViaId?id=${cmUser._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        // console.log(response.data)

        // setattendanceEntries(response.data.attendanceEntries)

        let pathString2 = `/su/getSpecialRequestViaId?id=${cmUser._id}`
        const response2 = await axiosInstance.get(pathString2)
        // console.log(response2.data)

        // setattendanceEntries(response2.data.attendanceEntries)

        const entries = response2.data.results.filter((el, idx) => {
          console.log(el.requestFor === 'Early Leave')
          if (el.requestFor === 'Early Leave') {
            setEarlyLeave((p) => p + 1)
          }
          console.log(el.requestFor === 'Late Entry')
          if (el.requestFor === 'Late Entry') {
            setLateEntries((p) => p + 1)
          }
          if (el.requestFor === 'Others') {
            setOthers((p) => p + 1)
          }
        })

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyAttendanceSummary()
  }, [])

  return (
    <div style={{ color: '#275058', textAlign: 'center' }}>
      <h2 style={{ textDecoration: 'underline' }}>SPECIAL REQUEST SUMMARY</h2>
      <div
        style={{
          color: '#275058',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          minHeight: 200,
        }}
      >
        <div>
          <p style={{ fontWeight: 'bold' }}> EARLY LEAVE</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {earlyLeave}
          </div>
        </div>
        <div>
          <p style={{ fontWeight: 'bold' }}>LATE ENTRY</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {lateEntries}
          </div>
        </div>
        <div>
          <p style={{ fontWeight: 'bold' }}>OTHERS</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {others}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecialReqAction
