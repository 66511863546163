import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { UploadOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  Layout,
  Button,
  Radio,
  Upload,
  Input,
  Modal,
  Alert,
  List,
  Popconfirm,
  Select,
  Switch,
  Spin,
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Navbar from '../../components/Navbar'
import axiosInstance from '../../utils/axiosInstance'
import { data as departments } from '../../constant/departments'

const { Content } = Layout
const { Option } = Select

const LabelText = styled.p`
  color: #275058;
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
`

const inputStyle = {
  padding: '12px',
  borderRadius: '26px',
  display: 'inline-block',
  border: '1px solid #275058',
}

function AddDoctor() {
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal()

  const [size, setSize] = useState('ADD')
  const [allDoctors, setAllDoctors] = useState([])
  const [doctorFileList, setDoctorFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [validationText, setValidationText] = useState('')
  const [allHospitals, setAllHospitals] = useState([])
  const [renderHospitals, setRenderHospitals] = useState([])
  const [treatments, setTreatments] = useState([])

  const treatmentPlanUploadProps = {
    name: 'file',
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setDoctorFileList(info.fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }

  const countDown = () => {
    let secondsToGo = 2
    const instance = modal.success({
      title: 'Doctor added successfully!',
      content: ``,
      onOk: () => navigate('/dashboard'),
    })
    const timer = setInterval(() => {
      secondsToGo -= 1
      // instance.update({
      //   content: `This modal will be destroyed after ${secondsToGo} second.`,
      // })
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
      instance.destroy()
      navigate('/dashboard')
    }, secondsToGo * 1000)
  }

  const validateLeadData = () => {
    setValidationText('')
    const missingFields = []

    // Check if mandatory fields are empty, null, or undefined
    if (!formData.name) missingFields.push('Name')
    if (!formData.hospital) missingFields.push('Hospital')
    if (!formData.yearsOfExperience) missingFields.push('Years of experience')
    if (!formData.department) missingFields.push('Department')
    if (!formData.designation) missingFields.push('Designation')
    if (!formData.qualification) missingFields.push('Qualification')
    if (!formData.education) missingFields.push('Education')
    if (!formData.noOfSurgeries) missingFields.push('Number of surgeries')
    if (!formData.about) missingFields.push('About')
    if (doctorFileList.length === 0) missingFields.push('Image')

    return missingFields
  }

  const handleSubmit = async () => {
    console.log(formData)
    try {
      const missingFields = validateLeadData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      // showError(false)
      setLoading(true)

      const fData = new FormData()

      // Add your fields to the FormData object
      fData.append('name', formData.name)
      fData.append('hospital', formData.hospital)
      fData.append('yearsOfExperience', formData.yearsOfExperience)
      fData.append('department', formData.department)
      fData.append('designation', formData.designation)
      fData.append('qualification', formData.qualification)
      fData.append('education', formData.education)
      fData.append('noOfSurgeries', formData.noOfSurgeries)
      fData.append('teleMedicine', formData.teleMedicine ? true : false) // even I am sending "true"/"false" string schema is converting it to boolean
      fData.append('about', formData.about)

      for (let i = 0; i < doctorFileList.length; i++) {
        fData.append('doctorFileList', doctorFileList[i].originFileObj)
      }

      try {
        const response = await axiosInstance.post('/cm/createDoctor', fData, {
          headers: {}, // not sure if its working or not
        })
        console.log(response.data)

        countDown() // showing success modal
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllDoctors = async () => {
    //
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.get('/cm/getAllDoctors')
        console.log(response.data)
        setAllDoctors(response.data.allDoctors)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllHospitals = async () => {
    //
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.get('/cm/getAllHospitals')
        console.log(response.data)
        setAllHospitals(response.data.allHospitals)
        setRenderHospitals(response.data.allHospitals)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllTreatments = async () => {
    try {
      const response = await axiosInstance.get(`/public/getAllTreatments`)

      // console.log(response.data.hospitals)
      setTreatments(response.data.allTreatments)
    } catch (error) {
      console.error('Request Error:', error)
    }
  }

  useEffect(() => {
    getAllDoctors()
    getAllHospitals()
    getAllTreatments()
  }, [])

  const addDoctor = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>ADD DOCTOR</p>

        <div style={{ display: 'flex', gap: 40 }}>
          <Upload {...treatmentPlanUploadProps}>
            <div
              style={{
                marginTop: 20,
                width: '300px',
                height: '150px',
                background:
                  'radial-gradient(circle, rgba(37, 81, 89, 0.8) 0%, rgba(36, 78, 86, 1) 100%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                flexDirection: 'column',
                cursor: 'pointer',
              }}
            >
              <PlusCircleOutlined
                style={{ color: 'white', fontSize: '2rem' }}
              />
              <p style={{ margin: 0 }}>PICTURE</p>
            </div>
          </Upload>

          <div style={{ border: '1px solid white', width: 1200 }}>
            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                marginBottom: 10,
                gap: 50,
                width: '100%',
              }}
            >
              <div
                style={
                  {
                    //  minWidth: '40%'
                  }
                }
              >
                <LabelText>*NAME</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Name"
                  //   value={name}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, name: value }
                    })
                  }}
                />
              </div>

              <div style={{ width: '20%' }}>
                <LabelText>*HOSPITAL</LabelText>
                {/* <Input
                  style={{ ...inputStyle }}
                  // placeholder="Age"
                  //   value={age}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, hospital: value }
                    })
                  }}
                /> */}
                <Select
                  showSearch
                  filterOption={false} // need for onSearch "https://stackoverflow.com/a/62533065/11609006"
                  style={{ ...styles.selectStyle, width: 260 }}
                  // value={formData.location}
                  onChange={(value) => {
                    // console.log(value)
                    setFormData((prev) => {
                      return { ...prev, hospital: value }
                    })
                  }}
                  onSearch={(what) => {
                    const searchResult = allHospitals.filter((el) => {
                      if (
                        el.hospitalName
                          .toLowerCase()
                          .includes(what.toLowerCase())
                      ) {
                        return el
                      }
                    })
                    setRenderHospitals(searchResult)
                  }}
                >
                  {renderHospitals?.map((el, idx) => (
                    <Option value={el._id} key={idx}>
                      {el.hospitalName} - {el.location}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{}}>
                <LabelText>*YEARS OF EXPERIENCE</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Age"
                  //   value={age}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, yearsOfExperience: value }
                    })
                  }}
                />
              </div>
              <div style={{}}>
                <LabelText>*DEPARTMENT</LabelText>
                {/* <Input
                  style={{ ...inputStyle }}
                  // placeholder="Age"
                  //   value={age}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, department: value }
                    })
                  }}
                /> */}
                <Select
                  style={{ ...styles.selectStyle, width: 300 }}
                  // value={formData.location}
                  onChange={(value) => {
                    // console.log(value)
                    setFormData((prev) => {
                      return { ...prev, department: value }
                    })
                  }}
                >
                  {treatments.map((el, idx) => (
                    <Option value={el.segmentName} key={idx}>
                      {el.segmentName}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                gap: 50,
              }}
            >
              <div style={{}}>
                <LabelText>*DESIGNATION</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Name"
                  //   value={name}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, designation: value }
                    })
                  }}
                />
              </div>

              <div style={{}}>
                <LabelText>*QUALIFICATION</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Age"
                  //   value={age}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, qualification: value }
                    })
                  }}
                />
              </div>
              <div style={{}}>
                <LabelText>*EDUCATION</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Age"
                  //   value={age}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, education: value }
                    })
                  }}
                />
              </div>
              <div style={{}}>
                <LabelText>*NO. OF SURGERIES</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Age"
                  //   value={age}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, noOfSurgeries: value }
                    })
                  }}
                />
              </div>
            </div>

            <div style={{ marginTop: 10 }}>
              <div style={{}}>
                <LabelText>*Available for Tele-Medicine</LabelText>
                <Switch
                  unCheckedChildren={'Not available'}
                  checkedChildren="Available"
                  onChange={(value) => {
                    console.log(value)
                    setFormData((prev) => {
                      return { ...prev, teleMedicine: value }
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}></div>
        <LabelText>*ABOUT</LabelText>
        <ReactQuill
          theme="snow"
          // value={value}
          onChange={(value) => {
            setFormData((prev) => {
              return { ...prev, about: value }
            })
          }}
          style={{ height: '30vh' }}
        />

        {validationText && (
          <div style={{ marginTop: 60 }}>
            <Alert message={validationText} type="error" />
          </div>
        )}

        {loading && (
          <div className="overlay">
            <div style={{ textAlign: 'center', marginTop: '9rem' }}>
              <Spin size="large" />
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 20,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => handleSubmit()}
          >
            SAVE{' '}
          </Button>
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => navigate('/dashboard')}
          >
            Cancel{' '}
          </Button>
        </div>
      </>
    )
  }
  const deleteDoctor = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>
          DELETE DOCTOR
        </p>

        <List
          style={{ maxWidth: '50%' }}
          size="large"
          // header={<div>All</div>}
          // footer={<div>Footer</div>}
          bordered
          dataSource={allDoctors}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a
                  href={`${process.env.REACT_APP_LIVE_SITE_URL}/doctor/${item.pID}`}
                  target="_blank"
                >
                  View Live
                </a>,
                <Button
                  onClick={() => {
                    navigate(`/editDoctor?id=${item._id}`)
                  }}
                >
                  Edit
                </Button>,
                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete this?"
                  onConfirm={async () => {
                    try {
                      const response = await axiosInstance.delete(
                        `/cm/deleteDoctorById?id=${item._id}`
                      )
                      console.log(response.data)
                      // handleReload()
                      getAllDoctors()
                    } catch (err) {
                      console.log(err)
                      // handleReload()
                    }
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger>Delete</Button>{' '}
                </Popconfirm>,
              ]}
            >
              {item.name} - {item.department}
            </List.Item>
          )}
        />
      </>
    )
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={styles.backBtn}
              onClick={() => navigate('/dashboard')}
            >
              Back{' '}
            </Button>
          </div>
          <p style={styles.actionText}>DOCTOR</p>

          <Radio.Group value={size} onChange={(e) => setSize(e.target.value)}>
            <Radio.Button
              value="ADD"
              style={{
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              ADD
            </Radio.Button>
            <Radio.Button
              value="DELETE"
              style={{
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              ACTIONS
            </Radio.Button>
          </Radio.Group>

          {size === 'ADD' ? addDoctor() : deleteDoctor()}
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  backBtn: {
    marginTop: 20,
    borderRadius: 25,
    width: 70,
    fontSize: '.8rem',
    padding: 0,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  actionText: {
    color: '#275058',
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
    fontSize: '2rem',
  },
  selectStyle: {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
    padding: 5,
    overflow: 'hidden',
    width: '90%',
  },
}

export default AddDoctor
