import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Layout,
  Button,
  Input,
  Select,
  Modal,
  Spin,
  Pagination,
  Breadcrumb,
  Radio,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
import Navbar from '../components/Navbar'
import MySider from '../components/MySider'
import axiosInstance from '../utils/axiosInstance'
import TopRowCommon from '../components/TopRowCommon'
import { data as treatments } from '../constant/treatments'
import getExtensionFromMimeType from '../utils/getExtensionFromMimeType'

const { Content } = Layout

const options = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'LAST WEEK',
    value: 'week',
  },
  {
    label: 'LAST MONTH',
    value: 'month',
  },
]

function AssignedLeads() {
  const navigate = useNavigate()
  const location = useLocation()
  // const state = location.state
  const [modal, contextHolder] = Modal.useModal()
  const [searchParams] = useSearchParams()
  const leadSource = searchParams.get('leadSource')

  const [allLeads, setAllLeads] = useState([])
  const [filteredLeads, setFilteredLeads] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(leadSource || `allLeads`)
  const [page, setPage] = useState(1)
  const [totalLeads, setTotalLeads] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [dateType, setDateType] = useState('all')

  const dateTypeController = ({ target: { value } }) => {
    console.log('radio4 checked', value)
    setDateType(value)
    setPage(1)
    getAllAssignedLeads(undefined, undefined, 1, value)
  }

  const handleReload = () => {
    window.location.reload()
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }

  const handleDownload = async (url, fileName) => {
    try {
      const response = await axiosInstance.get(url, {
        responseType: 'blob', // Set the response type to blob
      })

      // Create a blob object from the response data
      const blob = new Blob([response.data])

      // Create a URL for the blob
      const blobUrl = window.URL.createObjectURL(blob)

      // Create a link element and trigger the download
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = fileName
      link.click()

      // Clean up the URL object to release resources
      window.URL.revokeObjectURL(blobUrl)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const handleSelectedTabClick = (value) => {
    navigate(`/teleRequest?leadSource=${value}`)
    setSelectedTab(value)
    setPage(1)
    getAllAssignedLeads(undefined, value, 1)
  }

  const handleSelectTag = async (key, value, el) => {
    //
    console.log(key)
    console.log(value)
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${el._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
        getAllAssignedLeads(undefined, undefined, undefined)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllAssignedLeads = async (contact, tabV, pg, dt) => {
    const paginationPage = pg || page // if parameter available then ignore page from state
    const tabValue = tabV || selectedTab
    const dtValue = dt || dateType
    setLoading(true)
    try {
      try {
        let pathString = `/cm/getAllAssignedLeads?dt=${dtValue}&page=${paginationPage}&perPage=10`

        if (tabValue === 'Website' || tabValue === 'Facebook')
          pathString = `/cm/getAllAssignedLeads?dt=${dtValue}&page=${paginationPage}&perPage=10&ls=${tabValue}`
        if (tabValue === 'contactCenter')
          pathString = `/cm/getAllAssignedLeads?dt=${dtValue}&page=${paginationPage}&perPage=10&ls=Contact Center`

        if (contact) pathString = pathString.concat(`&contact=${contact}`)

        const response = await axiosInstance.get(pathString)
        // console.log(response.data)

        // setAllLeads(response.data.allLeads) // No need anymore
        const currentRender = response.data.allLeads.filter(
          (el) => el.leadSource === tabValue
        )
        setFilteredLeads(currentRender)

        if (tabValue === 'contactCenter') {
          const currentRender = response.data.allLeads.filter(
            (el) => el.leadSource === 'Contact Center'
          )
          setFilteredLeads(currentRender)
        }

        if (tabValue === 'allLeads') setFilteredLeads(response.data.allLeads)

        setTotalLeads(response.data.totalLeads)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const onSearch = (event) => {
    // console.log(event.target.value)
    getAllAssignedLeads(event.target.value, undefined, undefined)
  }

  const onViewClick = async (el) => {
    try {
      const response = await axiosInstance.post('/cm/getAFile', {
        fileName: el.filename,
      })
      console.log(response.data)

      showModal()

      setModalContent({
        type: getExtensionFromMimeType(el.mimetype),
        url: `${process.env.REACT_APP_BASE_URL}/${response.data.url}`,
      })
    } catch (err) {
      console.log(err)
      handleReload()
    }
  }

  const onDownloadClick = async (el) => {
    try {
      console.log(el.reportsFileList)
      const response = await axiosInstance.post('/cm/getAFile', {
        fileName: el.filename,
      })
      console.log(response.data)

      handleDownload(
        `${process.env.REACT_APP_BASE_URL}/${response.data.url}`,
        response.data.url
      )
    } catch (err) {
      console.log(err)
      // handleReload()
    }
  }

  useEffect(() => {
    if (selectedTab && page) {
      getAllAssignedLeads(undefined, undefined, undefined)
    }
  }, [page])

  const generateTable = () => {
    const tableStyles = {
      container: {
        marginTop: 20,
      },
      tableHeader: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '100%',
      },
      tableHeaderText: {
        width: '10%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        fontWeight: 'bold',
        fontSize: '.9rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
      },
      contentRow: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
      },
      contentRowText: {
        width: '11%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        // fontWeight: 'bold',
        fontSize: '1rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        overflow: 'hidden',
      },
      selectStyle: {
        borderRadius: '25px',
        backgroundColor: '#EBEBEB',
        color: '#275058',
        padding: 8,
        border: 'none',
        // appearance: 'none',
        paddingRight: '15px',
        fontWeight: 'bold',
        maxWidth: 'auto',
      },
    }

    return (
      <div style={tableStyles.container}>
        <div style={tableStyles.tableHeader}>
          <div style={{ ...tableStyles.tableHeaderText, width: '3%' }}>SL.</div>
          <div style={tableStyles.tableHeaderText}>DATE</div>
          <div style={tableStyles.tableHeaderText}>PROGRAM</div>
          <div style={tableStyles.tableHeaderText}>NAME</div>
          <div style={tableStyles.tableHeaderText}>AGE</div>
          <div style={tableStyles.tableHeaderText}>CONTACT (WhatsApp)</div>
          <div style={tableStyles.tableHeaderText}>EMAIL</div>
          <div style={tableStyles.tableHeaderText}>PREFERRED DESTINATION</div>
          <div style={tableStyles.tableHeaderText}>PREFERRED DOCTOR</div>
          <div style={tableStyles.tableHeaderText}>REQUEST DATE</div>
          <div style={tableStyles.tableHeaderText}>TREATMENT SEGMENT</div>
          <div style={tableStyles.tableHeaderText}>PREFERRED HOSPITAL</div>
          <div style={tableStyles.tableHeaderText}>NOTES</div>
          <div style={tableStyles.tableHeaderText}>REPORTS</div>
          <div
            style={{
              ...tableStyles.tableHeaderText,
              borderRight: '1px solid white',
            }}
          >
            PASSPORT
          </div>
        </div>
        {filteredLeads.map((el, idx) => {
          const skip = (page - 1) * 10 // to show the serial properly after page one
          // console.log(skip)
          return (
            <div style={tableStyles.contentRow}>
              <div style={{ ...tableStyles.contentRowText, width: '3.3%' }}>
                {idx + 1 + skip}.
              </div>
              <div style={tableStyles.contentRowText}>
                {moment(el.createdAt).format('DD/MM/YYYY')}
              </div>
              <div style={tableStyles.contentRowText}>{el.program}</div>
              <div style={tableStyles.contentRowText}>{el.patientName}</div>
              <div style={tableStyles.contentRowText}>{el.age}</div>
              <div style={tableStyles.contentRowText}>{el.contact}</div>
              <div style={tableStyles.contentRowText}>{el.email}</div>

              <div style={tableStyles.contentRowText}>
                {el.preferredDestination}
              </div>
              <div style={tableStyles.contentRowText}>{el.preferredDoctor}</div>
              <div style={tableStyles.contentRowText}>{el.requestDate}</div>
              <div style={tableStyles.contentRowText}>
                {el.treatmentSegment}
              </div>
              <div style={tableStyles.contentRowText}>
                {el.preferredHospital}
                {/* {el.currentDataOfDoctor?.hospitalDetails &&
                  el.currentDataOfDoctor?.hospitalDetails[0].hospitalName} */}
              </div>
              <div style={tableStyles.contentRowText}>{el.notes}</div>
              <div
                style={{
                  ...tableStyles.contentRowText,
                  maxHeight: 60,
                  overflow: 'auto',
                }}
              >
                {el.reportsFileList?.map((item, index) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <p
                        style={styles.onDownloadStyle}
                        onClick={() => onViewClick(item)}
                      >
                        View
                      </p>
                      <p
                        style={styles.onDownloadStyle}
                        onClick={() => onDownloadClick(item)}
                      >
                        Download
                      </p>
                      {el.reportsFileList.length > 1 && index == 0 && (
                        <span style={{ fontSize: 10, margin: 0 }}>
                          {el.reportsFileList.length}
                        </span>
                      )}
                    </div>
                  )
                })}
              </div>
              <div
                style={{
                  ...tableStyles.contentRowText,
                  maxHeight: 60,
                  overflow: 'auto',
                }}
              >
                {el.passportFileList?.map((item, index) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <p
                        style={styles.onDownloadStyle}
                        onClick={() => onViewClick(item)}
                      >
                        View
                      </p>
                      <p
                        style={styles.onDownloadStyle}
                        onClick={() => onDownloadClick(item)}
                      >
                        Download
                      </p>
                      {el.passportFileList.length > 1 && index == 0 && (
                        <span style={{ fontSize: 10, margin: 0 }}>
                          {el.passportFileList.length}
                        </span>
                      )}
                    </div>
                  )
                })}
              </div>

              {/* <div
                style={{
                  ...tableStyles.contentRowText,
                  width: '4%',
                  borderRight: '1px solid white',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(`/pi/${el.pID}?tab=1`, {
                    state: { sentFrom: location },
                  })
                }
              >
                <img
                  src="/contents/icons/PROFILE.png"
                  style={{ height: '1.4rem' }}
                  alt=""
                />
                <p style={{ margin: 0, color: '#275058', fontSize: '.7rem' }}>
                  PROFILE
                </p>
              </div> */}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      {isModalOpen && (
        <Modal
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ height: 900 }}
          width={900}
        >
          {/* <p>{JSON.stringify(modalContent)}</p> */}

          {modalContent?.type?.includes('pdf') ? (
            <iframe
              src={modalContent?.url}
              frameborder="0"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          ) : (
            <img
              src={modalContent?.url}
              alt=""
              srcset=""
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </Modal>
      )}
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            // margin: '0 16px',
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          {loading && (
            <div className="overlay">
              <div style={{ textAlign: 'center', marginTop: '9rem' }}>
                <Spin size="large" />
              </div>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Home',
                  href: '/',
                },
                {
                  title: 'Assigned Leads',
                },
              ]}
              style={{ marginTop: 20 }}
            />
            {/* <Button
              style={{
                marginTop: 20,
                borderRadius: 25,
                width: 70,
                fontSize: '.8rem',
                padding: 0,
                textTransform: 'uppercase',
                border: '1px solid #275058',
              }}
              onClick={() => navigate('/dashboard')}
            >
              Back{' '}
            </Button> */}
            {/* <Button
              type="primary"
              style={{
                marginTop: 20,
                borderRadius: 25,
                width: 150,
                letterSpacing: '1px',
                fontSize: '1rem',
                padding: 0,
              }}
              onClick={() => navigate('/createLead')}
            >
              Create Lead{' '}
            </Button> */}
          </div>

          <p style={{ ...styles.actionText }}>Assigned Leads</p>

          <div style={styles.tabNSearchRow}>
            <div style={styles.tabTextContainer}>
              {/* <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'allLeads'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('allLeads')}
              >
                Website
              </p> */}
              <Radio.Group
                // style={{ marginLeft: 20 }}
                size="small"
                options={options}
                onChange={dateTypeController}
                value={dateType}
                optionType="button"
                buttonStyle="outline"
              />
              {/* <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'Website' ? styles.tabTextHighlight : {}),
                }}
                onClick={() => handleSelectedTabClick('Website')}
              >
                Last Week
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'Facebook'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('Facebook')}
              >
                Last Month{' '}
              </p> */}
            </div>
            <div>
              {/* <Input
                placeholder="Search Patient"
                onChange={onSearch}
                allowClear
                style={{
                  width: 200,
                  borderRadius: 25,
                  border: '1px solid #275058',
                }}
              /> */}
            </div>
          </div>
          {generateTable()}
          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination
              defaultCurrent={1}
              defaultPageSize={10}
              total={totalLeads}
              current={page}
              onChange={(p) => setPage(p)}
              showSizeChanger={false}
            />
          </div>
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  tabNSearchRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  tabTextContainer: {
    display: 'flex',
    gap: 10,
  },
  tabText: {
    textTransform: 'uppercase',
    color: '#275058',
    margin: 0,
    cursor: 'pointer',
  },
  tabTextHighlight: {
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
  },
  actionText: {
    color: '#275058',
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
    fontSize: '2rem',
    fontSize: '1.8rem',
    margin: 0,
    marginTop: 20,
  },
  onDownloadStyle: {
    margin: 0,
    color: '#275058',
    fontSize: '.7rem',
    marginLeft: 10,
    marginTop: 5,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}

export default AssignedLeads
