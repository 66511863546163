import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function IncentiveAction({ cmUser }) {
  const [loading, setLoading] = useState(false)
  const [leaveEntries, seMeetingEntries] = useState([])
  const [formData, setFormData] = useState({})

  const getIncentivesOfAUser = async () => {
    // setLoading(true)

    let pathString = `/su/getIncentivesOfAUser?id=${cmUser._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        seMeetingEntries(response.data.results)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getIncentivesOfAUser()
  }, [])

  return (
    <div style={{ color: '#275058', textAlign: 'center' }}>
      <h2 style={{ textDecoration: 'underline' }}>INCENTIVES SUMMARY</h2>
      <div
        style={{
          color: '#275058',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          minHeight: 200,
        }}
      >
        <div>
          <p style={{ fontWeight: 'bold' }}>INCENTIVES GIVEN</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {leaveEntries.length}
          </div>
        </div>
        <div>
          <p style={{ fontWeight: 'bold' }}>TOTAL INCENTIVE AMOUNT</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            Not found
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncentiveAction
