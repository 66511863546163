import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axiosInstance'

export const fetchAllLeads = createAsyncThunk('cm/getAllLeads', async () => {
  const response = await axiosInstance.get(`/cm/getAllLeads`)
  return response.data
})

// create a slice
export const leadSlice = createSlice({
  name: 'leads',
  initialState: {
    globalLoading: false,
    allLeads: [],
  },
  reducers: {
    testReducer: async (state, action) => {},
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAllLeads.pending, (state) => {
        return {
          ...state,
          globalLoading: true,
        }
      })
      .addCase(fetchAllLeads.fulfilled, (state, action) => {
        // console.log(action.payload)
        // state.allLeads.push(action.payload?.allLeads)
        state.allLeads = action.payload?.allLeads
        state.globalLoading = false
      })
      .addCase(fetchAllLeads.rejected, (state, action) => {
        console.log('failed to fetch leads, please do some action here.')
      })
  },
})
