import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Modal,
  Spin,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
import Navbar from '../components/Navbar'
import MySider from '../components/MySider'
import axiosInstance from '../utils/axiosInstance'
import OverviewCard from '../components/PatientInformation/OverviewCard'
import ActionCard from '../components/PatientInformation/ActionContainer/ActionCard'
import BottomNavContainer from '../components/PatientInformation/BottomNavContainer/BottomNavContainer'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

function PatientInformation() {
  const navigate = useNavigate()
  let { id } = useParams()
  // console.log(id)
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')

  const [modal, contextHolder] = Modal.useModal()

  const [leadInfo, setLeadInfo] = useState(null)
  const [allLeads, setAllLeads] = useState([])
  const [loading, setLoading] = useState(false)
  // const [selectedTab, setSelectedTab] = useState('1')
  const [selectedTab, setSelectedTab] = useState(tab || '1')

  const getALead = async () => {
    //
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.get(`/cm/getALead?id=${id}`)
        console.log(response.data)
        setLeadInfo(response.data.lead)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getALead()
  }, [])

  if (loading) {
    return (
      <>
        <Navbar />
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          {' '}
          <Content
            style={{
              // margin: '0 16px',
              padding: '0 16px',
              backgroundColor: '#fff',
            }}
          >
            {loading && (
              <div className="overlay">
                <div style={{ textAlign: 'center', marginTop: '9rem' }}>
                  <Spin size="large" />
                </div>
              </div>
            )}
          </Content>
        </Layout>
      </>
    )
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        {' '}
        <Content
          style={{
            // margin: '0 16px',
            padding: '30px 16px',
            backgroundColor: '#fff',
          }}
        >
          {loading && (
            <div className="overlay">
              <div style={{ textAlign: 'center', marginTop: '9rem' }}>
                <Spin size="large" />
              </div>
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '50%' }}>
              <OverviewCard leadInfo={leadInfo} />
            </div>
            <div style={{ width: '50%' }}>
              <ActionCard leadInfo={leadInfo} selectedTab={selectedTab} />
            </div>
          </div>
          <div>
            {leadInfo && (
              <BottomNavContainer
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                leadInfo={leadInfo}
              />
            )}
          </div>
        </Content>
      </Layout>
    </>
  )
}

const styles = {}

export default PatientInformation
