import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Modal,
  Spin,
  Pagination,
} from 'antd'
import moment from 'moment'
import TopRowCommon from '../../components/TopRowCommon'
import Navbar from '../../components/Navbar'
import axiosInstance from '../../utils/axiosInstance'
import getExtensionFromMimeType from '../../utils/getExtensionFromMimeType'

const { Content } = Layout

function PatientDischarged() {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  // const [allLeads, setAllLeads] = useState([])
  const [filteredLeads, setFilteredLeads] = useState([])
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [selectedTab, setSelectedTab] = useState(
    state?.selectedTab || `dischargedDate`
  )
  const [page, setPage] = useState(1)
  const [totalLeads, setTotalLeads] = useState(0)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }

  const handleSelectedTabClick = (value) => {
    // console.log(`handleSelectedTabClick`)
    // console.log(value)
    setPage(1)
    setSelectedTab(value)
    getAllLeads(undefined, value, 1)
  }

  const getAllLeads = async (contact, tabV, pg) => {
    const paginationPage = pg || page // if parameter available then ignore page from state

    // setLoading(true)

    const tabValue = tabV || selectedTab

    console.log(tabValue)

    let pathString
    const perPage = 10000000000000 // when doing pagination, make this to '10'

    if (tabValue === 'dischargedDate')
      pathString = `/cm/getAllLeads?page=${paginationPage}&perPage=${perPage}&bills=${tabValue}`

    if (contact) pathString = pathString.concat(`&contact=${contact}`)

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)
        // console.log(response.data.totalLeads)
        // console.log(response.data.allLeads.length)

        const testPromises = response.data.allLeads.map(async (el, idx) => {
          // console.log(el)
          // console.log(el.teleMedicine)
          let eachRow = {}
          eachRow.name = el.name
          eachRow.email = el.email
          eachRow.contact = el.contact
          eachRow._id = el._id
          eachRow.pID = el.pID
          eachRow.pID = el.pID
          eachRow.treatmentSegment = el.treatmentSegment
          eachRow.treatmentCategory = el.treatmentCategory

          const teleRows = await Promise.all(
            el.bills?.map(async (tele, index) => {
              // Perform some asynchronous operations if needed here

              // Create a new object for each teleMedicine item
              return { ...eachRow, ...tele }
            })
          )

          // console.log(teleRows)

          return teleRows
        })

        // Use Promise.all to wait for all the promises to resolve
        Promise.all(testPromises)
          .then(async (test) => {
            // console.log(test)
            // Now distruct each patient and make one array only
            let flattenedArray = [].concat(...test)

            // Filtered array with all 'YES' values
            flattenedArray = flattenedArray?.filter(Boolean)
            flattenedArray = flattenedArray?.sort(
              (a, b) => b.createdAt - a.createdAt
            )

            console.log(flattenedArray)
            // console.log(flattenedArray)
            setFilteredLeads(flattenedArray)
          })
          .catch((error) => {
            console.error(error)
          })

        // setTotalLeads(response.data.totalLeads)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const handleSelectTag = async (key, value, el) => {
    //
    return // becuase select tags are disabled
    console.log(key)
    console.log(value)
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${el._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
        getAllLeads(undefined, undefined, undefined)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const onSearch = (event) => {
    // console.log(event.target.value)
    getAllLeads(event.target.value) // sending "contact parameter"
  }

  useEffect(() => {
    getAllLeads(undefined, undefined, undefined)
  }, [page])

  const generateTable = () => {
    const tableStyles = {
      container: {
        marginTop: 20,
      },
      tableHeader: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        ...(selectedTab === 'applicationStatus'
          ? { width: '95.4%' }
          : { width: '96.5%' }),
        ...(selectedTab === 'visaAcquired' ? { width: '94%' } : {}),
      },
      tableHeaderText: {
        width: '10%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        fontWeight: 'bold',
        fontSize: '.9rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
      },
      contentRow: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
      },
      contentRowText: {
        width: '11%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        // fontWeight: 'bold',
        fontSize: '1rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        overflow: 'hidden',
      },
      selectStyle: {
        borderRadius: '25px',
        backgroundColor: '#EBEBEB',
        color: '#275058',
        padding: 8,
        border: 'none',
        // appearance: 'none',
        paddingRight: '15px',
        fontWeight: 'bold',
        maxWidth: 'auto',
      },
    }

    return (
      <div style={tableStyles.container}>
        <div style={tableStyles.tableHeader}>
          <div style={{ ...tableStyles.tableHeaderText, width: '3.4%' }}>
            SL.
          </div>
          <div style={tableStyles.tableHeaderText}>PATIENT NAME</div>
          <div style={tableStyles.tableHeaderText}>CONTACT</div>
          <div style={tableStyles.tableHeaderText}>EMAIL ID</div>
          <div style={tableStyles.tableHeaderText}>HOSPITAL</div>
          <div style={tableStyles.tableHeaderText}>DOCTOR</div>
          <div style={tableStyles.tableHeaderText}>DISCHARGE/LAST DATE</div>
          <div style={tableStyles.tableHeaderText}>TREATMENT SEGMENT</div>
          <div style={tableStyles.tableHeaderText}>TREATMENT CATEGORY</div>
          <div style={tableStyles.tableHeaderText}>BILL SHARED BY PARTNER</div>
          <div style={{ ...tableStyles.tableHeaderText, borderRight: 0 }}>
            BILL AMOUNT
          </div>
        </div>
        {filteredLeads.map((el, idx) => {
          const skip = (page - 1) * 10 // to show the serial properly after page one
          // console.log(skip)
          return (
            <div style={tableStyles.contentRow}>
              <div style={{ ...tableStyles.contentRowText, width: '3.3%' }}>
                {idx + 1 + skip}.
              </div>
              <div style={tableStyles.contentRowText}>{el.name}</div>
              <div style={tableStyles.contentRowText}>{el.contact}</div>
              <div style={tableStyles.contentRowText}>{el.email}</div>
              <div style={tableStyles.contentRowText}>{el.hospitalName}</div>
              <div style={tableStyles.contentRowText}>{el.doctorName}</div>
              <div style={tableStyles.contentRowText}>{el.dischargedDate}</div>
              <div style={tableStyles.contentRowText}>
                {el.treatmentSegment}
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  disabled
                  style={tableStyles.selectStyle}
                  value={el.treatmentCategory} //
                  onChange={(event) =>
                    handleSelectTag('treatmentCategory', event.target.value, el)
                  }
                >
                  <option value="OPD">OPD</option>
                  <option value="IPD">IPD</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                {' '}
                <select
                  disabled
                  style={tableStyles.selectStyle}
                  value={el.sharedByPartner} //
                  onChange={(event) =>
                    handleSelectTag('sharedByPartner', event.target.value, el)
                  }
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>{el.billAmount}</div>

              <div
                style={{
                  ...tableStyles.contentRowText,
                  width: '4%',
                  borderRight: '1px solid white',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(`/pi/${el.pID}?tab=7`, {
                    state: { sentFrom: location },
                  })
                }
              >
                <img
                  src="/contents/icons/PROFILE.png"
                  style={{ height: '1.4rem' }}
                  alt=""
                />
                <p style={{ margin: 0, color: '#275058', fontSize: '.7rem' }}>
                  PROFILE
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <Layout style={styles.layoutStyle}>
        <Content style={styles.contentStyle}>
          <TopRowCommon title="PATIENT DISCHARGED" />

          {loading && (
            <div className="overlay">
              <div style={{ textAlign: 'center', marginTop: '9rem' }}>
                <Spin size="large" />
              </div>
            </div>
          )}

          <div style={styles.tabNSearchRow}>
            <div style={styles.tabTextContainer}>
              {/* <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'received'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('received')}
              >
                VIL RECEIVED
              </p> */}
            </div>
            <div>
              <Input
                placeholder="Search Patient"
                onChange={onSearch}
                allowClear
                style={{
                  width: 200,
                  borderRadius: 25,
                  border: '1px solid #275058',
                }}
              />
            </div>
          </div>
          {isModalOpen && (
            <Modal
              title=""
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              bodyStyle={{ height: 900 }}
              width={900}
            >
              {/* <p>{modalContent?.type}</p> */}

              {modalContent?.type?.includes('pdf') ? (
                <iframe
                  src={modalContent?.url}
                  frameborder="0"
                  style={{ width: '100%', height: '100%' }}
                ></iframe>
              ) : (
                <img
                  src={modalContent?.url}
                  alt=""
                  srcset=""
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </Modal>
          )}
          {/* {selectedTab} */}
          {generateTable()}

          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {/* <Pagination
              defaultCurrent={1}
              defaultPageSize={10}
              total={totalLeads}
              current={page}
              onChange={(p) => setPage(p)}
              showSizeChanger={false}
            /> */}
          </div>
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  layoutStyle: {
    minHeight: '100vh',
  },
  contentStyle: {
    padding: '0 16px',
    backgroundColor: '#fff',
  },
  tabNSearchRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  tabTextContainer: {
    display: 'flex',
    gap: 10,
  },
  tabText: {
    textTransform: 'uppercase',
    color: '#275058',
    margin: 0,
    cursor: 'pointer',
  },
  tabTextHighlight: {
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
  },
}
export default PatientDischarged
