import React from 'react'
import { Navigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'

export default function Auth({ children }) {
  const auth = useSelector((state) => state.auth.token)
  const location = useLocation()

  // if (location.pathname.includes('/profile/')) {
  //   // Then don't check the 'auth' instead allow to proceed
  //   return children
  // }
  // console.log(auth)
  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} />
  }
  // console.log(children)
  return children
}
