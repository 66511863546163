import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { UploadOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  Layout,
  Button,
  Radio,
  Upload,
  Input,
  Modal,
  Alert,
  List,
  Popconfirm,
  Select,
  Spin,
  Tooltip,
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Navbar from '../../components/Navbar'
import axiosInstance from '../../utils/axiosInstance'
import { data as countriesCities } from '../../constant/countriesCities'

const { Content } = Layout
const { Option } = Select

const LabelText = styled.p`
  color: #275058;
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
`

const inputStyle = {
  padding: '12px',
  borderRadius: '26px',
  display: 'inline-block',
  border: '1px solid #275058',
}

function EditHospital() {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  // Use get() method to retrieve the value of the 'id' parameter
  const id = searchParams.get('id')
  console.log(id)

  const [modal, contextHolder] = Modal.useModal()

  const [size, setSize] = useState('ADD')
  const [allHospitals, setAllHospitals] = useState([])
  const [foundHospital, setFoundHospital] = useState(null)
  const [hospitalLogoFileList, setHospitalLogoFileList] = useState([])
  const [hospitalFileList, setHospitalFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [validationText, setValidationText] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')

  const hospitalLogoUploadProps = {
    name: 'file',
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setHospitalLogoFileList(info.fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }

  const treatmentPlanUploadProps = {
    name: 'file',
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setHospitalFileList(info.fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }

  const countDown = () => {
    let secondsToGo = 2
    const instance = modal.success({
      title: 'Hospital updated successfully!',
      content: ``,
      onOk: () => navigate(-1),
    })
    const timer = setInterval(() => {
      secondsToGo -= 1
      // instance.update({
      //   content: `This modal will be destroyed after ${secondsToGo} second.`,
      // })
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
      instance.destroy()
      navigate(-1)
    }, secondsToGo * 1000)
  }

  const validateLeadData = () => {
    setValidationText('')
    const missingFields = []

    // Check if mandatory fields are empty, null, or undefined
    if (!formData.hospitalName) missingFields.push('Hospital Name')
    if (!formData.noOfBeds) missingFields.push('No of beds')
    if (!formData.location) missingFields.push('Location')
    if (!formData.country) missingFields.push('Country')
    if (!formData.established) missingFields.push('Established year')
    if (!formData.about) missingFields.push('About')
    if (!formData.addressLineOne) missingFields.push('Address line one')
    // if (hospitalLogoFileList.length === 0) missingFields.push('Hospital Logo')
    // if (hospitalFileList.length === 0) missingFields.push('Hospital Image')

    return missingFields
  }

  const handleSubmit = async () => {
    console.log(formData)
    // return
    try {
      const missingFields = validateLeadData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      // showError(false)
      setLoading(true)

      const fData = new FormData()

      // Add your fields to the FormData object
      fData.append('hospitalName', formData.hospitalName)
      fData.append('noOfBeds', formData.noOfBeds)
      fData.append('location', formData.location)
      fData.append('country', formData.country)
      fData.append('established', formData.established)
      fData.append('addressLineOne', formData.addressLineOne)
      fData.append('about', formData.about)

      for (let i = 0; i < hospitalLogoFileList.length; i++) {
        fData.append(
          'hospitalLogoFileList',
          hospitalLogoFileList[i].originFileObj
        )
      }
      for (let i = 0; i < hospitalFileList.length; i++) {
        fData.append('hospitalFileList', hospitalFileList[i].originFileObj)
      }

      try {
        const response = await axiosInstance.post(
          `/cm/editHospital?id=${id}`,
          fData,
          {
            headers: {}, // not sure if its working or not
          }
        )
        console.log(response.data)

        countDown() // showing success modal
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAHospitalById = async () => {
    //
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.get(
          `/cm/getAHospitalById?id=${id}`
        )
        console.log(response.data)
        const hos = response.data.foundHospital
        const {
          hospitalName,
          noOfBeds,
          location,
          country,
          established,
          addressLineOne,
          about,
        } = hos

        setFoundHospital(hos)
        setFormData({
          hospitalName,
          noOfBeds,
          location,
          country,
          established,
          addressLineOne,
          about,
        })

        if (country) setSelectedCountry(country)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAHospitalById()
  }, [])

  useEffect(() => {
    console.log(formData.about)
  }, [formData])

  const addHospital = () => {
    return (
      <>
        <p style={{ ...styles.actionText, fontSize: '1.2rem' }}>
          EDIT HOSPITAL
        </p>

        <div style={{ display: 'flex', gap: 40 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Upload {...hospitalLogoUploadProps}>
              <div
                style={{
                  marginTop: 20,
                  width: '300px',
                  height: '50px',
                  background:
                    'radial-gradient(circle, rgba(37, 81, 89, 0.8) 0%, rgba(36, 78, 86, 1) 100%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
              >
                <PlusCircleOutlined
                  style={{ color: 'white', fontSize: '1rem' }}
                />
                <p style={{ margin: 0 }}>ADD LOGO</p>
              </div>
            </Upload>
            <Upload {...treatmentPlanUploadProps}>
              <div
                style={{
                  marginTop: 20,
                  width: '300px',
                  height: '150px',
                  background:
                    'radial-gradient(circle, rgba(37, 81, 89, 0.8) 0%, rgba(36, 78, 86, 1) 100%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
              >
                <PlusCircleOutlined
                  style={{ color: 'white', fontSize: '2rem' }}
                />
                <p style={{ margin: 0 }}>PICTURE</p>
              </div>
            </Upload>
            <Alert
              showIcon
              style={{ width: '300px', marginTop: 20 }}
              message="To edit an image please upload any of the one above. To skip image upload please leave the images fields blank."
              type="info"
            />
          </div>

          <div style={{ border: '1px solid white', minWidth: 800 }}>
            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                marginBottom: 10,
                gap: 50,
              }}
            >
              <div style={{ width: '40%' }}>
                <LabelText>*HOSPITAL NAME</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Name"
                  value={formData.hospitalName}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, hospitalName: value }
                    })
                  }}
                />
              </div>

              <div style={{ width: '40%' }}>
                <LabelText>NO. OF BEDS</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Age"
                  value={formData.noOfBeds}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, noOfBeds: value }
                    })
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                gap: 50,
              }}
            >
              <div style={{ width: '40%' }}>
                <LabelText>*Country</LabelText>
                <Select
                  style={styles.selectStyle}
                  value={formData.country}
                  onChange={(value) => {
                    // console.log(value)
                    setSelectedCountry(value)
                    setFormData((prev) => {
                      return { ...prev, country: value }
                    })
                  }}
                >
                  {countriesCities.map((el, idx) => (
                    <Option value={el.country} key={idx}>
                      {el.country}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                border: '1px solid white',
                gap: 50,
              }}
            >
              <div style={{ width: '40%' }}>
                <LabelText>*LOCATION</LabelText>
                {/* <Input
                  style={{ ...inputStyle }}
                  // placeholder="Name"
                  //   value={name}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, location: value }
                    })
                  }}
                /> */}

                <Tooltip
                  color={'#275058'}
                  title="Select country to get its cities"
                >
                  <Select
                    showSearch
                    style={styles.selectStyle}
                    value={formData.location}
                    onChange={(value) => {
                      // console.log(value)
                      setFormData((prev) => {
                        return { ...prev, location: value }
                      })
                    }}
                  >
                    {countriesCities
                      .filter((obj) => obj.country == selectedCountry)
                      .map((country, idx) =>
                        country.cities.map((city, cityIdx) => (
                          <Option value={city.name} key={`${idx}-${cityIdx}`}>
                            {city.name}
                          </Option>
                        ))
                      )}
                  </Select>
                </Tooltip>
              </div>

              <div style={{ width: '40%' }}>
                <LabelText>*ESTABLISHED YEAR (YYYY)</LabelText>
                <Input
                  style={{ ...inputStyle }}
                  // placeholder="Age"
                  value={formData.established}
                  onChange={({ target: { value } }) => {
                    setFormData((prev) => {
                      return { ...prev, established: value }
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: '40%', marginTop: 20 }}>
          <LabelText>*ADDRESS LINE</LabelText>
          <Input
            style={{ ...inputStyle }}
            // placeholder="Name"
            value={formData.addressLineOne}
            onChange={({ target: { value } }) => {
              setFormData((prev) => {
                return { ...prev, addressLineOne: value }
              })
            }}
          />
        </div>

        <div style={{ marginTop: 20 }}></div>
        <LabelText>*ABOUT</LabelText>
        <ReactQuill
          theme="snow"
          defaultValue={formData.about}
          // value={formData.about} // this is causing infinite loop
          onChange={(value) => {
            setFormData((prev) => {
              return { ...prev, about: value }
            })
          }}
          style={{ height: '30vh' }}
        />

        {validationText && (
          <div style={{ marginTop: 60 }}>
            <Alert message={validationText} type="error" />
          </div>
        )}

        {loading && (
          <div className="overlay">
            <div style={{ textAlign: 'center', marginTop: '9rem' }}>
              <Spin size="large" />
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 20,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => handleSubmit()}
          >
            SAVE{' '}
          </Button>
          <Button
            style={{
              marginTop: 20,
              borderRadius: 25,
              width: 200,
              letterSpacing: '1px',
              fontSize: '1.2rem',
              padding: 0,
              height: '3rem',
              border: '1px solid #275058',
              color: '#275058',
              fontWeight: 'bold',
            }}
            onClick={() => navigate('/dashboard')}
          >
            Cancel{' '}
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button style={styles.backBtn} onClick={() => navigate(-1)}>
              Back{' '}
            </Button>
          </div>
          {foundHospital && addHospital()}
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  backBtn: {
    marginTop: 20,
    borderRadius: 25,
    width: 70,
    fontSize: '.8rem',
    padding: 0,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  actionText: {
    color: '#275058',
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
    fontSize: '2rem',
  },
  selectStyle: {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
    padding: 5,
    overflow: 'hidden',
    width: '90%',
  },
}

export default EditHospital
