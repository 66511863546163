import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Modal,
  Spin,
  Pagination,
} from 'antd'
import moment from 'moment'
import TopRowCommon from '../../components/TopRowCommon'
import Navbar from '../../components/Navbar'
import axiosInstance from '../../utils/axiosInstance'
import { data as treatments } from '../../constant/treatments'

const { Content } = Layout

function LeadStatus() {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  // const [allLeads, setAllLeads] = useState([])
  const [filteredLeads, setFilteredLeads] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(
    state?.selectedTab || `underProcess`
  )
  const [page, setPage] = useState(1)
  const [totalLeads, setTotalLeads] = useState(0)

  const handleSelectedTabClick = (value) => {
    // console.log(`handleSelectedTabClick`)
    // console.log(value)
    setPage(1)
    setSelectedTab(value)
    getAllLeads(undefined, value, 1)
  }

  const handleSelectTag = async (key, value, el) => {
    //
    console.log(key)
    console.log(value)
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${el._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
        getAllLeads(undefined, undefined, undefined)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllLeads = async (contact, leadStatus, pg) => {
    const paginationPage = pg || page // if parameter available then ignore page from state
    //
    setLoading(true)
    try {
      try {
        let pathString = `/cm/getAllLeads?page=${paginationPage}&perPage=10&leadStatus=${
          leadStatus || selectedTab
        }`

        if (contact)
          pathString = `/cm/getAllLeads?page=${paginationPage}&perPage=10&leadStatus=${
            leadStatus || selectedTab
          }&contact=${contact}`

        const response = await axiosInstance.get(pathString)
        // console.log(response.data)
        // console.log(response.data.totalLeads)
        // console.log(response.data.allLeads.length)

        // setAllLeads(response.data.allLeads)
        const currentRender = response.data.allLeads.filter(
          (el) => el.leadStatus === selectedTab || 'underProcess'
        )

        setFilteredLeads(currentRender)
        setTotalLeads(response.data.totalLeads)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const onSearch = (event) => {
    // console.log(event.target.value)
    getAllLeads(event.target.value) // sending "contact parameter"
  }

  // // Initial fetching
  // useEffect(() => {
  //   getAllLeads(undefined, undefined, undefined)
  // }, [])

  useEffect(() => {
    getAllLeads(undefined, undefined, undefined)
  }, [page])

  const generateTable = () => {
    const tableStyles = {
      container: {
        marginTop: 20,
      },
      tableHeader: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '97%',
      },
      tableHeaderText: {
        width: '10%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        fontWeight: 'bold',
        fontSize: '.9rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
      },
      contentRow: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
      },
      contentRowText: {
        width: '11%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        // fontWeight: 'bold',
        fontSize: '1rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        overflow: 'hidden',
      },
      selectStyle: {
        borderRadius: '25px',
        backgroundColor: '#EBEBEB',
        color: '#275058',
        padding: 8,
        border: 'none',
        // appearance: 'none',
        paddingRight: '15px',
        fontWeight: 'bold',
        maxWidth: 'auto',
      },
    }

    return (
      <div style={tableStyles.container}>
        <div style={tableStyles.tableHeader}>
          <div style={{ ...tableStyles.tableHeaderText, width: '3%' }}>SL.</div>
          <div style={tableStyles.tableHeaderText}>Patient Name</div>
          <div style={tableStyles.tableHeaderText}>Contact</div>
          <div style={tableStyles.tableHeaderText}>Created By</div>
          <div style={tableStyles.tableHeaderText}>Passport Received</div>
          <div style={tableStyles.tableHeaderText}>Report Received</div>
          <div style={tableStyles.tableHeaderText}>Lead Source</div>
          <div style={tableStyles.tableHeaderText}>Lead Created On</div>
          <div style={tableStyles.tableHeaderText}>Lead Status</div>
          <div style={tableStyles.tableHeaderText}>Location</div>
          <div style={tableStyles.tableHeaderText}>Treatment Segment</div>
          <div
            style={{
              ...tableStyles.tableHeaderText,
              borderRight: '1px solid white',
            }}
          >
            Treatment Category
          </div>
        </div>
        {filteredLeads.map((el, idx) => {
          const skip = (page - 1) * 10 // to show the serial properly after page one
          // console.log(skip)
          return (
            <div style={tableStyles.contentRow}>
              <div style={{ ...tableStyles.contentRowText, width: '3.3%' }}>
                {idx + 1 + skip}.
              </div>
              <div style={tableStyles.contentRowText}>{el.name}</div>
              <div style={tableStyles.contentRowText}>{el.contact}</div>
              <div style={tableStyles.contentRowText}>
                {el.createdByDetails &&
                  el.createdByDetails[0].email?.split('@')[0]}
              </div>
              <div style={tableStyles.contentRowText}>
                {/* {JSON.stringify(!!el.passportFileList)} */}
                <select
                  style={tableStyles.selectStyle}
                  value={el.passportReceived}
                  disabled
                  onChange={(event) =>
                    handleSelectTag('passportReceived', event.target.value, el)
                  }
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                {' '}
                <select
                  disabled
                  style={tableStyles.selectStyle}
                  value={el.reportReceived}
                  onChange={(event) =>
                    handleSelectTag('reportReceived', event.target.value, el)
                  }
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  disabled
                  style={tableStyles.selectStyle}
                  value={el.leadSource}
                  onChange={(event) =>
                    handleSelectTag('leadSource', event.target.value, el)
                  }
                >
                  <option value="Website">Website</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Contact Center">Contact Center</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                {moment(el.createdAt).format('DD.MM.YYYY')}
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  disabled
                  style={tableStyles.selectStyle}
                  value={el.leadStatus}
                  onChange={(event) =>
                    handleSelectTag('leadStatus', event.target.value, el)
                  }
                >
                  <option value="underProcess">Under Process</option>
                  <option value="onGoing">On Going</option>
                  <option value="lost">Lost</option>
                  <option value="converted">Converted</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>{el.location}</div>
              <div style={tableStyles.contentRowText}>
                <select
                  disabled
                  style={tableStyles.selectStyle}
                  value={el.treatmentSegment}
                  onChange={(event) =>
                    handleSelectTag('treatmentSegment', event.target.value, el)
                  }
                >
                  {treatments.map((segment, ind) => {
                    return (
                      <option value={segment.title} key={ind}>
                        {segment.title}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  disabled
                  style={tableStyles.selectStyle}
                  value={el.treatmentCategory}
                  onChange={(event) =>
                    handleSelectTag('treatmentCategory', event.target.value, el)
                  }
                >
                  <option value="OPD">OPD</option>
                  <option value="IPD">IPD</option>
                </select>
              </div>
              <div
                style={{
                  ...tableStyles.contentRowText,
                  width: '4%',
                  borderRight: '1px solid white',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(`/pi/${el.pID}?tab=1`, {
                    state: { sentFrom: location },
                  })
                }
              >
                <img
                  src="/contents/icons/PROFILE.png"
                  style={{ height: '1.4rem' }}
                  alt=""
                />
                <p style={{ margin: 0, color: '#275058', fontSize: '.7rem' }}>
                  PROFILE
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <Layout style={styles.layoutStyle}>
        <Content style={styles.contentStyle}>
          <TopRowCommon title="LEAD STATUS" />

          {loading && (
            <div className="overlay">
              <div style={{ textAlign: 'center', marginTop: '9rem' }}>
                <Spin size="large" />
              </div>
            </div>
          )}

          <div style={styles.tabNSearchRow}>
            <div style={styles.tabTextContainer}>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'underProcess'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('underProcess')}
              >
                UNDER PROCESS
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'onGoing' ? styles.tabTextHighlight : {}),
                }}
                onClick={() => handleSelectedTabClick('onGoing')}
              >
                ON GOING
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'converted'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('converted')}
              >
                CONVERTED
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'lost' ? styles.tabTextHighlight : {}),
                }}
                onClick={() => handleSelectedTabClick('lost')}
              >
                Lost
              </p>
            </div>
            <div>
              <Input
                placeholder="Search Patient"
                onChange={onSearch}
                allowClear
                style={{
                  width: 200,
                  borderRadius: 25,
                  border: '1px solid #275058',
                }}
              />
            </div>
          </div>

          {generateTable()}

          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination
              defaultCurrent={1}
              defaultPageSize={10}
              total={totalLeads}
              current={page}
              onChange={(p) => setPage(p)}
              showSizeChanger={false}
            />
          </div>
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  layoutStyle: {
    minHeight: '100vh',
  },
  contentStyle: {
    padding: '0 16px',
    backgroundColor: '#fff',
  },
  tabNSearchRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  tabTextContainer: {
    display: 'flex',
    gap: 10,
  },
  tabText: {
    textTransform: 'uppercase',
    color: '#275058',
    margin: 0,
    cursor: 'pointer',
  },
  tabTextHighlight: {
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
  },
}

export default LeadStatus
