import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Layout,
  Button,
  Radio,
  Upload,
  Input,
  Modal,
  Alert,
  List,
  Popconfirm,
  Select,
  Spin,
} from 'antd'

import Navbar from './Navbar'

function TopRowCommon({ title }) {
  const navigate = useNavigate()

  return (
    <div style={styles.topRow}>
      <Button style={styles.backBtn} onClick={() => navigate('/dashboard')}>
        Back
      </Button>
      <p style={{ ...styles.actionText }}>{title}</p>{' '}
      <Button
        style={styles.createLeadBtn}
        onClick={() => navigate('/createLead')}
      >
        Create Lead{' '}
      </Button>
    </div>
  )
}

const styles = {
  topRow: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backBtn: {
    borderRadius: 25,
    width: 70,
    fontSize: '.8rem',
    padding: 0,
    textTransform: 'uppercase',
    border: '1px solid #275058',
  },
  actionText: {
    color: '#275058',
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
    fontSize: '2rem',
    fontSize: '1.8rem',
    margin: 0,
  },
  createLeadBtn: {
    borderRadius: 25,
    width: 200,
    fontWeight: 'bold',
    letterSpacing: '1px',
    fontSize: '1.2rem',
    padding: 0,
    border: '1px solid #275850',
  },
}

export default TopRowCommon
