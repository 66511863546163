import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Modal, Popconfirm, Input, message } from 'antd'
import { CloseCircleOutlined, FileOutlined } from '@ant-design/icons'

import axiosInstance from '../../../utils/axiosInstance'
import getExtensionFromMimeType from '../../../utils/getExtensionFromMimeType'

function VisaStatus({ leadInfo }) {
  const { visaRecords } = leadInfo

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState({})

  const onSave = async () => {
    console.log(formData)

    try {
      const response = await axiosInstance.put(
        `/cm/updateVisaRecords?id=${leadInfo._id}`,
        {
          formData,
        }
      )
      console.log(response.data)
      // setAllLeads(response.data.allLeads)
      message.success(`Successfully Updated!`)
      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
    }
  }

  const handleSelectTag = async (value, idx, key) => {
    //

    console.log(value)
    console.log(idx)
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateVisaRecords?id=${leadInfo._id}`,
          {
            // [key]: value,
            formData: { [idx]: { [key]: value } },
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }

  const handleReload = () => {
    window.location.reload()
  }

  const generateTable = () => {
    const tableStyles = {
      container: {
        marginTop: 20,
        width: '100%',
      },
      tableHeader: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '97%',
        backgroundColor: '#275058',
      },
      tableHeaderText: {
        width: '19%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: 'white',
        // fontWeight: 'bold',
        fontSize: '.9rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid white',
      },
      contentRow: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '97%',
      },
      contentRowText: {
        width: '19%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        fontSize: '1rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        overflow: 'hidden',
      },
    }
    if (!leadInfo) return <></>
    const combined = [...leadInfo.reportsFileList, ...leadInfo.passportFileList]
    console.log(combined)
    return (
      <div style={tableStyles.container}>
        <div style={tableStyles.tableHeader}>
          <div style={{ ...tableStyles.tableHeaderText, width: '3%' }}>SL.</div>
          <div style={tableStyles.tableHeaderText}>CONCERNED HOSPITAL</div>
          <div style={tableStyles.tableHeaderText}>VISA TYPE</div>
          <div style={tableStyles.tableHeaderText}>VIL REQUESTED</div>
          <div style={tableStyles.tableHeaderText}>VIL REQUESTED DATE</div>
          <div style={tableStyles.tableHeaderText}>VIL RECEIVED</div>
          <div style={tableStyles.tableHeaderText}>VIL RECEIVED DATE</div>
          <div style={tableStyles.tableHeaderText}>VIL APPLICATION STATUS</div>
          <div style={tableStyles.tableHeaderText}>VISA SUBMITTED</div>
          <div style={tableStyles.tableHeaderText}>NO. OF ATTENDANTS</div>
          <div style={tableStyles.tableHeaderText}>VISA SUBMISSION DATE</div>
          <div style={tableStyles.tableHeaderText}>ATTENDANTS VISA STATUS</div>
          <div style={tableStyles.tableHeaderText}>VISA ACQUIRED</div>
          <div style={tableStyles.tableHeaderText}>VISA ACQUIRED DATE</div>
          <div style={tableStyles.tableHeaderText}>FLY-OUT CONFIRMED</div>
          <div style={tableStyles.tableHeaderText}>FLY-OUT DATE</div>
          <div style={tableStyles.tableHeaderText}>VIL Document</div>

          <div
            style={{
              ...tableStyles.tableHeaderText,
              borderRight: '1px solid #275058',
            }}
          >
            Delete
          </div>
        </div>
        {visaRecords?.map((el, idx) => {
          return (
            <div style={tableStyles.contentRow}>
              <div style={{ ...tableStyles.contentRowText, width: '3%' }}>
                <span style={{ fontWeight: 'bold' }}>{idx + 1}.</span>
              </div>
              <div style={tableStyles.contentRowText}>
                <Input
                  style={styles.invisibleInput}
                  defaultValue={el.concernedHospital}
                  // value={formData.name}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [idx]: {
                          ...prev[idx],
                          concernedHospital: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>
              <div style={tableStyles.contentRowText}>
                {' '}
                <Input
                  style={styles.invisibleInput}
                  defaultValue={el.visaType}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [idx]: { ...prev[idx], visaType: e.target.value },
                      }
                    })
                  }}
                />
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={styles.selectStyle}
                  defaultValue={el.requested}
                  onChange={(event) => {
                    handleSelectTag(event.target.value, idx, 'requested')
                  }}
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                <Input
                  style={styles.invisibleInput}
                  defaultValue={el.requestedDate}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [idx]: { ...prev[idx], requestedDate: e.target.value },
                      }
                    })
                  }}
                />
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={styles.selectStyle}
                  defaultValue={el.received}
                  onChange={(event) => {
                    handleSelectTag(event.target.value, idx, 'received')
                  }}
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                <Input
                  style={styles.invisibleInput}
                  defaultValue={el.receivedDate}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [idx]: { ...prev[idx], receivedDate: e.target.value },
                      }
                    })
                  }}
                />
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={styles.selectStyle}
                  defaultValue={el.applicationStatus}
                  onChange={(event) => {
                    handleSelectTag(
                      event.target.value,
                      idx,
                      'applicationStatus'
                    )
                  }}
                >
                  <option value="Under Process">Under Process</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={styles.selectStyle}
                  defaultValue={el.submitted}
                  onChange={(event) => {
                    handleSelectTag(event.target.value, idx, 'submitted')
                  }}
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                <Input
                  style={styles.invisibleInput}
                  defaultValue={el.noOfAttendants}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [idx]: { ...prev[idx], noOfAttendants: e.target.value },
                      }
                    })
                  }}
                />
              </div>
              <div style={tableStyles.contentRowText}>
                <Input
                  style={styles.invisibleInput}
                  defaultValue={el.submissionDate}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [idx]: { ...prev[idx], submissionDate: e.target.value },
                      }
                    })
                  }}
                />
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={styles.selectStyle}
                  defaultValue={el.visaStatus}
                  onChange={(event) => {
                    handleSelectTag(event.target.value, idx, 'visaStatus')
                  }}
                >
                  <option value="Received">Received</option>
                  <option value="Submitted">Submitted</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={styles.selectStyle}
                  defaultValue={el.visaAcquired}
                  onChange={(event) => {
                    handleSelectTag(event.target.value, idx, 'visaAcquired')
                  }}
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                {' '}
                <Input
                  style={styles.invisibleInput}
                  defaultValue={el.visaAcquiredDate}
                  // value={formData.name}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [idx]: {
                          ...prev[idx],
                          visaAcquiredDate: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>
              <div style={tableStyles.contentRowText}>
                {' '}
                <select
                  style={styles.selectStyle}
                  defaultValue={el.flyOutConfirmed}
                  onChange={(event) => {
                    handleSelectTag(event.target.value, idx, 'flyOutConfirmed')
                  }}
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                {' '}
                <Input
                  style={styles.invisibleInput}
                  defaultValue={el.flyOutDate}
                  // value={formData.name}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [idx]: {
                          ...prev[idx],
                          flyOutDate: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>

              <div style={tableStyles.contentRowText}>
                {/* {JSON.stringify(el)} */}
                {}
                <p
                  style={{
                    margin: 0,
                    color: '#275058',
                    fontSize: '.7rem',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    try {
                      const response = await axiosInstance.post(
                        '/cm/getAFile',
                        {
                          fileName: el.visaFileList[0].filename,
                        }
                      )
                      console.log(response.data)

                      showModal()

                      setModalContent({
                        type: getExtensionFromMimeType(
                          el.visaFileList[0].mimetype
                        ),
                        url: `${process.env.REACT_APP_BASE_URL}/${response.data.url}`,
                      })
                    } catch (err) {
                      console.log(err)
                      // handleReload()
                    }
                  }}
                >
                  {el.visaFileList.length > 0 && 'View'}
                </p>
              </div>
              <div
                style={{
                  ...tableStyles.contentRowText,
                  // width: '4%',
                  borderRight: '1px solid white',
                  cursor: 'pointer',
                }}
              >
                <Popconfirm
                  title="Delete the file"
                  description="Are you sure to delete this file and treatment plan?"
                  onConfirm={async () => {
                    try {
                      const response = await axiosInstance.post(
                        '/cm/deleteAVisaRecord',
                        {
                          _id: leadInfo?._id,
                          filename:
                            el.visaFileList.length > 0
                              ? el.visaFileList[0].filename
                              : '',
                          index: idx,
                        }
                      )
                      console.log(response.data)
                      handleReload()
                    } catch (err) {
                      console.log(err)
                      // handleReload()
                    }
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <CloseCircleOutlined style={{ cursor: 'pointer' }} />
                </Popconfirm>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  const handleDownload = async (url, fileName) => {
    try {
      const response = await axiosInstance.get(url, {
        responseType: 'blob', // Set the response type to blob
      })

      // Create a blob object from the response data
      const blob = new Blob([response.data])

      // Create a URL for the blob
      const blobUrl = window.URL.createObjectURL(blob)

      // Create a link element and trigger the download
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = fileName
      link.click()

      // Clean up the URL object to release resources
      window.URL.revokeObjectURL(blobUrl)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  return (
    <div>
      <p
        style={{
          display: 'inline-block',
          padding: 5,
          border: '1px solid #275058',
          color: '#275058',
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          cursor: 'pointer',
        }}
        onClick={onSave}
      >
        Update
      </p>
      {isModalOpen && (
        <Modal
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ height: 900 }}
          width={900}
        >
          {/* <p>{modalContent?.type}</p> */}

          {modalContent?.type?.includes('pdf') ? (
            <iframe
              src={modalContent?.url}
              frameborder="0"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          ) : (
            <img
              src={modalContent?.url}
              alt=""
              srcset=""
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </Modal>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {generateTable()}
      </div>
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default VisaStatus
