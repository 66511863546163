import Routes from './routes/Routes'
import { Provider } from 'react-redux'
import { ConfigProvider, Breadcrumb, Button, Form, Input, Spin } from 'antd'
import './App.css'
import store from './store'

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#2D575F',
          borderRadius: 2,

          // Alias Token
          // colorBgContainer: '#f6ffed',
        },
        components: {
          Button: {},
          Table: {
            /* here is your component tokens */
            borderColor: '#275058',
          },
        },
      }}
    >
      <Provider store={store}>
        <Routes />
      </Provider>
    </ConfigProvider>
  )
}

export default App
