import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function OfficeReqAction({ cmUser }) {
  const [loading, setLoading] = useState(false)
  const [attendanceEntries, setattendanceEntries] = useState([])
  const [totalBudget, setTotalBudget] = useState(0)
  const getOfficeReqOfAUser = async () => {
    // setLoading(true)

    let pathString = `/su/getOfficeReqOfAUser?id=${cmUser._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        response.data.results.forEach((element) => {
          if (parseInt(element.requiredBudget) > 0) {
            setTotalBudget((p) => p + parseFloat(element.requiredBudget))
          }
        })

        setattendanceEntries(response.data.results)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getOfficeReqOfAUser()
  }, [])

  return (
    <div style={{ color: '#275058', textAlign: 'center' }}>
      {/* <h2 style={{ textDecoration: 'underline' }}>SUMMARY</h2> */}
      <div
        style={{
          color: '#275058',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          minHeight: 200,
        }}
      >
        <div>
          <p style={{ fontWeight: 'bold' }}>NO. OF REQUESTS</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {attendanceEntries.length}
          </div>
        </div>
        <div>
          <p style={{ fontWeight: 'bold' }}>REQUESTED BUDGET</p>
          <div
            style={{
              border: '1px solid #275058',
              textAlign: 'center',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {totalBudget}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfficeReqAction
