function getExtensionFromMimeType(mimeType) {
  const mimeToExtensionMap = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'application/pdf': 'pdf',
    'text/plain': 'txt',
    // Add more MIME type to extension mappings as needed
  }

  // Check if the MIME type is in the map
  if (mimeToExtensionMap.hasOwnProperty(mimeType)) {
    return mimeToExtensionMap[mimeType]
  } else {
    // If the MIME type is not found, you can return a default extension or handle it as needed
    return 'unknown'
  }
}

export default getExtensionFromMimeType
