export const data = [
  {
    country: 'India',
    cities: [
      { name: 'Uttar Pradesh' },
      { name: 'Uttarkhand' },
      { name: 'Patparganj' },
      { name: 'Saket' },
      { name: 'Banglore' },
      { name: 'Amritsar' },
      { name: 'Vasant Kunj' },
      { name: 'Shalimar Bagh' },
      { name: 'Noida' },
      {
        name: 'Vaishali',
      },
      {
        name: 'Panchsheel',
      },
      {
        name: 'Gujarat',
      },
      {
        name: 'Punjab',
      },
      {
        name: 'Gurugram',
      },
      {
        name: 'Hyderabad',
      },
      {
        name: 'Kerala',
      },
      {
        name: 'Tamil Nadu',
      },
      {
        name: 'Maharashtra',
      },
      {
        name: 'Rajasthan',
      },
      {
        name: 'Karnataka',
      },
      {
        name: 'Delhi-NCR',
      },
      {
        name: 'Faridabad',
      },
      {
        name: 'West Bengal',
      },
      {
        name: 'Mumbai',
      },
      {
        name: 'Chandigarh',
      },
      {
        name: 'New Delhi',
      },
      {
        name: 'Indore',
      },
      {
        name: 'Chennai',
      },
      {
        name: 'Jharkhand',
      },
    ],
  },
  {
    country: 'Thailand',
    cities: [
      { name: 'Bangna' },
      { name: 'Suan Luang' },
      { name: 'Watthana' },
      { name: 'Bang Phlat' },
      { name: 'Bangkok Noi District' },
      { name: 'Bang Kapi District' },
      { name: 'Mueang Krabi District' },
      { name: 'Huai Khwang' },
      { name: 'Phaya Thai' },
      { name: 'Chom Thong' },
      { name: 'Udon Thani' },
      { name: 'Sukhumvit Road' },
      { name: 'Khlong Toei' },
      { name: 'Hua Hin' },
      { name: 'Bang Krachao' },
      { name: 'Bangkok' },
    ],
  },
  {
    country: 'U.A.E',
    cities: [{ name: '_' }],
  },
  {
    country: 'Turkey',
    cities: [{ name: 'Istanbul' }, { name: '_' }],
  },
  {
    country: 'Malaysia',
    cities: [{ name: '_' }],
  },
  {
    country: 'Singapore',
    cities: [{ name: '_' }],
  },
]
