import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { Layout, Button, Input, Modal, Spin, Pagination, Radio } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
import Navbar from '../components/Navbar'
import MySider from '../components/MySider'
import axiosInstance from '../utils/axiosInstance'
import { data as treatments } from '../constant/treatments'

const { Content } = Layout

const options = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'TODAY',
    value: 'today',
  },
  {
    label: 'LAST WEEK',
    value: 'week',
  },
  {
    label: 'LAST MONTH',
    value: 'month',
  },
]

function AllLeads() {
  const navigate = useNavigate()
  const location = useLocation()
  // const state = location.state
  const [modal, contextHolder] = Modal.useModal()
  const [searchParams] = useSearchParams()
  const leadSource = searchParams.get('leadSource')

  const [allLeads, setAllLeads] = useState([])
  const [filteredLeads, setFilteredLeads] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(leadSource || `allLeads`)
  const [page, setPage] = useState(1)
  const [totalLeads, setTotalLeads] = useState(0)
  const [dateType, setDateType] = useState('all')

  const dateTypeController = ({ target: { value } }) => {
    console.log('radio4 checked', value)
    setDateType(value)
    setPage(1)
    getAllLeads(undefined, undefined, 1, value)
  }

  const handleSelectedTabClick = (value) => {
    navigate(`/allLeads?leadSource=${value}`)
    setSelectedTab(value)
    setPage(1)
    getAllLeads(undefined, value, 1)
  }

  const handleSelectTag = async (key, value, el) => {
    //
    console.log(key)
    console.log(value)
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${el._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
        getAllLeads(undefined, undefined, undefined)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const getAllLeads = async (contact, tabV, pg, dt) => {
    const paginationPage = pg || page // if parameter available then ignore page from state
    const tabValue = tabV || selectedTab
    const dtValue = dt || dateType
    setLoading(true)
    try {
      try {
        let pathString = `/cm/getAllLeads?dt=${dtValue}&page=${paginationPage}&perPage=10`

        if (tabValue === 'Website' || tabValue === 'Facebook')
          pathString = `/cm/getAllLeads?dt=${dtValue}&page=${paginationPage}&perPage=10&ls=${tabValue}`
        if (tabValue === 'contactCenter')
          pathString = `/cm/getAllLeads?dt=${dtValue}&page=${paginationPage}&perPage=10&ls=Contact Center`

        if (contact) pathString = pathString.concat(`&contact=${contact}`)

        const response = await axiosInstance.get(pathString)
        // console.log(response.data)

        // setAllLeads(response.data.allLeads) // No need anymore
        const currentRender = response.data.allLeads.filter(
          (el) => el.leadSource === tabValue
        )
        setFilteredLeads(currentRender)

        if (tabValue === 'contactCenter') {
          const currentRender = response.data.allLeads.filter(
            (el) => el.leadSource === 'Contact Center'
          )
          setFilteredLeads(currentRender)
        }

        if (tabValue === 'allLeads') setFilteredLeads(response.data.allLeads)

        setTotalLeads(response.data.totalLeads)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const onSearch = (event) => {
    // console.log(event.target.value)
    getAllLeads(event.target.value, undefined, undefined)
  }

  useEffect(() => {
    if (selectedTab && page) {
      getAllLeads(undefined, undefined, undefined)
    }
  }, [page])

  const generateTable = () => {
    const tableStyles = {
      container: {
        marginTop: 20,
      },
      tableHeader: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '97%',
      },
      tableHeaderText: {
        width: '10%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        fontWeight: 'bold',
        fontSize: '.9rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
      },
      contentRow: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
      },
      contentRowText: {
        width: '11%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        // fontWeight: 'bold',
        fontSize: '1rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        overflow: 'hidden',
      },
      selectStyle: {
        borderRadius: '25px',
        backgroundColor: '#EBEBEB',
        color: '#275058',
        padding: 8,
        border: 'none',
        // appearance: 'none',
        paddingRight: '15px',
        fontWeight: 'bold',
        maxWidth: 'auto',
      },
    }

    return (
      <div style={tableStyles.container}>
        <div style={tableStyles.tableHeader}>
          <div style={{ ...tableStyles.tableHeaderText, width: '3%' }}>SL.</div>
          <div style={tableStyles.tableHeaderText}>Patient Name</div>
          <div style={tableStyles.tableHeaderText}>Contact</div>
          <div style={tableStyles.tableHeaderText}>Email ID</div>
          <div style={tableStyles.tableHeaderText}>Passport Received</div>
          <div style={tableStyles.tableHeaderText}>Report Received</div>
          <div style={tableStyles.tableHeaderText}>Lead Source</div>
          <div style={tableStyles.tableHeaderText}>Lead Created On</div>
          <div style={tableStyles.tableHeaderText}>Lead Status</div>
          <div style={tableStyles.tableHeaderText}>Location</div>
          <div style={tableStyles.tableHeaderText}>Treatment Segment</div>
          <div
            style={{
              ...tableStyles.tableHeaderText,
              borderRight: '1px solid white',
            }}
          >
            Treatment Category
          </div>
        </div>
        {filteredLeads.map((el, idx) => {
          const skip = (page - 1) * 10 // to show the serial properly after page one
          // console.log(skip)
          return (
            <div style={tableStyles.contentRow}>
              <div style={{ ...tableStyles.contentRowText, width: '3.3%' }}>
                {idx + 1 + skip}.
              </div>
              <div style={tableStyles.contentRowText}>{el.name}</div>
              <div style={tableStyles.contentRowText}>{el.contact}</div>
              <div style={tableStyles.contentRowText}>{el.email}</div>
              <div style={tableStyles.contentRowText}>
                {/* {JSON.stringify(!!el.passportFileList)} */}
                <select
                  style={tableStyles.selectStyle}
                  value={el.passportReceived}
                  onChange={(event) =>
                    handleSelectTag('passportReceived', event.target.value, el)
                  }
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                {' '}
                <select
                  style={tableStyles.selectStyle}
                  value={el.reportReceived}
                  onChange={(event) =>
                    handleSelectTag('reportReceived', event.target.value, el)
                  }
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={tableStyles.selectStyle}
                  value={el.leadSource}
                  onChange={(event) =>
                    handleSelectTag('leadSource', event.target.value, el)
                  }
                >
                  <option value="Website">Website</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Contact Center">Contact Center</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                {moment(el.createdAt).format('DD.MM.YYYY')}
              </div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={tableStyles.selectStyle}
                  value={el.leadStatus}
                  onChange={(event) =>
                    handleSelectTag('leadStatus', event.target.value, el)
                  }
                >
                  <option value="underProcess">Under Process</option>
                  <option value="onGoing">On Going</option>
                  <option value="Lost">Lost</option>
                  <option value="Converted">Converted</option>
                </select>
              </div>
              <div style={tableStyles.contentRowText}>{el.location}</div>
              <div style={tableStyles.contentRowText}>
                <select
                  style={tableStyles.selectStyle}
                  value={el.treatmentSegment}
                  onChange={(event) =>
                    handleSelectTag('treatmentSegment', event.target.value, el)
                  }
                >
                  {treatments.map((segment, ind) => {
                    return (
                      <option value={segment.title} key={ind}>
                        {segment.title}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div style={tableStyles.contentRowText}>
                {' '}
                <select
                  style={tableStyles.selectStyle}
                  value={el.treatmentCategory}
                  onChange={(event) =>
                    handleSelectTag('treatmentCategory', event.target.value, el)
                  }
                >
                  <option value="OPD">OPD</option>
                  <option value="IPD">IPD</option>
                </select>
              </div>
              <div
                style={{
                  ...tableStyles.contentRowText,
                  width: '4%',
                  borderRight: '1px solid white',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(`/pi/${el.pID}?tab=1`, {
                    state: { sentFrom: location },
                  })
                }
              >
                <img
                  src="/contents/icons/PROFILE.png"
                  style={{ height: '1.4rem' }}
                  alt=""
                />
                <p style={{ margin: 0, color: '#275058', fontSize: '.7rem' }}>
                  PROFILE
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        {' '}
        <Content
          style={{
            // margin: '0 16px',
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          {loading && (
            <div className="overlay">
              <div style={{ textAlign: 'center', marginTop: '9rem' }}>
                <Spin size="large" />
              </div>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={{
                marginTop: 20,
                borderRadius: 25,
                width: 70,
                fontSize: '.8rem',
                padding: 0,
                textTransform: 'uppercase',
                border: '1px solid #275058',
              }}
              onClick={() => navigate('/dashboard')}
            >
              Back{' '}
            </Button>
            <Button
              type="primary"
              style={{
                marginTop: 20,
                borderRadius: 25,
                width: 150,
                letterSpacing: '1px',
                fontSize: '1rem',
                padding: 0,
              }}
              onClick={() => navigate('/createLead')}
            >
              Create Lead{' '}
            </Button>
          </div>
          <div style={styles.tabNSearchRow}>
            <div style={styles.tabTextContainer}>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'allLeads'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('allLeads')}
              >
                All Leads
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'Website' ? styles.tabTextHighlight : {}),
                }}
                onClick={() => handleSelectedTabClick('Website')}
              >
                Website Leads
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'Facebook'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('Facebook')}
              >
                Facebook Leads
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'contactCenter'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('contactCenter')}
              >
                Contact Center Leads
              </p>
            </div>
            <Radio.Group
              style={{ marginLeft: 20 }}
              size="small"
              options={options}
              onChange={dateTypeController}
              value={dateType}
              optionType="button"
              buttonStyle="outline"
            />

            <div>
              <Input
                placeholder="Search Patient"
                onChange={onSearch}
                allowClear
                style={{
                  width: 200,
                  borderRadius: 25,
                  border: '1px solid #275058',
                }}
              />
            </div>
          </div>
          {generateTable()}

          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination
              defaultCurrent={1}
              defaultPageSize={10}
              total={totalLeads}
              current={page}
              onChange={(p) => setPage(p)}
              showSizeChanger={false}
            />
          </div>
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  tabNSearchRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  tabTextContainer: {
    display: 'flex',
    gap: 10,
  },
  tabText: {
    textTransform: 'uppercase',
    color: '#275058',
    margin: 0,
    cursor: 'pointer',
  },
  tabTextHighlight: {
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
  },
}

export default AllLeads
