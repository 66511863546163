import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Alert,
  Button,
  Input,
  Select,
  Upload,
  message,
  Modal,
  Spin,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import Navbar from '../components/Navbar'
import MySider from '../components/MySider'
import axiosInstance from '../utils/axiosInstance'
import { data as treatments } from '../constant/treatments'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

const HeadingsText = styled.h1`
  display: inline-block;
  color: #275058;
  // text-decoration: underline;
  border-bottom: 1px solid #275058;
  font-size: 2.5rem;
  @media (max-width: 500px) {
    //
  }
`

const LabelText = styled.p`
  color: #275058;
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
`

function CreateLead() {
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal()

  const countDown = () => {
    let secondsToGo = 2
    const instance = modal.success({
      title: 'Lead created successfully!',
      content: ``,
      onOk: () => navigate('/allLeads'),
    })
    const timer = setInterval(() => {
      secondsToGo -= 1
      // instance.update({
      //   content: `This modal will be destroyed after ${secondsToGo} second.`,
      // })
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
      instance.destroy()
      navigate('/allLeads')
    }, secondsToGo * 1000)
  }

  const [validationText, setValidationText] = useState('')
  const [name, setName] = useState('')
  const [age, setAge] = useState('')
  const [gender, setGender] = useState('')
  const [location, setLocation] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [leadSource, setLeadSource] = useState('')
  const [preferredDestination, setPreferredDestination] = useState('')
  const [treatmentSegment, setTreatmentSegment] = useState('')
  const [treatmentCategory, setTreatmentCategory] = useState('')
  const [notes, setNotes] = useState('')
  const [reportsFileList, setReportsFileList] = useState([])
  const [passportFileList, setPassportFileList] = useState([])

  const [loading, setLoading] = useState(false)

  const reportUploadProps = {
    name: 'file',
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setReportsFileList(info.fileList)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }
  const passportUploadProps = {
    name: 'file',
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      setPassportFileList(info.fileList)

      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`)
        console.log(info)
      }
    },
    customRequest(info) {
      // console.log(info)
      const { onProgress, onSuccess } = info
      onProgress({ percent: 100 })
      onSuccess('Ok')
    },
  }

  const validateLeadData = () => {
    setValidationText('')
    const missingFields = []

    // Check if mandatory fields are empty, null, or undefined
    if (!name) missingFields.push('Name')
    if (!age) missingFields.push('Age')
    if (!gender) missingFields.push('Gender')
    if (!location) missingFields.push('Location')
    if (!contact) missingFields.push('Contact')
    if (!leadSource) missingFields.push('Lead Source')
    if (!preferredDestination) missingFields.push('Preferred Destination')
    if (!treatmentSegment) missingFields.push('Treatment Segment')
    if (!treatmentCategory) missingFields.push('Treatment Category')
    if (reportsFileList.length === 0) missingFields.push('Report')
    if (passportFileList.length === 0) missingFields.push('Passport')

    return missingFields
  }

  const onCreateLead = async () => {
    //

    try {
      const missingFields = validateLeadData()

      if (missingFields.length > 0) {
        console.log(`${missingFields.join(', ')} missing`)
        setValidationText(` ${missingFields.join(', ')} missing`)
        return // Don't proceed with the request
      }

      // showError(false)
      setLoading(true)

      const formData = new FormData()

      // Add your fields to the FormData object
      formData.append('name', name)
      formData.append('age', age)
      formData.append('gender', gender)
      formData.append('location', location)
      formData.append('email', email)
      formData.append('contact', contact)
      formData.append('leadSource', leadSource)
      formData.append('preferredDestination', preferredDestination)
      formData.append('treatmentSegment', treatmentSegment)
      formData.append('treatmentCategory', treatmentCategory)
      formData.append('notes', notes)

      for (let i = 0; i < reportsFileList.length; i++) {
        formData.append('reportsFileList', reportsFileList[i].originFileObj)
      }

      for (let i = 0; i < passportFileList.length; i++) {
        formData.append('passportFileList', passportFileList[i].originFileObj)
      }

      try {
        const response = await axiosInstance.post('/cm/createLead', formData, {
          headers: {}, // not sure if its working or not
        })
        console.log(response.data)

        countDown() // showing success modal
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const inputStyle = {
    padding: '12px',
    borderRadius: '26px',
    display: 'inline-block',
    border: '1px solid #275058',
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        {' '}
        <Content
          style={{
            // margin: '0 16px',
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          <div>
            <HeadingsText>LEAD DETAILS</HeadingsText>
          </div>
          {/* <Button onClick={countDown}>3s test</Button> */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid white',
              marginBottom: 50,
            }}
          >
            <div style={{ width: '40%' }}>
              <LabelText>*Name</LabelText>
              <Input
                style={{ ...inputStyle }}
                // placeholder="Name"
                value={name}
                onChange={({ target: { value } }) => setName(value)}
              />
            </div>

            <div style={{ width: '10%' }}>
              <LabelText>*Age (YEARS)</LabelText>
              <Input
                style={{ ...inputStyle }}
                // placeholder="Age"
                value={age}
                onChange={({ target: { value } }) => setAge(value)}
              />
            </div>

            <div style={{ width: '10%' }}>
              <LabelText>*GENDER</LabelText>
              <Select
                style={{
                  ...inputStyle,
                  padding: 5,
                  overflow: 'hidden',
                  width: '100%',
                }}
                // placeholder="gender"
                // onChange={onGenderChange}
                onChange={(value) => setGender(value)}
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </div>

            <div style={{ width: '30%' }}>
              <LabelText>*Location</LabelText>
              <Input
                // placeholder="Location"
                value={location}
                style={{ ...inputStyle }}
                onChange={({ target: { value } }) => setLocation(value)}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid white',
              marginBottom: 50,
            }}
          >
            <div style={{ width: '30%' }}>
              <LabelText>Email ID</LabelText>
              <Input
                // placeholder="Email"
                style={{ ...inputStyle }}
                value={email}
                onChange={({ target: { value } }) => setEmail(value)}
              />
            </div>

            <div style={{ width: '20%' }}>
              <LabelText>*Contact Number (Whatsapp)</LabelText>
              <Input
                // placeholder="Contact"
                style={{ ...inputStyle }}
                value={contact}
                onChange={({ target: { value } }) => setContact(value)}
              />
            </div>

            <div style={{ width: '20%' }}>
              <LabelText>*Medical Report</LabelText>
              <Upload {...reportUploadProps}>
                <Button
                  type="primary"
                  style={{
                    borderRadius: 25,
                    textTransform: 'uppercase',
                    height: 50,
                    width: 250,
                    fontSize: '1.2rem',
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </div>
            <div style={{ width: '20%' }}>
              <LabelText>*Passport</LabelText>
              <Upload {...passportUploadProps}>
                <Button
                  type="primary"
                  style={{
                    borderRadius: 25,
                    textTransform: 'uppercase',
                    height: 50,
                    width: 250,
                    fontSize: '1.2rem',
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>

          <div>
            <HeadingsText>SOURCE</HeadingsText>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid white',
              marginBottom: 20,
            }}
          >
            <div style={{ width: '20%' }}>
              <LabelText>*Lead Source</LabelText>
              <Select
                style={{
                  ...inputStyle,
                  padding: 5,
                  overflow: 'hidden',
                  width: '100%',
                }}
                // onChange={onGenderChange}
                onChange={(value) => setLeadSource(value)}
              >
                <Option value="Website">Website</Option>
                <Option value="Facebook">Facebook</Option>
                <Option value="Contact Center">Contact Center</Option>
              </Select>
            </div>

            <div style={{ width: '25%' }}>
              <LabelText>*Prefered Treatment Destination</LabelText>
              <Input
                // placeholder="Prefered Destination"
                style={{ ...inputStyle }}
                value={preferredDestination}
                onChange={({ target: { value } }) =>
                  setPreferredDestination(value)
                }
              />
            </div>

            <div style={{ width: '20%' }}>
              <LabelText>*Treatment Segment</LabelText>
              <Select
                // placeholder="Treatment Segment"
                // onChange={onGenderChange}
                style={{
                  ...inputStyle,
                  padding: 5,
                  overflow: 'hidden',
                  width: '100%',
                }}
                onChange={(value) => setTreatmentSegment(value)}
              >
                {treatments.map((segment, ind) => {
                  return (
                    <Option value={segment.title} key={ind}>
                      {segment.title}
                    </Option>
                  )
                })}
              </Select>
            </div>

            <div style={{ width: '20%' }}>
              <LabelText>*Treatment Category</LabelText>
              <Select
                // placeholder="Treatment Category"
                // onChange={onGenderChange}
                // style={{ width: '300px' }}
                style={{
                  ...inputStyle,
                  padding: 5,
                  overflow: 'hidden',
                  width: '100%',
                }}
                onChange={(value) => setTreatmentCategory(value)}
              >
                <Option value="OPD">OPD</Option>
                <Option value="IPD">IPD</Option>
              </Select>
            </div>
          </div>

          <div>
            <LabelText>Notes</LabelText>
            <Input
              // placeholder="Notes"
              style={{
                ...inputStyle,
                height: '5rem',
                width: '70%',
                marginBottom: 20,
              }}
              value={notes}
              onChange={({ target: { value } }) => setNotes(value)}
            />
          </div>

          {validationText && (
            <div style={{}}>
              <Alert message={validationText} type="error" />
            </div>
          )}

          {!loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 20,
                marginTop: 20,
              }}
            >
              <Button
                type="primary"
                style={{
                  marginTop: 20,
                  borderRadius: 25,
                  width: 200,
                  letterSpacing: '1px',
                  fontSize: '1.2rem',
                  padding: 0,
                  height: '3rem',
                }}
                onClick={() => onCreateLead()}
              >
                Create{' '}
              </Button>
              <Button
                type="primary"
                style={{
                  marginTop: 20,
                  borderRadius: 25,
                  width: 200,
                  letterSpacing: '1px',
                  fontSize: '1.2rem',
                  padding: 0,
                  height: '3rem',
                }}
                onClick={() => navigate('/allLeads')}
              >
                Cancel{' '}
              </Button>
            </div>
          ) : (
            <>
              <Spin style={{ marginTop: '2rem' }} size="large" />
            </>
          )}
        </Content>
      </Layout>
    </>
  )
}

export default CreateLead
