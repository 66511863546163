import React from 'react'
import styled from 'styled-components'

const data = [
  {
    img: '/contents/icons/b-1.png',
    title: 'Patient Connected',
    subTitle: '6000+',
  },
  {
    img: '/contents/icons/b-2.png',
    title: 'Hospital Partner',
    subTitle: '120+',
  },
  {
    img: '/contents/icons/b-3.png',
    title: 'Doctors Network',
    subTitle: '400+',
  },
  {
    img: '/contents/icons/b-4.png',
    title: 'Treatments Offered',
    subTitle: '300+',
  },
]

function CapabilityBanner() {
  const InnerContainer = styled.div`
    height: 100%;
    box-shadow: inset 0px 3px 31px #b7b7b729;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    padding-bottom: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    // border: 1px solid black;
    @media only screen and (min-width: 1536px) {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 0px;
    }
  `

  const Card = ({ data }) => {
    return (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={data.img} alt="" width={100} />
        </div>
        <div
          style={{
            marginLeft: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: 20,
              color: '#275058',
              fontWeight: 'bold',
              margin: 0,
            }}
          >
            {data.title}
          </p>
          <p
            style={{
              fontSize: 53,
              color: '#275058',
              fontWeight: 'bold',
              margin: 0,
            }}
          >
            {data.subTitle}
          </p>
        </div>
      </div>
    )
  }
  return (
    <div style={styles.bannerStyle}>
      <InnerContainer>
        {data.map((el, idx) => {
          return <Card key={idx} data={el} />
        })}
      </InnerContainer>
    </div>
  )
}

const styles = {
  bannerStyle: {
    marginTop: 40,
    marginBottom: 40,
    boxShadow: '0px 0px 13px #00000029', // copied from xd
    border: '1px solid white',
    borderRadius: 100,
    overflow: 'hidden',
  },
}

export default CapabilityBanner
