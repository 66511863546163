import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../../../components/Navbar'
import MeetingOverviewCard from './MeetingOverviewCard'

import axiosInstance from '../../../../utils/axiosInstance'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

function MeetingReqForm() {
  const [leadInfo, setLeadInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [leaveEntries, seMeetingEntries] = useState([])
  const auth = useSelector((state) => state.auth)

  const handleSelectTag = async (value, key, _id) => {
    //

    console.log(value)
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateMeetingEntry?ID=${_id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'DATE',
      render: (text, record, index) => (
        <span>{record.meetingDate}</span>
      ),
      key: 'DATE',
      align: 'center',
    },
    {
      title: 'ORGANIZATION',
      dataIndex: 'organization',
      key: 'organization',
      align: 'center',
    },
    {
      title: 'CONCERNED PERSON',
      dataIndex: 'concernedPersonDeparment',
      key: 'concernedPersonDeparment',
      align: 'center',
    },
    {
      title: 'CONTACT',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      align: 'center',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: 'PURPOSE',
      dataIndex: 'purposeOfMeeting',
      key: 'purposeOfMeeting',
      align: 'center',
    },
    {
      title: 'MEETING CONDUCTED',
      // dataIndex: 'meetingConductedStatus',
      render: (text, record, index) => {
        // return <span>{record.status}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.meetingConductedStatus}
            onChange={(event) => {
              handleSelectTag(
                event.target.value,
                'meetingConductedStatus',
                record._id
              )
            }}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        )
      },
      key: 'meetingConductedStatus',
      align: 'center',
    },
  ]

  const getMeetingEntryOfUser = async () => {
    // setLoading(true)

    let pathString = `/cm/getMeetingEntryOfUser`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        seMeetingEntries(response.data.meetingEntries)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMeetingEntryOfUser()
  }, [])

  return (
    <div>
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            // margin: '0 16px',
            padding: '30px 16px',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{}}>
              <MeetingOverviewCard leadInfo={leadInfo} />
            </div>
          </div>
          <h2 style={{ color: '#275058', textDecoration: 'underline' }}>
            MEETING SUMMARY
          </h2>
          <Table
            dataSource={leaveEntries}
            columns={columns.map((each) => ({
              ...each,
              className: 'maxWidthCell',
            }))}
            bordered
            pagination={false}
          />
        </Content>
      </Layout>
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default MeetingReqForm
