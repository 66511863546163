import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'

import { leadSlice } from '../store/auth/leadSlice'
import { fetchAllLeads } from '../store/auth/leadSlice'

import Auth from './auth/Auth'
import Home from '../pages/Home'
import About from '../pages/About'
import Login from '../pages/Login.js'
import CreateLead from '../pages/CreateLead.js'
import AllLeads from '../pages/AllLeads.js'
import PatientInformation from '../pages/PatientInformation.js'
import LeadRequest from '../pages/LeadRequest.js'
import TeleRequest from '../pages/TeleRequest.js'
import QueryRequests from '../pages/QueryRequests.js'
import AssignedLeads from '../pages/AssignedLeads.js'
import Notifications from '../pages/Notifications.js'

import AddHospital from '../pages/cms/AddHospital.js'
import EditHospital from '../pages/cms/EditHospital.js'
import AddDoctor from '../pages/cms/AddDoctor.js'
import EditDoctor from '../pages/cms/EditDoctor.js'
import AddBlog from '../pages/cms/AddBlog.js'
import EditBlog from '../pages/cms/EditBlog.js'
import AddService from '../pages/cms/AddService.js'
import EditService from '../pages/cms/EditService.js'
import AddTreatment from '../pages/cms/AddTreatment.js'
import EditTreatment from '../pages/cms/EditTreatment.js'
import EditSubSegment from '../pages/cms/EditSubSegment.js'
import AddBanners from '../pages/cms/AddBanners.js'

import LeaveForm from '../pages/manageOffice/cm/leaveForm/LeaveForm.js'
import MeetingReqForm from '../pages/manageOffice/cm/meetingReqForm/MeetingReqForm.js'
import SalaryReceived from '../pages/manageOffice/cm/salaryReceived/SalaryReceived.js'
import IncentivesForm from '../pages/manageOffice/cm/incentives/IncentivesForm.js'
import OfficeRequirement from '../pages/manageOffice/cm/officeRequirement/OfficeRequirement.js'
import MyAttendanceSummary from '../pages/manageOffice/cm/attendanceSummary/MyAttendanceSummary.js'
import ReqForm from '../pages/manageOffice/cm/specialRequest/ReqForm.js'
import EachCm from '../pages/manageOffice/su/eachCm/EachCm.js'

import ReportReceived from '../pages/su/ReportReceived.js'
import PassportReceived from '../pages/su/PassportReceived.js'
import TotalPatients from '../pages/su/TotalPatients.js'
import LeadStatus from '../pages/su/LeadStatus.js'
import TeleMedicine from '../pages/su/TeleMedicine.js'
import VisaVil from '../pages/su/VisaVil.js'
import ServicePackage from '../pages/su/ServicePackage.js'
import FlyOut from '../pages/su/FlyOut.js'
import PatientDischarged from '../pages/su/PatientDischarged.js'

function R() {
  const dispatch = useDispatch()
  const leads = useSelector((state) => state.leads)
  const { getAllLeads } = leadSlice.actions

  useEffect(() => {
    // console.log(leads)
    // dispatch(fetchAllLeads()) // Don't ever call it here, causing infinite loading
  }, [])

  if (leads?.globalLoading)
    return (
      <div className="overlay">
        <div style={{ textAlign: 'center', marginTop: '9rem' }}>
          <Spin size="large" />
        </div>
      </div>
    )

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth children={<Outlet />} />}>
          <Route index element={<Login />} />
          <Route path="dashboard" element={<Home />}>
            {/* <Route index element={<Home />} /> */}
          </Route>

          <Route path="createLead" element={<CreateLead />} />
          <Route path="allLeads" element={<AllLeads />} />
          <Route path="pi/:id" element={<PatientInformation />} />
          <Route path="leadRequest" element={<LeadRequest />} />
          <Route path="teleRequest" element={<TeleRequest />} />
          <Route path="queryRequests" element={<QueryRequests />} />
          <Route path="assignedLeads" element={<AssignedLeads />} />
          <Route path="notifications" element={<Notifications />} />

          <Route path="addHospital" element={<AddHospital />} />
          <Route path="editHospital" element={<EditHospital />} />
          <Route path="addDoctor" element={<AddDoctor />} />
          <Route path="editDoctor" element={<EditDoctor />} />
          <Route path="addBlog" element={<AddBlog />} />
          <Route path="editBlog" element={<EditBlog />} />
          <Route path="addService" element={<AddService />} />
          <Route path="editService" element={<EditService />} />
          <Route path="addTreatment" element={<AddTreatment />} />
          <Route path="editTreatment" element={<EditTreatment />} />
          <Route path="editSubSegment" element={<EditSubSegment />} />
          <Route path="addBanners" element={<AddBanners />} />

          <Route path="leaveForm" element={<LeaveForm />} />
          <Route path="meetingReqForm" element={<MeetingReqForm />} />
          <Route path="salaryReceived" element={<SalaryReceived />} />
          <Route path="incentivesForm" element={<IncentivesForm />} />
          <Route path="officeRequirement" element={<OfficeRequirement />} />
          <Route path="attendanceSummary" element={<MyAttendanceSummary />} />
          <Route path="special-Request" element={<ReqForm />} />

          <Route path="cm/:id" element={<EachCm />} />

          <Route path="reportReceived" element={<ReportReceived />} />
          <Route path="PassportReceived" element={<PassportReceived />} />
          <Route path="totalPatients" element={<TotalPatients />} />
          <Route path="leadStatus" element={<LeadStatus />} />
          <Route path="teleMedicine" element={<TeleMedicine />} />
          <Route path="visaVil" element={<VisaVil />} />
          <Route path="servicePackage" element={<ServicePackage />} />
          <Route path="flyOut" element={<FlyOut />} />
          <Route path="patientDischarged" element={<PatientDischarged />} />
        </Route>
        <Route exact path="/about" element={<About />} />
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  )
}

export default R
