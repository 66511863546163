import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../../../utils/axiosInstance'

function Salary({ leadInfo }) {
  const [loading, setLoading] = useState(false)
  const [leaveEntries, seMeetingEntries] = useState([])
  const [formData, setFormData] = useState({})

  const onSave = async () => {
    // console.log(formData)
    // return
    try {
      const response = await axiosInstance.put(`/su/updateSalaryEntry`, {
        formData,
      })
      console.log(response.data)
      // setAllLeads(response.data.allLeads)
      message.success(`Successfully Updated!`)
      setLoading(false)
    } catch (error) {
      console.log('kamal')
      console.log(error)
      console.error('Request Error:', error)
    }
  }

  const handleSelectTag = async (value, key, _id) => {
    //
    // console.log(value)
    // console.log({
    //   formData: {
    //     [_id]: { [key]: value },
    //   },
    // })
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(`/su/updateSalaryEntry`, {
          formData: {
            [_id]: { [key]: value },
          },
        })
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'SL',
      // dataIndex: 'serial',
      render: (text, record, index) => <span>{index + 1}</span>,
      key: 'serial',
      align: 'center',
    },
    {
      title: 'MONTH',
      dataIndex: 'month',
      key: 'month',
      align: 'center',
    },
    {
      title: 'RELEASED DATE',
      render: (text, record, index) => (
        <>
          <Input
            style={styles.invisibleInput}
            defaultValue={record.releasedDate}
            // value={formData.name}
            onChange={(e) => {
              setFormData((prev) => {
                return {
                  ...prev,
                  [record._id]: {
                    ...prev[record._id],
                    releasedDate: e.target.value,
                  },
                }
              })
            }}
          />
        </>
      ),
      key: 'releasedDate',
      align: 'center',
    },
    {
      title: 'RELEASED TIME',
      render: (text, record, index) => (
        <>
          <Input
            style={styles.invisibleInput}
            defaultValue={record.releasedTime}
            // value={formData.name}
            onChange={(e) => {
              setFormData((prev) => {
                return {
                  ...prev,
                  [record._id]: {
                    ...prev[record._id],
                    releasedTime: e.target.value,
                  },
                }
              })
            }}
          />
        </>
      ),
      key: 'releasedTime',
      align: 'center',
    },
    {
      title: 'MEDIUM',
      dataIndex: 'medium',
      key: 'medium',
      align: 'center',
    },
    {
      title: 'ACCOUNT NUMBER',
      dataIndex: 'accNo',
      key: 'accNo',
      align: 'center',
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: 'DEDUCTION',
      render: (text, record, index) => (
        <>
          <Input
            style={styles.invisibleInput}
            defaultValue={record.deductionAmount}
            // value={formData.name}
            onChange={(e) => {
              setFormData((prev) => {
                return {
                  ...prev,
                  [record._id]: {
                    ...prev[record._id],
                    deductionAmount: e.target.value,
                  },
                }
              })
            }}
          />
        </>
      ),
      key: 'deductionAmount',
      align: 'center',
    },
    {
      title: 'REASON FOR DEDUCTION',
      render: (text, record, index) => {
        // return <span>{record.status}</span>
        return (
          <select
            style={styles.selectStyle}
            defaultValue={record.deductionReason}
            onChange={(event) => {
              handleSelectTag(event.target.value, 'deductionReason', record._id)
            }}
          >
            <option value=""></option>
            <option value="Late Attendance">Late Attendance</option>
            <option value="Unpaid Leave">Unpaid Leave</option>
          </select>
        )
      },
      key: 'deductionReason',
      align: 'center',
    },
    {
      title: 'TA/DA AMOUNT',
      render: (text, record, index) => (
        <>
          <Input
            style={styles.invisibleInput}
            defaultValue={record.taDaAmount}
            // value={formData.name}
            onChange={(e) => {
              setFormData((prev) => {
                return {
                  ...prev,
                  [record._id]: {
                    ...prev[record._id],
                    taDaAmount: e.target.value,
                  },
                }
              })
            }}
          />
        </>
      ),
      key: 'taDaAmount',
      align: 'center',
    },
    {
      title: 'NEXT INCREMENT DATE',
      render: (text, record, index) => (
        <>
          <Input
            style={styles.invisibleInput}
            defaultValue={record.nextIncrementDate}
            // value={formData.name}
            onChange={(e) => {
              setFormData((prev) => {
                return {
                  ...prev,
                  [record._id]: {
                    ...prev[record._id],
                    nextIncrementDate: e.target.value,
                  },
                }
              })
            }}
          />
        </>
      ),
      key: 'nextIncrementDate',
      align: 'center',
    },
    {
      title: 'INCREMENT AMOUNT',
      render: (text, record, index) => (
        <>
          <Input
            style={styles.invisibleInput}
            defaultValue={record.incrementAmount}
            // value={formData.name}
            onChange={(e) => {
              setFormData((prev) => {
                return {
                  ...prev,
                  [record._id]: {
                    ...prev[record._id],
                    incrementAmount: e.target.value,
                  },
                }
              })
            }}
          />
        </>
      ),
      key: 'incrementAmount',
      align: 'center',
    },
  ]

  const getSalaryEntryOfAUser = async () => {
    // setLoading(true)

    let pathString = `/su/getSalaryEntryOfAUser?id=${leadInfo._id}`

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)

        seMeetingEntries(response.data.results)
        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSalaryEntryOfAUser()
  }, [])

  return (
    <div>
      {' '}
      <br />{' '}
      <p
        style={{
          display: 'inline-block',
          padding: 5,
          border: '1px solid #275058',
          color: '#275058',
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          cursor: 'pointer',
        }}
        onClick={onSave}
      >
        Update
      </p>
      <Table
        dataSource={leaveEntries}
        columns={columns.map((each) => ({
          ...each,
          className: 'maxWidthCell',
        }))}
        bordered
        pagination={false}
      />
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    // backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}

export default Salary
