import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Modal, Popconfirm, Input, message, Tooltip } from 'antd'
import {
  CloseCircleOutlined,
  FileOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'

import axiosInstance from '../../../utils/axiosInstance'
import getExtensionFromMimeType from '../../../utils/getExtensionFromMimeType'

function Summary({ leadInfo }) {
  const { summary } = leadInfo

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [formData, setFormData] = useState({ ...summary })
  const [loading, setLoading] = useState({})

  const handleSelectTag = async () => {
    console.log(formData)
    // return
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${leadInfo._id}`,
          {
            summary: formData.summary,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)
        message.success(`Successfully Updated!`)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <div style={{ padding: 20, border: '1px solid white' }}>
      <p
        style={{
          display: 'inline-block',
          padding: 5,
          border: '1px solid #275058',
          color: '#275058',
          fontWeight: 'bold',
          textUnderlineOffset: '0.25rem',
          cursor: 'pointer',
        }}
        onClick={handleSelectTag}
      >
        Update
      </p>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ width: 300 }}>
            <p
              style={{
                textAlign: 'center',
                color: '#275058',
                fontSize: '1.2rem',
                margin: 0,
                marginBottom: 10,
              }}
            >
              HOSPITALS VISITED{' '}
              <Tooltip
                color="red"
                title="Please click 'Update' after adding one Hospital"
              >
                <InfoCircleOutlined />
              </Tooltip>
            </p>
            <div
              style={{
                border: '1px solid #275058',
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              <div
                style={{
                  borderBottom: `1px solid #275058`,
                  display: 'flex',
                  padding: 3,
                }}
              >
                <span>1.</span>
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={
                    (summary.hospitalVisited && summary.hospitalVisited[0]) ||
                    ''
                  }
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      const updatedHospitalVisited = [
                        ...summary.hospitalVisited,
                      ]
                      updatedHospitalVisited[0] = e.target.value
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev.summary,
                          hospitalVisited: updatedHospitalVisited,
                        },
                      }
                    })
                  }}
                />
              </div>
              <div
                style={{
                  borderBottom: `1px solid #275058`,
                  display: 'flex',
                  padding: 3,
                }}
              >
                <span>2.</span>{' '}
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={
                    (summary.hospitalVisited && summary.hospitalVisited[1]) ||
                    ''
                  }
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      const updatedHospitalVisited = [
                        ...summary.hospitalVisited,
                      ]
                      updatedHospitalVisited[1] = e.target.value
                      return {
                        ...prev,
                        summary: {
                          ...summary,

                          ...prev.summary,
                          hospitalVisited: updatedHospitalVisited,
                        },
                      }
                    })
                  }}
                />
              </div>
              <div
                style={{
                  borderBottom: `1px solid #275058`,
                  display: 'flex',
                  padding: 3,
                }}
              >
                <span>3.</span>{' '}
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={
                    (summary.hospitalVisited && summary.hospitalVisited[2]) ||
                    ''
                  }
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      const updatedHospitalVisited = [
                        ...summary.hospitalVisited,
                      ]
                      updatedHospitalVisited[2] = e.target.value
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev.summary,
                          hospitalVisited: updatedHospitalVisited,
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: 300 }}>
            <p
              style={{
                textAlign: 'center',
                color: '#275058',
                fontSize: '1.2rem',
                margin: 0,
                marginBottom: 10,
              }}
            >
              Total Bill Amount{' '}
            </p>
            <div
              style={{
                border: '1px solid #275058',
                boxShadow: 'inset 0px 3px 3px #00000029',
                height: 120,
              }}
            >
              <div
                style={{
                  padding: 3,
                }}
              >
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={summary.totalBillAmount}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev['summary'],
                          totalBillAmount: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: 300 }}>
            <p
              style={{
                textAlign: 'center',
                color: '#275058',
                fontSize: '1.2rem',
                margin: 0,
                marginBottom: 10,
              }}
            >
              LENGTH OF STAY{' '}
            </p>
            <div
              style={{
                border: '1px solid #275058',
                boxShadow: 'inset 0px 3px 3px #00000029',
                height: 120,
              }}
            >
              <div
                style={{
                  padding: 3,
                }}
              >
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={summary.lengthOfStay}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        summary: {
                          ...summary,

                          ...prev['summary'],
                          lengthOfStay: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: 300 }}>
            <p
              style={{
                textAlign: 'center',
                color: '#275058',
                fontSize: '1.2rem',
                margin: 0,
                marginBottom: 10,
              }}
            >
              OFFICE VISITED{' '}
            </p>
            <div
              style={{
                border: '1px solid #275058',
                boxShadow: 'inset 0px 3px 3px #00000029',
                height: 120,
              }}
            >
              <div
                style={{
                  padding: 3,
                }}
              >
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={summary.officeVisited}
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev['summary'],
                          officeVisited: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ width: 300 }}>
            <p
              style={{
                textAlign: 'center',
                color: '#275058',
                fontSize: '1.2rem',
                margin: 0,
                marginBottom: 10,
              }}
            >
              DOCTORS CONSULTED{' '}
              <Tooltip
                color="red"
                title="Please click 'Update' after adding one Doctor"
              >
                <InfoCircleOutlined />
              </Tooltip>
            </p>
            <div
              style={{
                border: '1px solid #275058',
                boxShadow: 'inset 0px 3px 3px #00000029',
              }}
            >
              <div
                style={{
                  borderBottom: `1px solid #275058`,
                  display: 'flex',
                  padding: 3,
                }}
              >
                <span>1.</span>
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={
                    (summary.docConsulted && summary.docConsulted[0]) || ''
                  }
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      const updateDocConsulted = [...summary.docConsulted]
                      updateDocConsulted[0] = e.target.value
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev.summary,
                          docConsulted: updateDocConsulted,
                        },
                      }
                    })
                  }}
                />
              </div>
              <div
                style={{
                  borderBottom: `1px solid #275058`,
                  display: 'flex',
                  padding: 3,
                }}
              >
                <span>2.</span>{' '}
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={
                    (summary.docConsulted && summary.docConsulted[1]) || ''
                  }
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      const updateDocConsulted = [...summary.docConsulted]
                      updateDocConsulted[1] = e.target.value
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev.summary,
                          docConsulted: updateDocConsulted,
                        },
                      }
                    })
                  }}
                />
              </div>
              <div
                style={{
                  borderBottom: `1px solid #275058`,
                  display: 'flex',
                  padding: 3,
                }}
              >
                <span>3.</span>{' '}
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={
                    (summary.docConsulted && summary.docConsulted[2]) || ''
                  }
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      const updateDocConsulted = [...summary.docConsulted]
                      updateDocConsulted[2] = e.target.value
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev.summary,
                          docConsulted: updateDocConsulted,
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: 300 }}>
            <p
              style={{
                textAlign: 'center',
                color: '#275058',
                fontSize: '1.2rem',
                margin: 0,
                marginBottom: 10,
              }}
            >
              GUIDE NAME{' '}
            </p>
            <div
              style={{
                border: '1px solid #275058',
                boxShadow: 'inset 0px 3px 3px #00000029',
                height: 120,
              }}
            >
              <div
                style={{
                  padding: 3,
                }}
              >
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={summary.guideName}
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev['summary'],
                          guideName: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: 300 }}>
            <p
              style={{
                textAlign: 'center',
                color: '#275058',
                fontSize: '1rem',
                margin: 0,
                marginBottom: 10,
              }}
            >
              SECONDARY TREATMENT SEGMENT{' '}
            </p>
            <div
              style={{
                border: '1px solid #275058',
                boxShadow: 'inset 0px 3px 3px #00000029',
                height: 120,
              }}
            >
              <div
                style={{
                  padding: 3,
                }}
              >
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={summary.secondaryTreatmentSegment}
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev['summary'],
                          secondaryTreatmentSegment: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: 300 }}>
            <p
              style={{
                textAlign: 'center',
                color: '#275058',
                fontSize: '1.2rem',
                margin: 0,
                marginBottom: 10,
              }}
            >
              PATIENT FEEDBACK{' '}
            </p>
            <div
              style={{
                border: '1px solid #275058',
                boxShadow: 'inset 0px 3px 3px #00000029',
                height: 120,
              }}
            >
              <div
                style={{
                  padding: 3,
                }}
              >
                <Input
                  style={{ ...styles.invisibleInput }}
                  defaultValue={summary.patientFeedback}
                  onChange={(e) => {
                    setFormData((prev) => {
                      console.log(prev)
                      return {
                        ...prev,
                        summary: {
                          ...summary,
                          ...prev['summary'],
                          patientFeedback: e.target.value,
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  invisibleInput: {
    display: 'inline-block',
    border: '1px solid white',
  },
  selectStyle: {
    borderRadius: '25px',
    backgroundColor: '#EBEBEB',
    color: '#275058',
    padding: 4,
    border: 'none',
    // appearance: 'none',
    paddingRight: '15px',
    fontWeight: 'bold',
    // maxWidth: 'auto',
    // width: 110,
  },
}
export default Summary
