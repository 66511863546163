import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Breadcrumb,
  Layout,
  Menu,
  DatePicker,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Table,
  Modal,
  Form,
  Spin,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Navbar from '../../../../components/Navbar'
import axiosInstance from '../../../../utils/axiosInstance'

const { Header, Content, Footer, Sider } = Layout
const { Option } = Select

function ReqForm() {
  const [leadInfo, setLeadInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [attendanceEntries, setattendanceEntries] = useState([])
  const auth = useSelector((state) => state.auth)
  const [formData, setFormData] = useState({})
  const navigate = useNavigate()

  const onFinish = async (values) => {
    // console.log('Success:', values)
    console.log(values)
    console.log(values.addingDate.format('YYYY-MM-DD'))
    const { addingDate, requestFor, details } = values
    try {
      const response = await axiosInstance.post(`/cm/newSpecialRequest`, {
        addingDate: addingDate.format('YYYY-MM-DD'),
        requestFor,
        [`details${requestFor.split(' ')[0]}`]: details,
      })
      console.log(response.data)
      // setAllLeads(response.data.allLeads)

      setLoading(false)
      navigate('/dashboard')
    } catch (error) {
      console.error('Request Error:', error)
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const rules = [
    {
      required: true,
    },
  ]

  return (
    <div>
      {' '}
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            padding: '30px 16px',
            backgroundColor: '#fff',
            color: '#275058',
          }}
        >
          <h1
            style={{
              color: '#275058',
              textDecoration: 'underline',
              textTransform: 'uppercase',
            }}
          >
            SPECIAL REQUEST FORM
          </h1>

          {/* <p
            style={{
              textTransform: 'uppercase',
            }}
          >
            REQUEST FOR
          </p> */}

          <Form
            name="basic"
            // layout="vertical"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 800,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item label="REQUEST FOR" name="requestFor" rules={rules}>
              <Select
                style={{
                  padding: '12px',
                  borderRadius: '26px',
                  display: 'inline-block',
                  border: '1px solid #275058',
                  padding: 5,
                  overflow: 'hidden',
                  width: '40%',
                }}
                // value={formData.leadSource}
                // onChange={(value) => {
                //   setFormData((prev) => {
                //     return { ...prev, leaveType: value }
                //   })
                // }}
              >
                <Option value="Early Leave">Early Leave</Option>
                <Option value="Late Entry">Late Entry</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Date" name="addingDate" rules={rules}>
              {/* <Input
                style={{
                  padding: '8px',
                  borderRadius: '26px',
                  display: 'inline-block',
                  border: '1px solid #275058',
                  width: '40%',
                }}
              /> */}
              <DatePicker
                // value={formData.name}
                style={{
                  padding: '8px',
                  borderRadius: '26px',
                  display: 'inline-block',
                  border: '1px solid #275058',
                  width: '40%',
                }}
                // onChange={(date, dateString) => {
                //   console.log(date, dateString)
                //   setFormData((prev) => {
                //     return { ...prev, leaveFrom: dateString }
                //   })
                // }}
              />
            </Form.Item>
            <Form.Item label="Details" name="details" rules={rules}>
              <Input.TextArea
                rows={8}
                style={{
                  padding: '8px',
                  borderRadius: '26px',
                  display: 'inline-block',
                  border: '1px solid #275058',
                  width: '100%',
                }}
              />
            </Form.Item>

            {loading && 'Loading...'}
            {!loading && (
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            )}
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button>Cancel</Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </div>
  )
}

export default ReqForm
