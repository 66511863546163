import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Layout,
  Button,
  Input,
  Select,
  Modal,
  Spin,
  Pagination,
  Breadcrumb,
  Radio,
  List,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
import Navbar from '../components/Navbar'
import MySider from '../components/MySider'
import axiosInstance from '../utils/axiosInstance'
import TopRowCommon from '../components/TopRowCommon'
import { data as treatments } from '../constant/treatments'
import getExtensionFromMimeType from '../utils/getExtensionFromMimeType'

const { Content } = Layout

function Notifications() {
  const navigate = useNavigate()
  const [allNotifications, setAllNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalUnreadNotif, setTotalUnreadNotif] = useState(0)

  const getUserNotifications = async () => {
    try {
      let pathString = `/cm/getUserNotifications`
      const response = await axiosInstance.get(pathString)
      setAllNotifications(response.data?.notifications)
    } catch (error) {
      console.error('Request Error:', error)
    }
  }
  const updateAllNotifications = async () => {
    try {
      let pathString = `/cm/updateAllNotifications`
      const response = await axiosInstance.put(pathString)
      console.log(response.data)
    } catch (error) {
      console.error('Request Error:', error)
    }
  }

  useEffect(() => {
    getUserNotifications()
    updateAllNotifications() // make all notification as read here
  }, [])

  return (
    <>
      <Navbar />
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Content
          style={{
            // margin: '0 16px',
            padding: '0 16px',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              //   border: '1px solid red',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ ...styles.actionText }}>Notifications</p>
            <List
              style={{ width: 600, border: '1px solid rgba(0, 0, 0, 0.16)' }}
              size="large"
              // header={<div>Header</div>}
              // footer={<div>Footer</div>}
              // bordered
              dataSource={allNotifications}
              renderItem={(item) => (
                <List.Item style={{ cursor: 'pointer' }} onClick={() => {}}>
                  <div>
                    <a
                      href={item.content?.url}
                      style={{ color: '#275058', fontSize: '1.1rem' }}
                    >
                      {item.content?.message}
                    </a>
                    <p style={{ color: '#c4c4c4', margin: 0 }}>
                      {' '}
                      {moment(item.createdAt).format('DD.MM.YYYY hh:mm A')}
                    </p>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  actionText: {
    color: '#275058',
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
    fontSize: '2rem',
    fontSize: '1.8rem',
    margin: 0,
    marginTop: 20,
    marginBottom: 20,
  },
}

export default Notifications
