export const data = [
  { name: 'Cardiac' },
  { name: 'Pulmonology' },
  { name: 'Endocrinology' },
  { name: 'ENT' },
  { name: 'Gastroenterology' },
  { name: 'Hepatology' },
  { name: 'Gastrointestinal & Bariatric' },
  { name: 'Hematology' },
]
