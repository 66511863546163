import React from 'react'
import ActionReportsPassports from './ActionReportsPassports'
import ActionTreatmentPlan from './ActionTreatmentPlan'
import ActionTeleMedicine from './ActionTeleMedicine'
import ActionVISA from './ActionVISA'
import ActionServicePackage from './ActionServicePackage'
import ActionNotes from './ActionNotes'
import ActionBills from './ActionBills'
import ActionSummary from './ActionSummary'

function ActionCard(props) {
  const { selectedTab, leadInfo } = props

  const generateJsx = () => {
    // console.log(selectedTab)
    if (selectedTab === '1') {
      return <ActionReportsPassports leadInfo={leadInfo} />
    } else if (selectedTab === '2') {
      return <ActionTreatmentPlan leadInfo={leadInfo} />
    } else if (selectedTab === '3') {
      return <ActionTeleMedicine leadInfo={leadInfo} />
    } else if (selectedTab === '4') {
      return <ActionVISA leadInfo={leadInfo} />
    } else if (selectedTab === '5') {
      return <ActionServicePackage leadInfo={leadInfo} />
    } else if (selectedTab === '6') {
      return <ActionNotes leadInfo={leadInfo} />
    } else if (selectedTab === '7') {
      return <ActionBills leadInfo={leadInfo} />
    } else if (selectedTab === '8') {
      return <ActionSummary leadInfo={leadInfo} />
    }
  }

  return <div>{generateJsx()}</div>
}

export default ActionCard
