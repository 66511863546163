import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Input,
  Select,
  Upload,
  message,
  Modal,
  Spin,
  Pagination,
} from 'antd'
import moment from 'moment'
import TopRowCommon from '../../components/TopRowCommon'
import Navbar from '../../components/Navbar'
import axiosInstance from '../../utils/axiosInstance'
import getExtensionFromMimeType from '../../utils/getExtensionFromMimeType'

const { Content } = Layout

function ServicePackage() {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  // const [allLeads, setAllLeads] = useState([])
  const [filteredLeads, setFilteredLeads] = useState([])
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [selectedTab, setSelectedTab] = useState(
    state?.selectedTab || `PACKAGE-01`
  )
  const [page, setPage] = useState(1)
  const [totalLeads, setTotalLeads] = useState(0)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalContent(null)
  }

  const handleSelectedTabClick = (value) => {
    // console.log(`handleSelectedTabClick`)
    // console.log(value)
    setPage(1)
    setSelectedTab(value)
    getAllLeads(undefined, value, 1)
  }

  const getAllLeads = async (contact, tabV, pg) => {
    const paginationPage = pg || page // if parameter available then ignore page from state

    // setLoading(true)

    const tabValue = tabV || selectedTab

    console.log(tabValue)

    let pathString
    const perPage = 10000000000000 // when doing pagination, make this to '10'

    if (tabValue === 'PACKAGE-01')
      pathString = `/cm/getAllLeads?page=${paginationPage}&perPage=${perPage}&sp=${tabValue}`
    if (tabValue === 'PACKAGE-02')
      pathString = `/cm/getAllLeads?page=${paginationPage}&perPage=${perPage}&sp=${tabValue}`
    if (tabValue === 'PACKAGE-03')
      pathString = `/cm/getAllLeads?page=${paginationPage}&perPage=${perPage}&sp=${tabValue}`
    if (tabValue === 'PACKAGE-04')
      pathString = `/cm/getAllLeads?page=${paginationPage}&perPage=${perPage}&sp=${tabValue}`

    if (contact) pathString = pathString.concat(`&contact=${contact}`)

    try {
      try {
        const response = await axiosInstance.get(pathString)
        console.log(response.data)
        console.log(response.data.totalLeads)
        console.log(response.data.allLeads.length)

        setFilteredLeads(response.data.allLeads)

        // setTotalLeads(response.data.totalLeads)

        setLoading(false)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const handleSelectTag = async (key, value, el) => {
    //
    return // becuase select tags are disabled
    console.log(key)
    console.log(value)
    setLoading(true)
    try {
      try {
        const response = await axiosInstance.put(
          `/cm/updateLead?id=${el._id}`,
          {
            [key]: value,
          }
        )
        console.log(response.data)
        // setAllLeads(response.data.allLeads)

        setLoading(false)
        getAllLeads(undefined, undefined, undefined)
      } catch (error) {
        console.error('Request Error:', error)
      }
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      // showError(true)
      setLoading(false)
    }
  }

  const onSearch = (event) => {
    // console.log(event.target.value)
    getAllLeads(event.target.value) // sending "contact parameter"
  }

  useEffect(() => {
    getAllLeads(undefined, undefined, undefined)
  }, [page])

  const generateTable = () => {
    const tableStyles = {
      container: {
        marginTop: 20,
      },
      tableHeader: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        ...(selectedTab === 'PACKAGE-01'
          ? { width: '96.1%' }
          : { width: '96.8%' }),
        ...(selectedTab === 'PACKAGE-03'
          ? { width: '97.3%' }
          : { ...(selectedTab === 'PACKAGE-04' ? { width: '97.8%' } : {}) }),
      },
      tableHeaderText: {
        width: '10%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        fontWeight: 'bold',
        fontSize: '.9rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
      },
      contentRow: {
        border: '1px solid #275058',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
      },
      contentRowText: {
        width: '11%',
        textAlign: 'center',
        // border: '1px solid blue',
        color: '#275058',
        // fontWeight: 'bold',
        fontSize: '1rem',
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: '1px solid #275058',
        overflow: 'hidden',
      },
      selectStyle: {
        borderRadius: '25px',
        backgroundColor: '#EBEBEB',
        color: '#275058',
        padding: 8,
        border: 'none',
        // appearance: 'none',
        paddingRight: '15px',
        fontWeight: 'bold',
        maxWidth: 'auto',
      },
    }

    return (
      <div style={tableStyles.container}>
        <div style={tableStyles.tableHeader}>
          <div style={{ ...tableStyles.tableHeaderText, width: '3.4%' }}>
            SL.
          </div>
          <div style={tableStyles.tableHeaderText}>Patient Name</div>
          <div style={tableStyles.tableHeaderText}>Contact</div>
          <div style={tableStyles.tableHeaderText}>EMAIL ID</div>
          <div style={tableStyles.tableHeaderText}>HOSPITAL</div>
          <div style={tableStyles.tableHeaderText}>DOCTOR</div>
          {selectedTab === `PACKAGE-01` && (
            <>
              <div style={tableStyles.tableHeaderText}>PAYMENT RECIEVED</div>
              <div style={tableStyles.tableHeaderText}>
                PAYMENT RECEIVED DATE
              </div>
              <div style={tableStyles.tableHeaderText}>
                TREATMENT PLAN DELIVERED
              </div>
              <div
                style={{
                  ...tableStyles.tableHeaderText,
                  borderRight: '1px solid white',
                }}
              >
                CALL CENTER SUPPORT
              </div>
            </>
          )}
          {selectedTab === `PACKAGE-02` && (
            <>
              <div style={tableStyles.tableHeaderText}>PAYMENT RECIEVED</div>
              <div style={tableStyles.tableHeaderText}>
                PAYMENT RECEIVED DATE
              </div>
              <div style={tableStyles.tableHeaderText}>
                TREATMENT PLAN DELIVERED
              </div>
              <div style={tableStyles.tableHeaderText}>
                NO. OF TREATMENT PLAN
              </div>
              <div style={tableStyles.tableHeaderText}>CALL CENTER SUPPORT</div>
              <div
                style={{
                  ...tableStyles.tableHeaderText,
                  borderRight: '1px solid white',
                }}
              >
                COUNSELLING SERVICE
              </div>
            </>
          )}
          {selectedTab === `PACKAGE-03` && (
            <>
              <div style={tableStyles.tableHeaderText}>PAYMENT RECIEVED</div>
              <div style={tableStyles.tableHeaderText}>
                PAYMENT RECEIVED DATE
              </div>
              <div style={tableStyles.tableHeaderText}>
                TREATMENT PLAN DELIVERED
              </div>
              <div style={tableStyles.tableHeaderText}>
                NO. OF TREATMENT PLAN
              </div>
              <div style={tableStyles.tableHeaderText}>CALL CENTER SUPPORT</div>
              <div style={tableStyles.tableHeaderText}>
                COUNSELLING SERVICE
              </div>{' '}
              <div style={tableStyles.tableHeaderText}>VISA PROCESSING</div>
              <div
                style={{
                  ...tableStyles.tableHeaderText,
                  borderRight: '1px solid white',
                }}
              >
                FLY-OUT CONFIRMED
              </div>
            </>
          )}
          {selectedTab === `PACKAGE-04` && (
            <>
              <div style={tableStyles.tableHeaderText}>PAYMENT RECIEVED</div>
              <div style={tableStyles.tableHeaderText}>
                PAYMENT RECEIVED DATE
              </div>
              <div style={tableStyles.tableHeaderText}>
                TREATMENT PLAN DELIVERED
              </div>
              <div style={tableStyles.tableHeaderText}>
                NO. OF TREATMENT PLAN
              </div>
              <div style={tableStyles.tableHeaderText}>CALL CENTER SUPPORT</div>
              <div style={tableStyles.tableHeaderText}>COUNSELLING SERVICE</div>
              <div style={tableStyles.tableHeaderText}>VISA PROCESSING</div>
              <div style={tableStyles.tableHeaderText}>AIRPORT TRANSFER</div>
              <div style={tableStyles.tableHeaderText}>GUIDE SERVICE</div>
              <div style={tableStyles.tableHeaderText}>FLY-OUT CONFIRMED</div>
              <div
                style={{
                  ...tableStyles.tableHeaderText,
                  borderRight: '1px solid white',
                }}
              >
                FLY-OUT DATE
              </div>
            </>
          )}
        </div>
        {filteredLeads.map((el, idx) => {
          const skip = (page - 1) * 10 // to show the serial properly after page one
          // console.log(skip)
          return (
            <div style={tableStyles.contentRow}>
              <div style={{ ...tableStyles.contentRowText, width: '3.3%' }}>
                {idx + 1 + skip}.
              </div>
              <div style={tableStyles.contentRowText}>{el.name}</div>
              <div style={tableStyles.contentRowText}>{el.contact}</div>
              <div style={tableStyles.contentRowText}>{el.email}</div>
              {selectedTab === `PACKAGE-01` && (
                <>
                  <div style={tableStyles.contentRowText}>
                    {' '}
                    {el.treatmentPlan &&
                      el.treatmentPlan.length > 0 &&
                      el.treatmentPlan[0].hospitalName}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {el.treatmentPlan &&
                      el.treatmentPlan.length > 0 &&
                      el.treatmentPlan[0].doctorName}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    **PAYMENT RECEIVED**
                  </div>
                  <div style={tableStyles.contentRowText}>
                    **PAYMENT RECEIVED DATE**
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.treatmentPlanDelivered} //
                      onChange={(event) =>
                        handleSelectTag(
                          'treatmentPlanDelivered',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {' '}
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.callCenterSupportGiven} //
                      onChange={(event) =>
                        handleSelectTag(
                          'callCenterSupportGiven',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                </>
              )}
              {selectedTab === `PACKAGE-02` && (
                <>
                  <div style={tableStyles.contentRowText}>
                    {' '}
                    {el.treatmentPlan &&
                      el.treatmentPlan.length > 0 &&
                      el.treatmentPlan[0].hospitalName}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {' '}
                    {el.treatmentPlan &&
                      el.treatmentPlan.length > 0 &&
                      el.treatmentPlan[0].doctorName}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    **PAYMENT RECEIVED**
                  </div>
                  <div style={tableStyles.contentRowText}>
                    **PAYMENT RECEIVED DATE**
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.treatmentPlanDelivered} //
                      onChange={(event) =>
                        handleSelectTag(
                          'treatmentPlanDelivered',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {el.servicePackage?.treatmentPlanNumber}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.callCenterSupportGiven} //
                      onChange={(event) =>
                        handleSelectTag(
                          'callCenterSupportGiven',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.counsellingService} //
                      onChange={(event) =>
                        handleSelectTag(
                          'counsellingService',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                </>
              )}
              {selectedTab === `PACKAGE-03` && (
                <>
                  <div style={tableStyles.contentRowText}>
                    {' '}
                    {el.treatmentPlan &&
                      el.treatmentPlan.length > 0 &&
                      el.treatmentPlan[0].hospitalName}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {' '}
                    {el.treatmentPlan &&
                      el.treatmentPlan.length > 0 &&
                      el.treatmentPlan[0].doctorName}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    **PAYMENT RECEIVED**
                  </div>
                  <div style={tableStyles.contentRowText}>
                    **PAYMENT RECEIVED DATE**
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.treatmentPlanDelivered} //
                      onChange={(event) =>
                        handleSelectTag(
                          'treatmentPlanDelivered',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {el.servicePackage?.treatmentPlanNumber}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.callCenterSupportGiven} //
                      onChange={(event) =>
                        handleSelectTag(
                          'callCenterSupportGiven',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.counsellingService} //
                      onChange={(event) =>
                        handleSelectTag(
                          'counsellingService',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.visaProcessingAndAssistance} //
                      onChange={(event) =>
                        handleSelectTag(
                          'visaProcessingAndAssistance',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      // defaultValue={ // ! somehow its not working
                      //   el.visaRecords &&
                      //   el.visaRecords.length > 0 &&
                      //   el.visaRecords[0].flyOutConfirmed
                      // }
                      value={
                        el.visaRecords &&
                        el.visaRecords.length > 0 &&
                        el.visaRecords[0].flyOutConfirmed
                      }
                      onChange={(event) => {
                        handleSelectTag(
                          event.target.value,
                          idx,
                          'flyOutConfirmed'
                        )
                      }}
                    >
                      <option value=""></option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                </>
              )}
              {selectedTab === `PACKAGE-04` && (
                <>
                  <div style={tableStyles.contentRowText}>
                    {el.treatmentPlan &&
                      el.treatmentPlan.length > 0 &&
                      el.treatmentPlan[0].hospitalName}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {' '}
                    {el.treatmentPlan &&
                      el.treatmentPlan.length > 0 &&
                      el.treatmentPlan[0].doctorName}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    **PAYMENT RECEIVED**
                  </div>
                  <div style={tableStyles.contentRowText}>
                    **PAYMENT RECEIVED DATE**
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.treatmentPlanDelivered} //
                      onChange={(event) =>
                        handleSelectTag(
                          'treatmentPlanDelivered',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {el.servicePackage?.treatmentPlanNumber}
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.callCenterSupportGiven} //
                      onChange={(event) =>
                        handleSelectTag(
                          'callCenterSupportGiven',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.counsellingService} //
                      onChange={(event) =>
                        handleSelectTag(
                          'counsellingService',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.visaProcessingAndAssistance} //
                      onChange={(event) =>
                        handleSelectTag(
                          'visaProcessingAndAssistance',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.airportHotelTransfer} //
                      onChange={(event) =>
                        handleSelectTag(
                          'airportHotelTransfer',
                          event.target.value,
                          el
                        )
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      value={el.servicePackage?.guideSerivces} //
                      onChange={(event) =>
                        handleSelectTag('guideSerivces', event.target.value, el)
                      }
                    >
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    <select
                      disabled
                      style={tableStyles.selectStyle}
                      // defaultValue={ // ! somehow its not working
                      //   el.visaRecords &&
                      //   el.visaRecords.length > 0 &&
                      //   el.visaRecords[0].flyOutConfirmed
                      // }
                      value={
                        el.visaRecords &&
                        el.visaRecords.length > 0 &&
                        el.visaRecords[0].flyOutConfirmed
                      }
                      onChange={(event) => {
                        handleSelectTag(
                          event.target.value,
                          idx,
                          'flyOutConfirmed'
                        )
                      }}
                    >
                      <option value=""></option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                  <div style={tableStyles.contentRowText}>
                    {el.visaRecords &&
                      el.visaRecords.length > 0 &&
                      el.visaRecords[0].flyOutDate}
                  </div>
                </>
              )}

              <div
                style={{
                  ...tableStyles.contentRowText,
                  width: '4%',
                  borderRight: '1px solid white',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(`/pi/${el.pID}?tab=4`, {
                    state: { sentFrom: location },
                  })
                }
              >
                <img
                  src="/contents/icons/PROFILE.png"
                  style={{ height: '1.4rem' }}
                  alt=""
                />
                <p style={{ margin: 0, color: '#275058', fontSize: '.7rem' }}>
                  PROFILE
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <Layout style={styles.layoutStyle}>
        <Content style={styles.contentStyle}>
          <TopRowCommon title="SERVICE PACKAGE SOLD" />

          {loading && (
            <div className="overlay">
              <div style={{ textAlign: 'center', marginTop: '9rem' }}>
                <Spin size="large" />
              </div>
            </div>
          )}

          <div style={styles.tabNSearchRow}>
            <div style={styles.tabTextContainer}>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'PACKAGE-01'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('PACKAGE-01')}
              >
                SERVICE PACKAGE-01{' '}
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'PACKAGE-02'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('PACKAGE-02')}
              >
                SERVICE PACKAGE-02
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'PACKAGE-03'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('PACKAGE-03')}
              >
                SERVICE PACKAGE-03
              </p>
              <p
                style={{
                  ...styles.tabText,
                  ...(selectedTab === 'PACKAGE-04'
                    ? styles.tabTextHighlight
                    : {}),
                }}
                onClick={() => handleSelectedTabClick('PACKAGE-04')}
              >
                SERVICE PACKAGE-04
              </p>
            </div>
            <div>
              <Input
                placeholder="Search Patient"
                onChange={onSearch}
                allowClear
                style={{
                  width: 200,
                  borderRadius: 25,
                  border: '1px solid #275058',
                }}
              />
            </div>
          </div>
          {isModalOpen && (
            <Modal
              title=""
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              bodyStyle={{ height: 900 }}
              width={900}
            >
              {/* <p>{modalContent?.type}</p> */}

              {modalContent?.type?.includes('pdf') ? (
                <iframe
                  src={modalContent?.url}
                  frameborder="0"
                  style={{ width: '100%', height: '100%' }}
                ></iframe>
              ) : (
                <img
                  src={modalContent?.url}
                  alt=""
                  srcset=""
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </Modal>
          )}
          {/* {selectedTab} */}
          {generateTable()}

          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {/* <Pagination
              defaultCurrent={1}
              defaultPageSize={10}
              total={totalLeads}
              current={page}
              onChange={(p) => setPage(p)}
              showSizeChanger={false}
            /> */}
          </div>
        </Content>
      </Layout>
    </>
  )
}

const styles = {
  layoutStyle: {
    minHeight: '100vh',
  },
  contentStyle: {
    padding: '0 16px',
    backgroundColor: '#fff',
  },
  tabNSearchRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  tabTextContainer: {
    display: 'flex',
    gap: 10,
  },
  tabText: {
    textTransform: 'uppercase',
    color: '#275058',
    margin: 0,
    cursor: 'pointer',
  },
  tabTextHighlight: {
    fontWeight: 'bold',
    textUnderlineOffset: '0.25rem',
    textDecoration: 'underline',
  },
}
export default ServicePackage
