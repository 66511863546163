import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { Button, Menu, Drawer } from 'antd'

import ReportsPassports from './ReportsPassports'
import TreatmentPlan from './TreatmentPlan'
import TeleMedicine from './TeleMedicine'
import VisaStatus from './VisaStatus'
import ServicePackage from './ServicePackage'
import NoteSection from './Notes'
import BillList from './BillList'
import Summary from './Summary'

const DesktopMenuContainer = styled.div`
  border-bottom: 1px solid #275058;
  // padding-bottom: 20px;
`

const StyledMenuItemDesktop = styled(Menu.Item)`
  //   width: 11%;
  //   text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #275058;
  padding-bottom: 10px;
  @media (max-width: 1400px) {
    // font-size: 18px;
    // width: 12%;
    // border: 1px solid red;
  }
`

function BottomNavContainer(props) {
  const { selectedTab, setSelectedTab, leadInfo } = props
  const navigate = useNavigate()
  const location = useLocation()

  const generateJsx = () => {
    // console.log(selectedTab)
    if (selectedTab === '1') {
      return <ReportsPassports leadInfo={leadInfo} />
    } else if (selectedTab === '2') {
      return <TreatmentPlan leadInfo={leadInfo} />
    } else if (selectedTab === '3') {
      return <TeleMedicine leadInfo={leadInfo} />
    } else if (selectedTab === '4') {
      return <VisaStatus leadInfo={leadInfo} />
    } else if (selectedTab === '5') {
      return <ServicePackage leadInfo={leadInfo} />
    } else if (selectedTab === '6') {
      return <NoteSection leadInfo={leadInfo} />
    } else if (selectedTab === '7') {
      return <BillList leadInfo={leadInfo} />
    } else if (selectedTab === '8') {
      return <Summary leadInfo={leadInfo} />
    }
  }

  useEffect(() => {
    // to keep the current tab ready for hard refresh of page
    if (selectedTab)
      navigate(`/pi/${leadInfo.pID}?tab=${selectedTab}`, {
        state: { ...location.state }, // if any state is passed, making sure here this is also available after auto navigation via useEffect hook, in future sub components. E.g. OverviewCard.js
      })
  }, [selectedTab])

  return (
    <div>
      <DesktopMenuContainer>
        {/* Nav element goes here */}
        <Menu
          mode="horizontal"
          defaultSelectedKeys={selectedTab}
          disabledOverflow={true}
          style={{
            marginLeft: `5%`,
            // marginRight: `15%`,
            marginTop: 20,
            paddingLeft: '2%',
            // borderBottom: '1px solid #275058',
            width: '90%', // to expand the menu in medium large screen ( to avoid overlapping )
          }}
        >
          <StyledMenuItemDesktop
            key="1"
            onClick={(v) => {
              setSelectedTab(v.key)
            }}
          >
            REPORTS & PASSPORT
          </StyledMenuItemDesktop>
          <StyledMenuItemDesktop
            key="2"
            onClick={(v) => {
              setSelectedTab(v.key)
            }}
          >
            TREATMENT PLAN
          </StyledMenuItemDesktop>
          <StyledMenuItemDesktop
            key="3"
            onClick={(v) => {
              setSelectedTab(v.key)
            }}
          >
            TELE-MEDICINE
          </StyledMenuItemDesktop>
          <StyledMenuItemDesktop
            key="4"
            onClick={(v) => {
              setSelectedTab(v.key)
            }}
          >
            VIL & VISA STATUS
          </StyledMenuItemDesktop>
          <StyledMenuItemDesktop
            key="5"
            onClick={(v) => {
              setSelectedTab(v.key)
            }}
          >
            SERVICE PACKAGE
          </StyledMenuItemDesktop>
          <StyledMenuItemDesktop
            key="6"
            onClick={(v) => {
              setSelectedTab(v.key)
            }}
          >
            NOTES
          </StyledMenuItemDesktop>
          <StyledMenuItemDesktop
            key="7"
            onClick={(v) => {
              setSelectedTab(v.key)
            }}
          >
            BILLS{' '}
          </StyledMenuItemDesktop>
          <StyledMenuItemDesktop
            key="8"
            onClick={(v) => {
              setSelectedTab(v.key)
            }}
          >
            SUMMARY{' '}
          </StyledMenuItemDesktop>
        </Menu>
        {/* <hr /> */}
      </DesktopMenuContainer>

      {generateJsx()}
    </div>
  )
}

export default BottomNavContainer
